export enum ContentType {
  FACEBOOK = 'facebook',
  MIXED = 'other',
  SOUNDCLOUD = 'soundcloud',
  SPOTIFY = 'spotify',
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube',
}

export enum ContentCategory {
  LITERATURE = 'literature',
  POETRY = 'poetry',
  FESTIVALS = 'festivals',
  EVENTS = 'events',
  MUSIC = 'music',
  PODCASTS = 'podcasts',
  WELSH_LEARNERS = 'welsh-learners',
  THEATRE = 'theatre',
  VISUAL_ARTS = 'visual-arts',
  COMMUNITIES = 'communities',
  DANCE = 'dance',
  FILM = 'film',
  MAGAZINES = 'magazines',
  BLOGS = 'blogs',
}

export enum FeatureType {
  EMBED = 'embed',
  // THUMBNAIL = 'thumbnail',
  MEDIA = 'media',
}

export const contentTypes = () => {
  return Object.keys(ContentType).map((type) => ContentType[type]);
};

export const contentCategories = () => {
  return Object.keys(ContentCategory).map(
    (category) => ContentCategory[category],
  );
};

export const featureTypes = () => {
  return Object.keys(FeatureType).map((type) => FeatureType[type]);
};
