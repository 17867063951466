import {Language} from '../enums/Language';
import {UserStatus} from '../enums/User';
import {User} from '../interfaces/User';

const BuildInitialUser = (loggedinUserId: string): User => {
  return {
    meta: {
      created: new Date(),
      createdBy: loggedinUserId,
      lastUpdated: new Date(),
      lastUpdatedBy: loggedinUserId,
    },

    status: UserStatus.ACTIVE,

    auth0Id: '',

    firstName: '',
    lastName: '',

    avatar: null,

    contactDetails: {
      email: '',
      phone: '',
    },

    language: Language.EN,

    library: {
      channels: [],
      content: [],
    },

    // For redux
    token: '',
    accessType: 'channel',
    accessChannels: [],
  };
};

export default BuildInitialUser;
