import {
  contentCategories,
  ContentType,
  contentTypes,
  FeatureType,
} from '../enums/Content';
import {Language} from '../enums/Language';
import {publishStatuses} from '../enums/PublishMeta';
import {Content} from '../interfaces/Content';

const coeff = 1000 * 60 * 5;
const date = new Date();

const primaryContent = {
  [ContentType.YOUTUBE]: {
    url: '',
    mediaUrl: '',
    showChat: false,
  },
  [ContentType.VIMEO]: {
    url: '',
    mediaUrl: '',
  },
  [ContentType.SPOTIFY]: {
    url: '',
    mediaUrl: '',
    isPodcast: false,
  },
  [ContentType.SOUNDCLOUD]: {
    url: '',
    mediaUrl: '',
  },
  [ContentType.FACEBOOK]: {
    url: '',
  },
};

const BuildInitialContent = (
  loggedinUserId: string,
  channelId: string,
): Content => {
  return {
    meta: {
      created: new Date(),
      createdBy: loggedinUserId,
      lastUpdated: new Date(),
      lastUpdatedBy: loggedinUserId,
    },

    publishMeta: {
      status: publishStatuses()[0],
      publishDate: new Date(Math.floor(date.getTime() / coeff) * coeff),
    },

    showInLatest: true,
    showInChannelLatest: true,
    sendNotifications: true,

    watchlist: {
      active: false,
      autoApproval: false,
      pending: [],
      users: [],
    },

    channelId: channelId || '',

    contentName: '',
    urlSlug: '',

    description: {
      [Language.EN]: '',
      [Language.CY]: '',
    },

    contentType: contentTypes()[0],

    contentCategory: [contentCategories()[0]],
    channelCategories: [],

    primaryContent: primaryContent,

    featureContent: {
      featureType: FeatureType.EMBED,
      thumbnailId: null,
      coverId: null,
    },

    bodyContent: {
      [Language.EN]: '',
      [Language.CY]: '',
    },

    tags: {
      private: [],
      public: [],
    },

    likeCount: 0,

    isComingSoon: false,

    comingSoonContent: {
      title: '',
      description: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      contentType: contentTypes()[0],
      primaryContent: primaryContent,

      featureContent: {
        featureType: FeatureType.EMBED,
        thumbnailId: null,
        coverId: null,
      },
    },

    geolocation: {
      active: false,
      allow: [],
      reject: [],
    },
  };
};

export default BuildInitialContent;
