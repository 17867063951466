import {
  FETCH_SELECTED_CHANNEL_ERROR,
  FETCH_SELECTED_CHANNEL_PENDING,
  FETCH_SELECTED_CHANNEL_SUCCESS,
} from '../actions/selected-channel/SelectedChannelActionTypes';

export interface SelectedChannelState {
  pending: boolean;
  data: any;
  error: string | null;
}

const INITIAL_STATE: SelectedChannelState = {
  pending: false,
  data: null,
  error: null,
};

const selectedChannelReducer = (state = INITIAL_STATE, action) => {
  const payload: SelectedChannelState = action.payload;
  switch (action.type) {
    case FETCH_SELECTED_CHANNEL_PENDING:
      return {...state, pending: true};

    case FETCH_SELECTED_CHANNEL_SUCCESS:
      return {...state, pending: false, data: payload};

    case FETCH_SELECTED_CHANNEL_ERROR:
      return {...state, pending: false, error: payload};

    default:
      return state;
  }
};

export default selectedChannelReducer;
