import {cloneDeep, get, set, sortBy, startCase} from 'lodash';
import moment from 'moment';
import {Types} from 'mongoose';
import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Button, Dropdown, Form, Popup, Select, Table} from 'semantic-ui-react';
import config from '../../config/config';
import {
  highlightPositions,
  HighlightType,
  highlightTypes,
} from '../../const/enums/Highlight';
import {SchemaName} from '../../const/enums/Schema';
import {systemStatuses} from '../../const/enums/System';
import BuildInitialSchedule from '../../const/initial/Schedule';
import {TOTAL_HIGHLIGHTS} from '../../const/initial/System';
import {System} from '../../const/interfaces/System';
import {User} from '../../const/interfaces/User';
import {showCommonModal} from '../../redux/actions/common-modal/CommonModalAction';
import {fetchSystemSuccess} from '../../redux/actions/system/SystemAction';
import AxiosHelper from '../../util/AxiosHelper';
import {handlePagination} from '../../util/PaginationHelper';
import {handleSearch} from '../../util/SearchHelper';
import {validateSchedule} from '../../util/ValidationHelper';
import TablePagination from '../common/TablePagination';

export interface ScheduleTableProps {
  loggedInUser: User;
  model: SchemaName;
  system?: System;
  moderationFilter?: boolean;
  channelFilter?: string[];
}

const ScheduleTable = ({
  loggedInUser,
  model,
  moderationFilter = false,
  channelFilter,
}: ScheduleTableProps) => {
  const pageSize = 10;

  const {addToast} = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const [schedulePaginationPage, setSponsorPaginationPage] = useState(1);
  const [schedulePaginationTotal, setSchedulePaginationTotal] = useState(0);

  const [selectedScheduleItem, setSelectedScheduleItem] = useState(
    BuildInitialSchedule,
  );
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  const [dataCollection, setDataCollection] = useState(null as any);
  const [contentItems, setContentItems] = useState([] as any);
  const [categoryItems, setCategoryItems] = useState([] as any);
  const [contentSearchResults, setContentSearchResults] = useState([] as any);
  const [categorySearchResults, setCategorySearchResults] = useState([] as any);

  const system = useSelector((state) => state.system).data;

  const [scheduledHighlights, setScheduledHighlights] = useState([] as any);

  const handlePaginationChange = useCallback(
    async (activePage: number) => {
      if (model !== SchemaName.SYSTEM) {
        const requiresModeration = moderationFilter
          ? {moderation: {$ne: null}}
          : {};

        const additionalFilters: any = {
          'publishMeta.publishDate': {$gte: new Date()},
          sort: {'publishMeta.publishDate': 1},
        };

        if (moderationFilter) {
          additionalFilters.moderation = {$ne: null};
        }

        if (channelFilter?.length) {
          additionalFilters.channelId = {$in: channelFilter};
        }

        return await handlePagination(
          loggedInUser && loggedInUser.token,
          model,
          activePage,
          pageSize,
          [],
          setDataCollection,
          setSponsorPaginationPage,
          setSchedulePaginationTotal,
          {
            'publishMeta.publishDate': {$gte: new Date()},
            sort: {'publishMeta.publishDate': 1},
            ...requiresModeration,
          },
        );
      }
    },
    [loggedInUser, model, moderationFilter, channelFilter],
  );

  const handleSystemPaginationChange = (activePage: number) => {
    setSponsorPaginationPage(activePage);
  };

  useEffect(() => {
    if (system) {
      const now = moment();
      const scheduled = system.highlightedContent.content.scheduled.filter(
        (i) => now.isBefore(moment(i.publishMeta.publishDate)),
      );
      const sorted = sortBy(scheduled, 'publishMeta.publishDate');

      setScheduledHighlights(sorted);
    }
  }, [system]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        await handlePaginationChange(1);
      })();
    }
  }, [loggedInUser, handlePaginationChange]);

  useEffect(() => {
    if (
      model === SchemaName.SYSTEM &&
      scheduledHighlights &&
      scheduledHighlights.length !== schedulePaginationTotal
    ) {
      setSchedulePaginationTotal(scheduledHighlights.length);
    }
  }, [scheduledHighlights, schedulePaginationTotal, model]);

  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser.token &&
      model === SchemaName.SYSTEM &&
      scheduledHighlights &&
      scheduledHighlights.length
    ) {
      (async () => {
        try {
          const axiosHelper = new AxiosHelper(loggedInUser.token);
          // Get scheduled content
          const highlightContentIds = Object.keys(scheduledHighlights).map(
            (sch) => {
              const contentId = scheduledHighlights[sch].contentId;
              if (contentId && contentId.length) {
                return contentId;
              }

              return null;
            },
          );

          const result = await axiosHelper.get(
            `${config.api}/content/formatted`,
            {
              _id: {
                $in: highlightContentIds,
              },
            },
          );

          if (result && result.data.results.length) {
            setContentItems(result.data.results);

            setContentSearchResults(
              result.data.results.map((item) => {
                return {
                  key: `hero-${item.contentName}-0`,
                  value: item._id,
                  text: item.contentName,
                  image: item.featureContent.thumbnailId
                    ? `${config.api}/file/${item.featureContent.thumbnailId}`
                    : item.primaryContent[item.contentType].mediaUrl,
                };
              }),
            );
          }

          const categoryOptions: any = [];
          const categoryResults: any = [];

          for (const key of Object.keys(scheduledHighlights)) {
            if (scheduledHighlights[key].isCategory) {
              const res = await axiosHelper.get(
                `${config.api}/channel/category`,
                {
                  channelId: scheduledHighlights[key].contentId,
                  categoryId: scheduledHighlights[key].categoryId,
                },
              );

              if (res) {
                categoryResults.push({
                  _id: res.data._id,
                  categoryItem: true,
                  channelId: res.data.channelId,
                  contentName: res.data.categoryName,
                  urlSlug: res.data.urlSlug,
                  featureContent: {
                    thumbnailId: res.data.thumbnailId,
                  },
                });
                categoryOptions.push({
                  _id: res.data.channelId,
                  key: `highlight-${res.data.contentId}`,
                  value: res.data._id,
                  text: res.data.categoryName,
                  image: `${config.api}/file/${res.data.thumbnailId}`,
                });
              }
            }
          }

          if (categoryResults) {
            setCategoryItems(categoryResults || []);
            setCategorySearchResults(categoryOptions || []);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [loggedInUser, scheduledHighlights, system, model]);

  const clearScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedScheduleItem(BuildInitialSchedule());
    dispatch(showCommonModal({open: false}));
  };

  const handleScheduleChange = useCallback(
    (field: string | string[], data: any) => {
      if (field && data) {
        const cloned = cloneDeep(selectedScheduleItem);

        if (!Array.isArray(field)) {
          field = [field];
        }

        if (!Array.isArray(data)) {
          data = [data];
        }

        for (let i = 0; i < field.length; i++) {
          set(cloned, field[i], data[i].value);
        }

        setSelectedScheduleItem(cloned);
      }
    },
    [selectedScheduleItem],
  );

  const handleScheduleSave = () => {
    const cloned = cloneDeep(system);

    // Find if update or create schedule
    if (selectedScheduleItem._id) {
      cloned.highlightedContent.content.scheduled = cloned.highlightedContent.content.scheduled.map(
        (schedule) => {
          if (schedule._id === selectedScheduleItem._id) {
            selectedScheduleItem.publishMeta.publishDate = new Date(
              selectedScheduleItem.publishMeta.publishDate,
            );
            return selectedScheduleItem;
          }
          return schedule;
        },
      );

      // Add toast
      addToast(`Schedule item updated successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      selectedScheduleItem._id = new Types.ObjectId().toHexString();
      selectedScheduleItem.publishMeta.publishDate = new Date(
        selectedScheduleItem.publishMeta.publishDate,
      );
      cloned.highlightedContent.content.scheduled.push(selectedScheduleItem);

      // Add toast
      addToast(`Schedule item created successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }

    dispatch(fetchSystemSuccess(cloned));
    clearScheduleModal();
  };

  const handleScheduleDelete = () => {
    const cloned = cloneDeep(system);

    if (!selectedScheduleItem._id) {
      // Add toast
      addToast(`Schedule item deletion failed. Try again later.`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }

    // Sort items
    cloned.highlightedContent.content.scheduled = sortBy(
      cloned.highlightedContent.content.scheduled,
      'publishMeta.publishDate',
    );

    // Delete schedule
    let deletedIndex;
    cloned.highlightedContent.content.scheduled = cloned.highlightedContent.content.scheduled.filter(
      (item, index) => {
        if (item._id === selectedScheduleItem._id) {
          deletedIndex = index;
        }
        return item._id !== selectedScheduleItem._id;
      },
    );

    // Update pagination if necessary
    if (deletedIndex) {
      if (
        deletedIndex + 1 === schedulePaginationTotal &&
        schedulePaginationPage > 1
      ) {
        setSponsorPaginationPage(schedulePaginationPage - 1);
      }
    }

    // Add toast
    addToast(`Schedule item deleted successfully.`, {
      appearance: 'success',
      autoDismiss: true,
    });

    dispatch(fetchSystemSuccess(cloned));
    clearScheduleModal();
  };

  useEffect(() => {
    if (scheduleModalOpen) {
      const isEditing = !!selectedScheduleItem._id;

      const buttons = [
        {
          disabled: validateSchedule([selectedScheduleItem]),
          className: 'button primary',
          buttonText: 'Save Scheduled Highlight',
          action: handleScheduleSave,
        },
        {
          className: 'button secondary',
          buttonText: 'Cancel',
          action: clearScheduleModal,
        },
      ];

      if (isEditing) {
        buttons.splice(1, 0, {
          className: 'button red',
          buttonText: 'Delete Scheduled Highlight',
          action: handleScheduleDelete,
        });
      }

      dispatch(
        showCommonModal({
          open: true,
          onClose: clearScheduleModal,
          headerText: `${isEditing ? 'Edit' : 'Create'} Scheduled Highlight`,
          bodyText: (
            <Form>
              <Form.Field required>
                <label>Highlight Type</label>
                <Select
                  placeholder={'Select a highlight type'}
                  options={highlightTypes().map((type, index) => {
                    return {
                      key: `highlight-type-${type}-${index}`,
                      value: type,
                      text: startCase(type),
                    };
                  })}
                  value={
                    get(selectedScheduleItem, `highlightType`) ||
                    systemStatuses()[0]
                  }
                  onChange={(e, data) =>
                    handleScheduleChange(`highlightType`, data)
                  }
                />
              </Form.Field>

              {selectedScheduleItem.highlightType ===
                HighlightType.HIGHLIGHT && (
                <Form.Field required>
                  <label>Highlight Position</label>
                  <Select
                    placeholder={'Select a highlight position'}
                    options={highlightPositions(TOTAL_HIGHLIGHTS)}
                    value={
                      get(selectedScheduleItem, `highlightPosition`) ||
                      highlightPositions(TOTAL_HIGHLIGHTS)[0].value
                    }
                    onChange={(e, data) =>
                      handleScheduleChange(`highlightPosition`, data)
                    }
                  />
                </Form.Field>
              )}

              <Form.Field>
                <label>Content Type</label>
                <Form.Checkbox
                  toggle
                  label={'Channel Playlist'}
                  checked={get(selectedScheduleItem, `isCategory`)}
                  onChange={(e, data) =>
                    handleScheduleChange(
                      [`isCategory`, `contentId`, `categoryId`],
                      [
                        {
                          value: !!data.checked,
                        },
                        {value: null},
                        {value: null},
                      ],
                    )
                  }
                />
              </Form.Field>

              {!get(selectedScheduleItem, `isCategory`) && (
                <Form.Field required>
                  <label>Content</label>
                  <Dropdown
                    placeholder={'Type to search Content'}
                    noResultsMessage={'Type to search Content'}
                    fluid
                    search
                    onSearchChange={async (e, data) => {
                      await handleSearch(
                        loggedInUser.token,
                        SchemaName.CONTENT,
                        data.searchQuery,
                        contentSearchResults,
                        setContentSearchResults,
                      );
                    }}
                    onChange={(e, data) =>
                      handleScheduleChange('contentId', data)
                    }
                    selection
                    options={contentSearchResults}
                    value={selectedScheduleItem.contentId}
                    renderLabel={(item) => {
                      return {
                        content: <>{item.text}</>,
                      };
                    }}
                  />
                </Form.Field>
              )}

              {get(selectedScheduleItem, `isCategory`) && (
                <Form.Field required>
                  <label>Playlist</label>
                  <Dropdown
                    placeholder={'Type to search Playlist'}
                    noResultsMessage={'Type to search Playlist'}
                    fluid
                    search
                    onSearchChange={async (e, data) => {
                      await handleSearch(
                        loggedInUser.token,
                        SchemaName.CATEGORY,
                        data.searchQuery,
                        categorySearchResults,
                        setCategorySearchResults,
                      );
                    }}
                    onChange={(e, data) => {
                      const item = categorySearchResults.find(
                        (i) => i.value === data.value,
                      );

                      handleScheduleChange(
                        [`contentId`, `categoryId`],
                        [
                          {
                            value: item ? item._id : null,
                          },
                          data,
                        ],
                      );
                    }}
                    selection
                    options={categorySearchResults}
                    value={selectedScheduleItem.categoryId}
                    renderLabel={(item) => {
                      return {
                        content: <>{item.text}</>,
                      };
                    }}
                  />
                </Form.Field>
              )}

              <Form.Field required>
                <label>Publish Date</label>
                <DatePicker
                  selected={
                    (selectedScheduleItem &&
                      selectedScheduleItem.publishMeta &&
                      new Date(selectedScheduleItem.publishMeta.publishDate)) ||
                    new Date()
                  }
                  onChange={(date) =>
                    handleScheduleChange('publishMeta.publishDate', {
                      value: date,
                    })
                  }
                  showTimeSelect
                  timeIntervals={5}
                  dateFormat={'MMM d, yyyy h:mm aa'}
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '-100px, 5px',
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                />
              </Form.Field>
            </Form>
          ),
          buttons,
        }),
      );
    }
  });

  const getTableHeaders = () => {
    switch (model) {
      case SchemaName.SYSTEM:
        return ['', 'Scheduled', 'Content', 'Highlight Type'];
      case SchemaName.CONTENT:
        return ['', 'Scheduled', 'Content', 'Channel', 'Last Updated'];
      case SchemaName.CHANNEL:
        return ['', 'Scheduled', 'Channel', 'Last Updated'];
      default:
        return [];
    }
  };

  const getTableBody = (item, itemIndex, contentItem) => {
    switch (model) {
      case SchemaName.SYSTEM:
        if (contentItem) {
          return (
            <Table.Row
              className={'TableRowClickable'}
              key={`schedule-row-${item.contentId}-${itemIndex}`}
              onClick={() => {
                setSelectedScheduleItem(item);
                setScheduleModalOpen(true);
              }}
            >
              <Table.Cell>
                <div
                  className={'TableImage'}
                  style={
                    contentItem && {
                      backgroundImage: contentItem.featureContent.thumbnailId
                        ? `url(${config.api}/file/${contentItem.featureContent.thumbnailId})`
                        : `url(${
                            contentItem.primaryContent[contentItem.contentType]
                              .mediaUrl
                          })`,
                    }
                  }
                >
                  {contentItem.moderation && (
                    <Popup
                      position={'left center'}
                      size={'small'}
                      content={'This content requires moderation'}
                      trigger={<div className={'TableImageModerationFlag'} />}
                    />
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                {moment(item.publishMeta.publishDate).format(
                  'ddd Do MMM HH:mm',
                )}
              </Table.Cell>
              <Table.Cell>{contentItem && contentItem.contentName}</Table.Cell>
              <Table.Cell>
                {item.highlightType === HighlightType.HERO
                  ? startCase(item.highlightType)
                  : startCase(`Highlight ${item.highlightPosition + 1}`)}
              </Table.Cell>
            </Table.Row>
          );
        }
        return [];
      case SchemaName.CONTENT:
        if (contentItem) {
          return (
            <Table.Row
              className={'TableRowClickable'}
              key={`schedule-row-${item._id}-${itemIndex}`}
              onClick={() => {
                history.push(`${model}-item/${item._id}`);
              }}
            >
              <Table.Cell>
                <div
                  className={'TableImage'}
                  style={{
                    backgroundImage: contentItem.featureContent.thumbnailId
                      ? `url(${config.api}/file/${contentItem.featureContent.thumbnailId})`
                      : `url(${
                          contentItem.primaryContent[contentItem.contentType]
                            .mediaUrl
                        })`,
                  }}
                >
                  {contentItem.moderation && (
                    <Popup
                      position={'left center'}
                      size={'small'}
                      content={'This content requires moderation'}
                      trigger={<div className={'TableImageModerationFlag'} />}
                    />
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                {moment(item.publishMeta.publishDate).format(
                  'ddd Do MMM HH:mm',
                )}
              </Table.Cell>
              <Table.Cell>{contentItem.contentName}</Table.Cell>
              <Table.Cell>
                {(contentItem.channel &&
                  contentItem.channel[0] &&
                  contentItem.channel[0].channelName) ||
                  contentItem.channelId}
              </Table.Cell>
              <Table.Cell>
                {moment(contentItem.meta.lastUpdated).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </Table.Cell>
            </Table.Row>
          );
        }
        return [];

      case SchemaName.CHANNEL:
        if (contentItem) {
          return (
            <Table.Row
              className={'TableRowClickable'}
              key={`schedule-row-${item._id}-${itemIndex}`}
              onClick={() => {
                history.push(`${model}-item/${item._id}`);
              }}
            >
              <Table.Cell>
                <div
                  className={'TableImage'}
                  style={{
                    backgroundImage: `url(${config.api}/file/${contentItem.theme.logo})`,
                  }}
                >
                  {contentItem.moderation && (
                    <Popup
                      position={'left center'}
                      size={'small'}
                      content={'This content requires moderation'}
                      trigger={<div className={'TableImageModerationFlag'} />}
                    />
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                {moment(item.publishMeta.publishDate).format(
                  'ddd Do MMM HH:mm',
                )}
              </Table.Cell>
              <Table.Cell>{contentItem.channelName}</Table.Cell>
              <Table.Cell>
                {moment(contentItem.meta.lastUpdated).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </Table.Cell>
            </Table.Row>
          );
        }
        return [];

      default:
        return [];
    }
  };

  const scheduleTableHeaders = getTableHeaders().map((header) => {
    return (
      <Table.Cell className={'TableHeader'} key={`schedule-header-${header}`}>
        {header}
      </Table.Cell>
    );
  });

  const groupItemsByMonth = () => {
    let obj: any = {};

    let collection = dataCollection;

    if (model === SchemaName.SYSTEM && scheduledHighlights) {
      const clonedSched = cloneDeep(scheduledHighlights);
      collection = clonedSched.splice(
        schedulePaginationPage - 1 === 0
          ? 0
          : (schedulePaginationPage - 1) * pageSize,
        schedulePaginationTotal < pageSize
          ? schedulePaginationTotal
          : schedulePaginationPage * pageSize,
      );
    }

    collection &&
      collection.forEach((item) => {
        const label = moment(item.publishMeta.publishDate).format('MMMM YYYY');

        if (!obj[label]) {
          obj[label] = [];
        }
        obj[label].push(item);
      });

    return obj;
  };

  const groupedItems = groupItemsByMonth();
  const scheduleTableItems = Object.keys(groupedItems).map(
    (schedule: any, index: number) => {
      if (
        index === 0 ||
        (index >= (schedulePaginationPage - 1) * pageSize &&
          index < schedulePaginationPage * pageSize)
      ) {
        const scheduleItems = groupedItems[schedule].map((item, itemIndex) => {
          const contentItem =
            model === SchemaName.SYSTEM
              ? item.isCategory
                ? categoryItems &&
                  categoryItems.find((i) => i._id === item.categoryId)
                : contentItems &&
                  contentItems.find((i) => i._id === item.contentId)
              : item;

          return getTableBody(item, itemIndex, contentItem);
        });

        return (
          <Table.Body key={`schedule-label-row-${schedule}-${index}`}>
            <Table.Row textAlign={'left'}>
              <Table.Cell
                colSpan={getTableHeaders().length}
                className={'TableHeaderDateLabel'}
              >
                {schedule}
              </Table.Cell>
            </Table.Row>
            {scheduleItems}
          </Table.Body>
        );
      }
      return null;
    },
  );

  return (
    <>
      {model === SchemaName.SYSTEM && (
        <div className={'SubTableHeader'}>
          <h2 className={'alt-colour-type'}>Scheduled Highlights</h2>

          <Button
            primary
            onClick={(event) => {
              event.preventDefault();

              setScheduleModalOpen(true);
            }}
          >
            Create Scheduled Highlight
          </Button>
        </div>
      )}

      <Table className={'Table'} textAlign={'center'} selectable striped>
        <Table.Header>
          <Table.Row>{scheduleTableHeaders}</Table.Row>
        </Table.Header>

        {scheduleTableItems && scheduleTableItems.length ? (
          scheduleTableItems
        ) : (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={getTableHeaders().length}>
                <p>No Scheduled items found</p>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}

        <TablePagination
          totalCols={getTableHeaders().length}
          pageSize={pageSize}
          paginationPage={schedulePaginationPage}
          paginationTotal={schedulePaginationTotal}
          onChange={
            model === SchemaName.SYSTEM
              ? handleSystemPaginationChange
              : handlePaginationChange
          }
        />
      </Table>
    </>
  );
};

export default ScheduleTable;
