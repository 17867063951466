import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
  <div className={'LoadingWrapper'}>
    <img src={loading} alt={'Loading'} />
  </div>
);

export default Loading;
