export enum PublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  AWAITING_MODERATION = 'awaiting-moderation',
  REJECTED = 'rejected',
}

export const publishStatuses = () => {
  return Object.keys(PublishStatus).map((status) => PublishStatus[status]);
};
