import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastProvider} from 'react-toast-notifications';
import App from './App';
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory';
import store from './redux/store';
import * as serviceWorker from './serviceWorker';
import './styles/App.scss';

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <Provider store={store}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={5000}
          placement="top-right"
        >
          <App />
        </ToastProvider>
      </Provider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
