import React from 'react';
import {Content} from '../../const/interfaces/Content';
import ContentThumbnail from './ContentThumbnail';

export interface MixedProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
}

const Mixed = ({content, handleChange, comingSoon}: MixedProps) => {
  if (!content || !handleChange) {
    return null;
  }

  return (
    <div className={'ContentType'}>
      <div>
        <ContentThumbnail content={content} handleChange={handleChange} comingSoon={comingSoon} />
      </div>
    </div>
  );
};

export default Mixed;
