export enum SchemaName {
  CATEGORY = 'category',
  CHANNEL = 'Channel',
  CONTENT = 'Content',
  EVENT = 'Event',
  MEDIA = 'Media',
  SPONSOR = 'Sponsor',
  SYSTEM = 'System',
  USER = 'User',
}

export enum SchemaNameTesting {
  CHANNEL_TEST = 'Channel-Test',
  CONTENT_TEST = 'Content-Test',
  EVENT_TEST = 'Event-Test',
  MEDIA_TEST = 'Media-Test',
  SYSTEM_TEST = 'System-Test',
  USER_TEST = 'User-Test',
}
