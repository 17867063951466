import React from 'react';
import {Dropdown, Pagination, Table} from 'semantic-ui-react';

export interface TablePaginationProps {
  totalCols: number;
  paginationPage: number;
  pageSize: number;
  paginationTotal: number;
  onChange: any;
}

const TablePagination = (props: TablePaginationProps) => {
  const showingColSpan = props.totalCols > 3 ? 3 : 2;
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={showingColSpan} textAlign={'left'}>
          <p>
            Showing{' '}
            {props.paginationPage - 1 === 0
              ? props.paginationTotal > 0
                ? 1
                : 0
              : (props.paginationPage - 1) * props.pageSize + 1}{' '}
            -{' '}
            {props.paginationPage * props.pageSize > props.paginationTotal
              ? props.paginationTotal
              : props.paginationPage * props.pageSize}{' '}
            of {props.paginationTotal} results
          </p>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={{margin: '0 10px 0 0'}}>Jump to page</p>
            <Dropdown
              upward={true}
              placeholder={'Jump to page'}
              search
              value={+props.paginationPage}
              onChange={async (e, data) => {
                if (data.value) {
                  props.onChange(data.value);
                }
              }}
              selection
              options={Array.from(
                Array(Math.ceil(props.paginationTotal / props.pageSize)).keys(),
              ).map((k, i) => ({key: i + 1, value: i + 1, text: i + 1}))}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell colSpan={props.totalCols - showingColSpan}>
          <div className={'PaginationCell'}>
            <Pagination
              onPageChange={(e, data) => props.onChange(data.activePage)}
              activePage={props.paginationPage}
              totalPages={Math.ceil(props.paginationTotal / props.pageSize)}
              firstItem={null}
              lastItem={null}
            />
          </div>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

export default TablePagination;
