import {faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cloneDeep, get, set, startCase} from 'lodash';
import {Types} from 'mongoose';
import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Link, useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Button, Divider, Dropdown, Form, Select} from 'semantic-ui-react';
import CategoryTable from '../components/channel/CategoryTable';
import ContentEditor from '../components/common/ContentEditor';
import SectionHeader from '../components/common/SectionHeader';
import config from '../config/config';
import {CommonModalActionTypes} from '../const/enums/CommonModal';
import {Language} from '../const/enums/Language';
import {PublishStatus, publishStatuses} from '../const/enums/PublishMeta';
import {SchemaName} from '../const/enums/Schema';
import {SocialPlatform} from '../const/enums/Social';
import BuildInitialChannel from '../const/initial/Channel';
import BuildInitialContent from '../const/initial/Content';
import {Channel} from '../const/interfaces/Channel';
import {System} from '../const/interfaces/System';
import {User} from '../const/interfaces/User';
import {
  hideCommonModal,
  showCommonModal,
} from '../redux/actions/common-modal/CommonModalAction';
import {
  fetchSelectedChannelError,
  fetchSelectedChannelPending,
  fetchSelectedChannelSuccess,
} from '../redux/actions/selected-channel/SelectedChannelAction';
import {fetchSelectedContentSuccess} from '../redux/actions/selected-content/SelectedContentAction';
import {
  fetchSystemError,
  fetchSystemPending,
  fetchSystemSuccess,
} from '../redux/actions/system/SystemAction';
import AxiosHelper from '../util/AxiosHelper';
import {getModerationField} from '../util/ModerationHelper';
import {buildSearchOption, handleSearch} from '../util/SearchHelper';
import {buildTagOptions} from '../util/TagHelper';
import {validateChannel} from '../util/ValidationHelper';

const ChannelItemView = () => {
  const {addToast} = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const channelId = params.id;

  const system: System = useSelector((state) => state.system).data;
  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const channel: Channel =
    useSelector((state) => state.selectedChannel).data ||
    BuildInitialChannel((loggedInUser && loggedInUser._id) || '');

  const [langStrings, setLangStrings] = useState({
    description: Language.EN,
    bio: Language.EN,
    highlightsTitle: Language.EN,
  });

  const [contentEditorReady, setContentEditorReady] = useState(false);
  const [userSearchResults, setUserSearchResults] = useState([] as any);
  const [contentSearchResults, setContentSearchResults] = useState([] as any);

  if (!channelId && !contentEditorReady) {
    setContentEditorReady(true);
  }

  useEffect(() => {
    if (loggedInUser && loggedInUser.token && !system) {
      (async () => {
        try {
          const axiosHelper = new AxiosHelper(loggedInUser.token);
          dispatch(fetchSystemPending());
          // Fetch system to check for admin
          const result = await axiosHelper.get(`${config.api}/system`);

          dispatch(fetchSystemSuccess(result.data));
        } catch (e) {
          dispatch(fetchSystemError(e));
        }
      })();
    }
  }, [loggedInUser, dispatch, system]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.token && channelId) {
      (async () => {
        let channel;
        const axiosHelper = new AxiosHelper(loggedInUser.token);
        try {
          dispatch(fetchSelectedChannelPending());
          // Get channel
          channel = await axiosHelper.get(`${config.api}/channel/${channelId}`);
          dispatch(fetchSelectedChannelSuccess(channel.data));

          // Get channel admin users
          if (channel.data.channelAdmins && channel.data.channelAdmins.length) {
            const adminResult = await axiosHelper.get(`${config.api}/user`, {
              _id: {$in: channel.data.channelAdmins},
            });
            const userOptions = adminResult.data.results.map(
              (result, index) => {
                return buildSearchOption(SchemaName.USER, result, index);
              },
            );

            setUserSearchResults(userOptions);
          }

          setContentEditorReady(true);
        } catch (e) {
          dispatch(fetchSelectedChannelError(e));
        }

        // Set featured content dropdown
        if (channel && channel.data.featuredContentId) {
          const content = await axiosHelper.get(
            `${config.api}/content/${channel.data.featuredContentId}`,
          );

          if (content) {
            setContentSearchResults([
              {
                key: `content-${content.data.contentName}-0`,
                value: content.data._id,
                text: content.data.contentName,
                image: content.data.featureContent.thumbnailId
                  ? `${config.api}/file/${content.data.featureContent.thumbnailId}`
                  : content.data.primaryContent[content.data.contentType]
                      .mediaUrl,
              },
            ]);
          }
        }
      })();
    } else if (loggedInUser && loggedInUser.token && !channelId) {
      fetchSelectedChannelSuccess(
        BuildInitialChannel((loggedInUser && loggedInUser._id) || ''),
      );
    }
  }, [loggedInUser, dispatch, channelId]);

  const handleLangChange = (field: string, lang: Language) => {
    if (field && lang) {
      const cloned = cloneDeep(langStrings);
      set(cloned, field, lang);
      setLangStrings(cloned);
    }
  };

  const handleChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(channel);

        if (get(channel, `moderation.${field}`)) {
          field = `moderation.${field}`;
        }

        set(cloned, field, data.value);
        dispatch(fetchSelectedChannelSuccess(cloned));
      }
    },
    [dispatch, channel],
  );

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update lastUpdated
      channel.meta.lastUpdated = new Date();
      channel.meta.lastUpdatedBy = loggedInUser._id;

      // Update/Create channel
      const newChannel = (channelId
        ? await axiosHelper.put(`${config.api}/channel`, channel)
        : await axiosHelper.post(`${config.api}/channel`, channel)
      ).data;
      dispatch(fetchSelectedChannelSuccess(newChannel));

      // Add toast
      addToast(`Channel ${channelId ? 'updated' : 'created'} successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      // If creation - redirect to edit page
      if (!channelId) {
        history.push(`/channel-item/${newChannel._id}`);
      }
    } catch (e) {
      dispatch(fetchSelectedChannelError(e));

      // Add toast
      addToast(
        `Channel ${channelId ? 'update' : 'creation'} failed. Try again later.`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  };

  const handleDelete = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Delete channel
      await axiosHelper.delete(`${config.api}/channel/${channel._id}`);
      dispatch(fetchSelectedChannelSuccess(null));

      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('Channel deleted successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });

      // Redirect
      history.push(`/channels`);
    } catch (e) {
      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('Channel deletion failed. Try again later.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const contentEditorHandleChange = useCallback(
    (data, lang) => handleChange(`bio.${lang}`, data),
    [handleChange],
  );

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <div className={'PageTitle'}>
          <div className={'TitleContent'}>
            <h1>
              {channelId
                ? `Editing ${channel.channelName}`
                : `Creating Channel`}
            </h1>
            <Button
              primary
              onClick={() => {
                // Clear existing selectedContent data
                dispatch(
                  fetchSelectedContentSuccess(
                    BuildInitialContent(loggedInUser._id, channel._id),
                  ),
                );

                history.push(`/content-item/?channelId=${channel._id}`);
              }}
            >
              Add Content
            </Button>
          </div>

          <div className={'MetaCount'}>
            <FontAwesomeIcon icon={faUser} />
            <p className={'bold-type'}>{channel.followerCount}</p>
          </div>
        </div>

        {system?.systemAdmins.find(
          (admin) => admin === loggedInUser._id.toString(),
        ) && (
          <>
            <SectionHeader title={'Channel Admins'} />
            <Form.Field>
              <label>Admin Users</label>
              <Dropdown
                placeholder={'Type to search Users'}
                noResultsMessage={'Type to search Users'}
                fluid
                multiple
                search
                onSearchChange={async (e, data) => {
                  await handleSearch(
                    loggedInUser.token,
                    SchemaName.USER,
                    data.searchQuery,
                    userSearchResults,
                    setUserSearchResults,
                  );
                }}
                onChange={(e, data) => handleChange('channelAdmins', data)}
                selection
                options={userSearchResults}
                value={channel.channelAdmins}
                renderLabel={(item) => {
                  return {
                    content: <>{item.text}</>,
                  };
                }}
              />
            </Form.Field>
            <Divider section />
          </>
        )}

        <SectionHeader
          data={channel}
          fields={['channelName', 'description']}
          title={'Channel Information'}
        />

        <Form.Field required>
          <label>Channel Name</label>
          <Form.Input
            placeholder={'Enter a channel name'}
            value={
              get(channel, getModerationField(channel, 'channelName')) || ''
            }
            onChange={(e, data) => handleChange('channelName', data)}
            error={!get(channel, getModerationField(channel, 'channelName'))}
          />
        </Form.Field>

        {/*<Form.Field required>*/}
        {/*  <label className={'LangStringSelector'}>*/}
        {/*    <span*/}
        {/*      className={*/}
        {/*        langStrings.description !== Language.EN ? 'normal-type' : ''*/}
        {/*      }*/}
        {/*      onClick={() => handleLangChange('description', Language.EN)}*/}
        {/*    >*/}
        {/*      Description*/}
        {/*    </span>*/}
        {/*    <span className={'normal-type'}> | </span>*/}
        {/*    <span*/}
        {/*      className={*/}
        {/*        langStrings.description !== Language.CY ? 'normal-type' : ''*/}
        {/*      }*/}
        {/*      onClick={() => handleLangChange('description', Language.CY)}*/}
        {/*    >*/}
        {/*      Disgrifiad*/}
        {/*    </span>*/}
        {/*  </label>*/}
        {/*  {langStrings.description === Language.EN && (*/}
        {/*    <Form.TextArea*/}
        {/*      placeholder={'Enter a description'}*/}
        {/*      value={*/}
        {/*        get(*/}
        {/*          channel,*/}
        {/*          getModerationField(channel, `description.${Language.EN}`),*/}
        {/*        ) || ''*/}
        {/*      }*/}
        {/*      onChange={(e, data) =>*/}
        {/*        handleChange(`description.${Language.EN}`, data)*/}
        {/*      }*/}
        {/*      error={*/}
        {/*        !get(*/}
        {/*          channel,*/}
        {/*          getModerationField(channel, `description.${Language.EN}`),*/}
        {/*        )*/}
        {/*      }*/}
        {/*    />*/}
        {/*  )}*/}
        {/*  {langStrings.description === Language.CY && (*/}
        {/*    <Form.TextArea*/}
        {/*      placeholder={'Rhowch ddisgrifiad'}*/}
        {/*      value={*/}
        {/*        get(*/}
        {/*          channel,*/}
        {/*          getModerationField(channel, `description.${Language.CY}`),*/}
        {/*        ) || ''*/}
        {/*      }*/}
        {/*      onChange={(e, data) =>*/}
        {/*        handleChange(`description.${Language.CY}`, data)*/}
        {/*      }*/}
        {/*      error={*/}
        {/*        !get(*/}
        {/*          channel,*/}
        {/*          getModerationField(channel, `description.${Language.CY}`),*/}
        {/*        )*/}
        {/*      }*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</Form.Field>*/}

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['featuredContentId']}
          title={'Featured Content'}
        />

        <Form.Field>
          <Form.Checkbox
            toggle
            label={'Automatically feature latest content'}
            checked={get(channel, 'featuredContentLatest') || false}
            onChange={(e, data) =>
              handleChange(`featuredContentLatest`, {
                value: !!data.checked,
              })
            }
          />
        </Form.Field>

        {!get(channel, 'featuredContentLatest') && (
          <Form.Field>
            <label>Featured Content</label>
            <Dropdown
              placeholder={'Type to search Content'}
              noResultsMessage={'Type to search Content'}
              fluid
              search
              clearable
              onSearchChange={async (e, data) => {
                await handleSearch(
                  loggedInUser.token,
                  SchemaName.CONTENT,
                  data.searchQuery,
                  contentSearchResults,
                  setContentSearchResults,
                  {
                    channelId: new Types.ObjectId(channel._id),
                  },
                );
              }}
              onChange={(e, data) => {
                let val: any = data?.value as string;
                if (!val?.length) {
                  val = null;
                }

                handleChange('featuredContentId', {value: val});
              }}
              selection
              options={contentSearchResults}
              value={get(
                channel,
                getModerationField(channel, `featuredContentId`, true),
              )}
            />
          </Form.Field>
        )}

        {!get(channel, 'featuredContentLatest') &&
          get(
            channel,
            getModerationField(channel, `featuredContentId`, true),
          ) && (
            <p className={'InputLink'}>
              <Link
                to={`/content-item/${get(
                  channel,
                  getModerationField(channel, `featuredContentId`),
                )}`}
              >
                View Content
              </Link>
            </p>
          )}

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['theme']}
          title={'Channel Appearance'}
        />

        <div className={'ChannelAppearanceWrapper'}>
          <div className={'ChannelAvatar'}>
            <Form.Field required>
              <label className={'LangStringSelector'}>Channel Avatar</label>
              <div
                className={'ChannelAvatarPreview'}
                style={
                  channel &&
                  get(channel, getModerationField(channel, `theme.logo`)) && {
                    backgroundImage: `url(${config.api}/file/${get(
                      channel,
                      getModerationField(channel, `theme.logo`),
                    )})`,
                  }
                }
              />
              <Form.Input
                placeholder={'Enter an ALT tag'}
                value={
                  get(channel, getModerationField(channel, `theme.logoAlt`)) ||
                  get(channel, getModerationField(channel, `channelName`)) ||
                  ''
                }
                onChange={(e, data) => handleChange(`theme.logoAlt`, data)}
              />
              <Button
                primary
                onClick={(event) => {
                  event.preventDefault();

                  dispatch(
                    showCommonModal({
                      open: true,
                      headerText: 'Upload Avatar',
                      buttons: [
                        {
                          className: 'button primary',
                          buttonText: 'Save Avatar',
                          action: 'upload',
                        },
                        {
                          className: 'button secondary',
                          buttonText: 'Cancel',
                          action: CommonModalActionTypes.CLOSE,
                        },
                      ],
                      upload: {
                        documentId: channel._id,
                        model: SchemaName.CHANNEL,
                        subModel: 'logo',
                        field: `theme.logo`,
                        size: {
                          width: 150,
                          height: 150,
                          minWidth: 150,
                          minHeight: 150,
                        },
                        aspect: {
                          width: 10,
                          height: 10,
                        },
                      },
                    }),
                  );
                }}
              >
                Upload Avatar
              </Button>
            </Form.Field>
          </div>

          <div className={'ChannelBanner'}>
            <Form.Field>
              <label className={'LangStringSelector'}>
                Channel Banner (Minimum size: 768px x 212px)
              </label>
              <div
                className={'ChannelBannerPreview'}
                style={
                  channel &&
                  get(channel, getModerationField(channel, `theme.header`)) && {
                    backgroundImage: `url(${config.api}/file/${get(
                      channel,
                      getModerationField(channel, `theme.header`),
                    )})`,
                  }
                }
              />
              <Form.Input
                placeholder={'Enter an ALT tag'}
                value={
                  get(
                    channel,
                    getModerationField(channel, `theme.headerAlt`),
                  ) ||
                  get(channel, getModerationField(channel, `channelName`)) ||
                  ''
                }
                onChange={(e, data) => handleChange(`theme.headerAlt`, data)}
              />
              <Button
                primary
                onClick={(event) => {
                  event.preventDefault();

                  dispatch(
                    showCommonModal({
                      open: true,
                      headerText: 'Upload Banner',
                      buttons: [
                        {
                          className: 'button primary',
                          buttonText: 'Save Banner',
                          action: 'upload',
                        },
                        {
                          className: 'button secondary',
                          buttonText: 'Cancel',
                          action: CommonModalActionTypes.CLOSE,
                        },
                      ],
                      upload: {
                        documentId: channel._id,
                        model: SchemaName.CHANNEL,
                        subModel: 'header',
                        field: `theme.header`,
                        size: {
                          width: 768,
                          height: 212,
                          minWidth: 768,
                          minHeight: 212,
                        },
                        aspect: {
                          width: 16,
                          height: 4.4,
                        },
                      },
                    }),
                  );
                }}
              >
                Upload Banner
              </Button>
            </Form.Field>
          </div>
        </div>

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['bio']}
          title={'Channel Biography'}
        />

        <Form.Field>
          <label className={'LangStringSelector'}>
            <span
              className={langStrings.bio !== Language.EN ? 'normal-type' : ''}
              onClick={() => handleLangChange('bio', Language.EN)}
            >
              Biography
            </span>
            <span className={'normal-type'}> | </span>
            <span
              className={langStrings.bio !== Language.CY ? 'normal-type' : ''}
              onClick={() => handleLangChange('bio', Language.CY)}
            >
              Bywgraffiad
            </span>
          </label>
          {langStrings.bio === Language.EN && contentEditorReady && (
            <ContentEditor
              content={get(
                channel,
                getModerationField(channel, `bio.${Language.EN}`),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.EN)
              }
            />
          )}
          {langStrings.bio === Language.CY && contentEditorReady && (
            <ContentEditor
              content={get(
                channel,
                getModerationField(channel, `bio.${Language.CY}`),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.CY)
              }
            />
          )}
        </Form.Field>

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['social']}
          title={'Social Links'}
        />

        {Object.keys(SocialPlatform).map((k, index) => {
          return (
            <Form.Field key={`social-${k}-${index}`}>
              <label>{startCase(SocialPlatform[k])}</label>
              <Form.Input
                placeholder={
                  k === SocialPlatform.EMAIL
                    ? 'Enter an Email address'
                    : `Enter a ${startCase(SocialPlatform[k])} url`
                }
                value={
                  get(
                    channel,
                    getModerationField(channel, `social.${SocialPlatform[k]}`),
                  ) || ''
                }
                onChange={(e, data) =>
                  handleChange(`social.${SocialPlatform[k]}`, data)
                }
              />
            </Form.Field>
          );
        })}

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['tags']}
          title={'Channel Tags'}
        />

        <Form.Field>
          <label>Public Tags</label>
          <Form.Dropdown
            placeholder={'Type to add tags'}
            noResultsMessage={'Type to add tags'}
            fluid
            multiple
            selection
            allowAdditions
            search
            value={
              get(channel, getModerationField(channel, 'tags.public')) || []
            }
            options={
              channel && channel.tags
                ? buildTagOptions(
                    get(channel, getModerationField(channel, 'tags.public')),
                    'public',
                  )
                : []
            }
            onChange={(e, data) => handleChange(`tags.public`, data)}
          />
        </Form.Field>

        <Form.Field>
          <label>Private Tags</label>
          <Form.Dropdown
            placeholder={'Type to add tags'}
            noResultsMessage={'Type to add tags'}
            fluid
            multiple
            selection
            allowAdditions
            search
            value={
              get(channel, getModerationField(channel, 'tags.private')) || []
            }
            options={
              channel && channel.tags
                ? buildTagOptions(
                    get(channel, getModerationField(channel, 'tags.private')),
                    'private',
                  )
                : []
            }
            onChange={(e, data) => handleChange(`tags.private`, data)}
          />
        </Form.Field>

        <Divider section />

        <SectionHeader
          data={channel}
          fields={['highlightsTitle']}
          title={'Highlights'}
        />

        <Form.Field>
          <label>
            <span
              className={
                langStrings.highlightsTitle !== Language.EN ? 'normal-type' : ''
              }
            >
              Highlights Title
            </span>
          </label>
          {langStrings.highlightsTitle === Language.EN && (
            <Form.Input
              placeholder={'Enter a highlights title'}
              value={
                get(
                  channel,
                  getModerationField(channel, `highlightsTitle.${Language.EN}`),
                ) || 'Highlights'
              }
              onChange={(e, data) =>
                handleChange(`highlightsTitle.${Language.EN}`, data)
              }
              error={
                !get(
                  channel,
                  getModerationField(channel, `highlightsTitle.${Language.EN}`),
                )
              }
            />
          )}
        </Form.Field>

        <CategoryTable channel={channel} />
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>

        {channel.urlSlug && (
          <Form.Field>
            <label>URL Slug</label>
            <Form.Input
              placeholder={'Preview URL'}
              value={get(channel, getModerationField(channel, 'urlSlug'))}
              onChange={(e, data) => handleChange(`urlSlug`, data)}
            />
            <p className={'InputLink'}>
              <a
                href={`${config.webUrl}/${get(
                  channel,
                  getModerationField(channel, 'urlSlug'),
                )}`}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                View Channel
              </a>
            </p>
          </Form.Field>
        )}

        <Form.Field required>
          <label>Status</label>
          <Select
            fluid
            placeholder={'Select a status'}
            options={publishStatuses()
              .filter((i) => {
                if (loggedInUser?.accessType !== 'admin') {
                  const status =
                    get(channel, `publishMeta.status`) || publishStatuses()[0];
                  if (status === PublishStatus.AWAITING_MODERATION) {
                    if (
                      i === PublishStatus.PUBLISHED ||
                      i === PublishStatus.REJECTED
                    ) {
                      return false;
                    }
                  }
                }

                return true;
              })
              .map((status, index) => {
                return {
                  key: `channel-status-${status}-${index}`,
                  value: status,
                  text: startCase(status),
                };
              })}
            value={get(channel, `publishMeta.status`) || publishStatuses()[0]}
            onChange={(e, data) => handleChange(`publishMeta.status`, data)}
          />
        </Form.Field>

        {channel.publishMeta.status === PublishStatus.REJECTED && (
          <Form.Field>
            <label>Moderation Note</label>
            <Form.TextArea
              disabled={loggedInUser?.accessType !== 'admin'}
              placeholder={'Enter any moderation notes'}
              value={
                get(
                  channel,
                  getModerationField(channel, `moderation.moderationNote`),
                ) || ''
              }
              onChange={(e, data) =>
                handleChange(`moderation.moderationNote`, data)
              }
            />
          </Form.Field>
        )}

        <Form.Field required>
          <label>Publish Date</label>
          <DatePicker
            selected={
              (channel &&
                channel.publishMeta &&
                new Date(channel.publishMeta.publishDate)) ||
              new Date()
            }
            onChange={(date) =>
              handleChange('publishMeta.publishDate', {value: date})
            }
            showTimeSelect
            timeIntervals={5}
            dateFormat={'MMM d, yyyy h:mm aa'}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '-100px, 5px',
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </Form.Field>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateChannel(channel)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            {channelId ? 'Update' : 'Create'} Channel
          </Button>
          {channelId && (
            <Button
              className={'PageSidebarDelete'}
              color={'red'}
              size={'tiny'}
              onClick={() =>
                dispatch(
                  showCommonModal({
                    open: true,
                    headerText: 'Delete Channel',
                    bodyText:
                      'Are you sure you wish to delete this channel? This action cannot be undone.',
                    buttons: [
                      {
                        className: 'button red',
                        buttonText: 'Delete Channel',
                        action: handleDelete,
                      },
                      {
                        className: 'button primary',
                        buttonText: 'Cancel',
                        action: CommonModalActionTypes.CLOSE,
                      },
                    ],
                  }),
                )
              }
            >
              Delete Channel
            </Button>
          )}
        </div>
      </aside>
    </Form>
  );
};

export default ChannelItemView;
