import {get} from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Form} from 'semantic-ui-react';
import config from '../../config/config';
import {CommonModalActionTypes} from '../../const/enums/CommonModal';
import {FeatureType} from '../../const/enums/Content';
import {SchemaName} from '../../const/enums/Schema';
import {Content} from '../../const/interfaces/Content';
import {showCommonModal} from '../../redux/actions/common-modal/CommonModalAction';
import {getModerationField} from '../../util/ModerationHelper';

export interface ContentMediaCoverProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
  required?: boolean;
}

const ContentMediaCover = ({
  content,
  handleChange,
  comingSoon,
  required = true,
}: ContentMediaCoverProps) => {
  const dispatch = useDispatch();

  const field = comingSoon
    ? `comingSoonContent.featureContent`
    : `featureContent`;

  const featureContentMediaId = get(
    content,
    getModerationField(content, `${field}.coverId`, true),
  );

  return (
    <div className={'FeatureType'}>
      <Form.Field required={required}>
        <label>Cover Image</label>
        <div
          className={'FeatureTypeMediaCover'}
          style={
            featureContentMediaId &&
            featureContentMediaId.length && {
              backgroundImage: `url(${config.api}/file/${get(
                content,
                getModerationField(content, `${field}.coverId`, true),
              )})`,
            }
          }
        />

        <div className={'FeatureTypeMediaCoverActions'}>
          <Form.Input
            placeholder={'Enter an ALT tag'}
            value={
              get(content, getModerationField(content, `${field}.coverAlt`)) ||
              get(content, getModerationField(content, `contentName`)) ||
              ''
            }
            onChange={(e, data) => handleChange(`${field}.coverAlt`, data)}
          />

          <Button
            primary
            onClick={(event) => {
              event.preventDefault();

              dispatch(
                showCommonModal({
                  open: true,
                  headerText: 'Upload Cover Image',
                  buttons: [
                    {
                      className: 'button primary',
                      buttonText: 'Save Cover Image',
                      action: 'upload',
                    },
                    {
                      className: 'button secondary',
                      buttonText: 'Cancel',
                      action: CommonModalActionTypes.CLOSE,
                    },
                  ],
                  upload: {
                    documentId: content._id,
                    model: SchemaName.CONTENT,
                    subModel: FeatureType.MEDIA,
                    field: `${field}.coverId`,
                    size: {
                      width: 393,
                      height: 223,
                      minWidth: 393,
                      minHeight: 223,
                    },
                    aspect: {
                      width: 393,
                      height: 223,
                    },
                  },
                }),
              );
            }}
          >
            Upload Cover Image
          </Button>

          {featureContentMediaId && featureContentMediaId.length && (
            <Button
              color={'red'}
              size={'tiny'}
              onClick={(event) => {
                event.preventDefault();

                handleChange(getModerationField(content, `${field}.coverId`), {
                  value: null,
                });
              }}
            >
              Delete Cover Image
            </Button>
          )}
        </div>
      </Form.Field>
    </div>
  );
};

export default ContentMediaCover;
