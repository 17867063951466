import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Form} from 'semantic-ui-react';
import config from '../../config/config';
import {ContentType} from '../../const/enums/Content';
import {Content} from '../../const/interfaces/Content';
import {User} from '../../const/interfaces/User';
import AxiosHelper from '../../util/AxiosHelper';
import {getModerationField} from '../../util/ModerationHelper';
import ContentThumbnail from './ContentThumbnail';

export interface SpotifyProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
}

const Spotify = ({content, handleChange, comingSoon}: SpotifyProps) => {
  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const {addToast} = useToasts();

  const field = comingSoon ? `comingSoonContent.primaryContent` : `primaryContent`;
  const url = get(
    content,
    getModerationField(content, `${field}.${ContentType.SPOTIFY}.url`),
  );
  const existingThumbnail = get(
    content,
    getModerationField(
      content,
      `${field}.${ContentType.SPOTIFY}.mediaUrl`,
    ),
  );

  const [thumbnailVideoUrl, setThumbnailVideoUrl] = useState(url);
  const [thumbnail, setThumbnail] = useState(existingThumbnail);

  useEffect(() => {
    if (url && url !== thumbnailVideoUrl) {
      setThumbnailVideoUrl(url);
      (async () => {
        try {
          if (loggedInUser && loggedInUser.token) {
            const axiosHelper = new AxiosHelper(loggedInUser.token);
            const strippedUrl = url.replace('/embed', '');
            const contentData = await axiosHelper.get(
              `${config.api}/content/provider/spotify`,
              {
                spotifyUrl: strippedUrl,
              },
            );
            let thumbnail;
            if (contentData) {
              thumbnail = contentData.data.thumbnail_url;
              handleChange(`${field}.${ContentType.SPOTIFY}.mediaUrl`, {
                value: thumbnail,
              });
              setThumbnail(thumbnail);
            }
          }
        } catch (e) {
          return addToast(`Thumbnail could not be found.`, {
            appearance: 'warning',
            autoDismiss: true,
          });
        }
      })();
    }
  }, [url, handleChange, thumbnailVideoUrl, addToast, loggedInUser, field]);

  if (!content || !handleChange) {
    return null;
  }

  const isPodcast = content.primaryContent[ContentType.SPOTIFY]?.isPodcast;

  return (
    <div className={'ContentType'}>
      <div className={'ContentTypeInputs'}>
        <Form.Field required>
          <label>Spotify URL</label>
          <Form.Input
            placeholder={
              isPodcast
                ? 'Enter a Spotify podcast URL e.g. ' +
                  'https://open.spotify.com/episode/6MLOOSdEMOv2GKaVtJL8n7'
                : 'Enter a Spotify URL e.g. https://open.spotify.com/track/6mVLekJKZIwnFR2sX5KNEe'
            }
            value={
              get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.SPOTIFY}.url`,
                ),
              ) || ''
            }
            onChange={(e, data) =>
              handleChange(`${field}.${ContentType.SPOTIFY}.url`, data)
            }
            error={
              !get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.SPOTIFY}.url`,
                ),
              )
            }
          />
        </Form.Field>
      </div>

      <div>
        <ContentThumbnail
          content={content}
          handleChange={handleChange}
          thumbnail={thumbnail}
          comingSoon={comingSoon}
        />
      </div>
    </div>
  );
};

export default Spotify;
