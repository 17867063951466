const countryOptions = [
  {
    key: 'country-Andorra-0',
    value: 'Andorra',
    text: 'Andorra',
  },
  {
    key: 'country-United Arab Emirates-1',
    value: 'United Arab Emirates',
    text: 'United Arab Emirates',
  },
  {
    key: 'country-Afghanistan-2',
    value: 'Afghanistan',
    text: 'Afghanistan',
  },
  {
    key: 'country-Antigua and Barbuda-3',
    value: 'Antigua and Barbuda',
    text: 'Antigua and Barbuda',
  },
  {
    key: 'country-Anguilla-4',
    value: 'Anguilla',
    text: 'Anguilla',
  },
  {
    key: 'country-Albania-5',
    value: 'Albania',
    text: 'Albania',
  },
  {
    key: 'country-Armenia-6',
    value: 'Armenia',
    text: 'Armenia',
  },
  {
    key: 'country-Angola-7',
    value: 'Angola',
    text: 'Angola',
  },
  {
    key: 'country-Antarctica-8',
    value: 'Antarctica',
    text: 'Antarctica',
  },
  {
    key: 'country-Argentina-9',
    value: 'Argentina',
    text: 'Argentina',
  },
  {
    key: 'country-American Samoa-10',
    value: 'American Samoa',
    text: 'American Samoa',
  },
  {
    key: 'country-Austria-11',
    value: 'Austria',
    text: 'Austria',
  },
  {
    key: 'country-Australia-12',
    value: 'Australia',
    text: 'Australia',
  },
  {
    key: 'country-Aruba-13',
    value: 'Aruba',
    text: 'Aruba',
  },
  {
    key: 'country-Aland Islands-14',
    value: 'Aland Islands',
    text: 'Aland Islands',
  },
  {
    key: 'country-Azerbaijan-15',
    value: 'Azerbaijan',
    text: 'Azerbaijan',
  },
  {
    key: 'country-Bosnia and Herzegovina-16',
    value: 'Bosnia and Herzegovina',
    text: 'Bosnia and Herzegovina',
  },
  {
    key: 'country-Barbados-17',
    value: 'Barbados',
    text: 'Barbados',
  },
  {
    key: 'country-Bangladesh-18',
    value: 'Bangladesh',
    text: 'Bangladesh',
  },
  {
    key: 'country-Belgium-19',
    value: 'Belgium',
    text: 'Belgium',
  },
  {
    key: 'country-Burkina Faso-20',
    value: 'Burkina Faso',
    text: 'Burkina Faso',
  },
  {
    key: 'country-Bulgaria-21',
    value: 'Bulgaria',
    text: 'Bulgaria',
  },
  {
    key: 'country-Bahrain-22',
    value: 'Bahrain',
    text: 'Bahrain',
  },
  {
    key: 'country-Burundi-23',
    value: 'Burundi',
    text: 'Burundi',
  },
  {
    key: 'country-Benin-24',
    value: 'Benin',
    text: 'Benin',
  },
  {
    key: 'country-Saint Barthelemy-25',
    value: 'Saint Barthelemy',
    text: 'Saint Barthelemy',
  },
  {
    key: 'country-Bermuda-26',
    value: 'Bermuda',
    text: 'Bermuda',
  },
  {
    key: 'country-Brunei-27',
    value: 'Brunei',
    text: 'Brunei',
  },
  {
    key: 'country-Bolivia-28',
    value: 'Bolivia',
    text: 'Bolivia',
  },
  {
    key: 'country-Bonaire, Saint Eustatius and Saba-29',
    value: 'Bonaire, Saint Eustatius and Saba',
    text: 'Bonaire, Saint Eustatius and Saba',
  },
  {
    key: 'country-Brazil-30',
    value: 'Brazil',
    text: 'Brazil',
  },
  {
    key: 'country-Bahamas-31',
    value: 'Bahamas',
    text: 'Bahamas',
  },
  {
    key: 'country-Bhutan-32',
    value: 'Bhutan',
    text: 'Bhutan',
  },
  {
    key: 'country-Bouvet Island-33',
    value: 'Bouvet Island',
    text: 'Bouvet Island',
  },
  {
    key: 'country-Botswana-34',
    value: 'Botswana',
    text: 'Botswana',
  },
  {
    key: 'country-Belarus-35',
    value: 'Belarus',
    text: 'Belarus',
  },
  {
    key: 'country-Belize-36',
    value: 'Belize',
    text: 'Belize',
  },
  {
    key: 'country-Canada-37',
    value: 'Canada',
    text: 'Canada',
  },
  {
    key: 'country-Cocos Islands-38',
    value: 'Cocos Islands',
    text: 'Cocos Islands',
  },
  {
    key: 'country-Democratic Republic of the Congo-39',
    value: 'Democratic Republic of the Congo',
    text: 'Democratic Republic of the Congo',
  },
  {
    key: 'country-Central African Republic-40',
    value: 'Central African Republic',
    text: 'Central African Republic',
  },
  {
    key: 'country-Republic of the Congo-41',
    value: 'Republic of the Congo',
    text: 'Republic of the Congo',
  },
  {
    key: 'country-Switzerland-42',
    value: 'Switzerland',
    text: 'Switzerland',
  },
  {
    key: 'country-Ivory Coast-43',
    value: 'Ivory Coast',
    text: 'Ivory Coast',
  },
  {
    key: 'country-Cook Islands-44',
    value: 'Cook Islands',
    text: 'Cook Islands',
  },
  {
    key: 'country-Chile-45',
    value: 'Chile',
    text: 'Chile',
  },
  {
    key: 'country-Cameroon-46',
    value: 'Cameroon',
    text: 'Cameroon',
  },
  {
    key: 'country-China-47',
    value: 'China',
    text: 'China',
  },
  {
    key: 'country-Colombia-48',
    value: 'Colombia',
    text: 'Colombia',
  },
  {
    key: 'country-Costa Rica-49',
    value: 'Costa Rica',
    text: 'Costa Rica',
  },
  {
    key: 'country-Cuba-50',
    value: 'Cuba',
    text: 'Cuba',
  },
  {
    key: 'country-Cabo Verde-51',
    value: 'Cabo Verde',
    text: 'Cabo Verde',
  },
  {
    key: 'country-Curacao-52',
    value: 'Curacao',
    text: 'Curacao',
  },
  {
    key: 'country-Christmas Island-53',
    value: 'Christmas Island',
    text: 'Christmas Island',
  },
  {
    key: 'country-Cyprus-54',
    value: 'Cyprus',
    text: 'Cyprus',
  },
  {
    key: 'country-Czechia-55',
    value: 'Czechia',
    text: 'Czechia',
  },
  {
    key: 'country-Germany-56',
    value: 'Germany',
    text: 'Germany',
  },
  {
    key: 'country-Djibouti-57',
    value: 'Djibouti',
    text: 'Djibouti',
  },
  {
    key: 'country-Denmark-58',
    value: 'Denmark',
    text: 'Denmark',
  },
  {
    key: 'country-Dominica-59',
    value: 'Dominica',
    text: 'Dominica',
  },
  {
    key: 'country-Dominican Republic-60',
    value: 'Dominican Republic',
    text: 'Dominican Republic',
  },
  {
    key: 'country-Algeria-61',
    value: 'Algeria',
    text: 'Algeria',
  },
  {
    key: 'country-Ecuador-62',
    value: 'Ecuador',
    text: 'Ecuador',
  },
  {
    key: 'country-Estonia-63',
    value: 'Estonia',
    text: 'Estonia',
  },
  {
    key: 'country-Egypt-64',
    value: 'Egypt',
    text: 'Egypt',
  },
  {
    key: 'country-Western Sahara-65',
    value: 'Western Sahara',
    text: 'Western Sahara',
  },
  {
    key: 'country-Eritrea-66',
    value: 'Eritrea',
    text: 'Eritrea',
  },
  {
    key: 'country-Spain-67',
    value: 'Spain',
    text: 'Spain',
  },
  {
    key: 'country-Ethiopia-68',
    value: 'Ethiopia',
    text: 'Ethiopia',
  },
  {
    key: 'country-Finland-69',
    value: 'Finland',
    text: 'Finland',
  },
  {
    key: 'country-Fiji-70',
    value: 'Fiji',
    text: 'Fiji',
  },
  {
    key: 'country-Falkland Islands-71',
    value: 'Falkland Islands',
    text: 'Falkland Islands',
  },
  {
    key: 'country-Micronesia-72',
    value: 'Micronesia',
    text: 'Micronesia',
  },
  {
    key: 'country-Faroe Islands-73',
    value: 'Faroe Islands',
    text: 'Faroe Islands',
  },
  {
    key: 'country-France-74',
    value: 'France',
    text: 'France',
  },
  {
    key: 'country-Gabon-75',
    value: 'Gabon',
    text: 'Gabon',
  },
  {
    key: 'country-United Kingdom-76',
    value: 'United Kingdom',
    text: 'United Kingdom',
  },
  {
    key: 'country-Grenada-77',
    value: 'Grenada',
    text: 'Grenada',
  },
  {
    key: 'country-Georgia-78',
    value: 'Georgia',
    text: 'Georgia',
  },
  {
    key: 'country-French Guiana-79',
    value: 'French Guiana',
    text: 'French Guiana',
  },
  {
    key: 'country-Guernsey-80',
    value: 'Guernsey',
    text: 'Guernsey',
  },
  {
    key: 'country-Ghana-81',
    value: 'Ghana',
    text: 'Ghana',
  },
  {
    key: 'country-Gibraltar-82',
    value: 'Gibraltar',
    text: 'Gibraltar',
  },
  {
    key: 'country-Greenland-83',
    value: 'Greenland',
    text: 'Greenland',
  },
  {
    key: 'country-Gambia-84',
    value: 'Gambia',
    text: 'Gambia',
  },
  {
    key: 'country-Guinea-85',
    value: 'Guinea',
    text: 'Guinea',
  },
  {
    key: 'country-Guadeloupe-86',
    value: 'Guadeloupe',
    text: 'Guadeloupe',
  },
  {
    key: 'country-Equatorial Guinea-87',
    value: 'Equatorial Guinea',
    text: 'Equatorial Guinea',
  },
  {
    key: 'country-Greece-88',
    value: 'Greece',
    text: 'Greece',
  },
  {
    key: 'country-South Georgia and the South Sandwich Islands-89',
    value: 'South Georgia and the South Sandwich Islands',
    text: 'South Georgia and the South Sandwich Islands',
  },
  {
    key: 'country-Guatemala-90',
    value: 'Guatemala',
    text: 'Guatemala',
  },
  {
    key: 'country-Guam-91',
    value: 'Guam',
    text: 'Guam',
  },
  {
    key: 'country-Guinea-Bissau-92',
    value: 'Guinea-Bissau',
    text: 'Guinea-Bissau',
  },
  {
    key: 'country-Guyana-93',
    value: 'Guyana',
    text: 'Guyana',
  },
  {
    key: 'country-Hong Kong-94',
    value: 'Hong Kong',
    text: 'Hong Kong',
  },
  {
    key: 'country-Heard Island and McDonald Islands-95',
    value: 'Heard Island and McDonald Islands',
    text: 'Heard Island and McDonald Islands',
  },
  {
    key: 'country-Honduras-96',
    value: 'Honduras',
    text: 'Honduras',
  },
  {
    key: 'country-Croatia-97',
    value: 'Croatia',
    text: 'Croatia',
  },
  {
    key: 'country-Haiti-98',
    value: 'Haiti',
    text: 'Haiti',
  },
  {
    key: 'country-Hungary-99',
    value: 'Hungary',
    text: 'Hungary',
  },
  {
    key: 'country-Indonesia-100',
    value: 'Indonesia',
    text: 'Indonesia',
  },
  {
    key: 'country-Ireland-101',
    value: 'Ireland',
    text: 'Ireland',
  },
  {
    key: 'country-Israel-102',
    value: 'Israel',
    text: 'Israel',
  },
  {
    key: 'country-Isle of Man-103',
    value: 'Isle of Man',
    text: 'Isle of Man',
  },
  {
    key: 'country-India-104',
    value: 'India',
    text: 'India',
  },
  {
    key: 'country-British Indian Ocean Territory-105',
    value: 'British Indian Ocean Territory',
    text: 'British Indian Ocean Territory',
  },
  {
    key: 'country-Iraq-106',
    value: 'Iraq',
    text: 'Iraq',
  },
  {
    key: 'country-Iran-107',
    value: 'Iran',
    text: 'Iran',
  },
  {
    key: 'country-Iceland-108',
    value: 'Iceland',
    text: 'Iceland',
  },
  {
    key: 'country-Italy-109',
    value: 'Italy',
    text: 'Italy',
  },
  {
    key: 'country-Jersey-110',
    value: 'Jersey',
    text: 'Jersey',
  },
  {
    key: 'country-Jamaica-111',
    value: 'Jamaica',
    text: 'Jamaica',
  },
  {
    key: 'country-Jordan-112',
    value: 'Jordan',
    text: 'Jordan',
  },
  {
    key: 'country-Japan-113',
    value: 'Japan',
    text: 'Japan',
  },
  {
    key: 'country-Kenya-114',
    value: 'Kenya',
    text: 'Kenya',
  },
  {
    key: 'country-Kyrgyzstan-115',
    value: 'Kyrgyzstan',
    text: 'Kyrgyzstan',
  },
  {
    key: 'country-Cambodia-116',
    value: 'Cambodia',
    text: 'Cambodia',
  },
  {
    key: 'country-Kiribati-117',
    value: 'Kiribati',
    text: 'Kiribati',
  },
  {
    key: 'country-Comoros-118',
    value: 'Comoros',
    text: 'Comoros',
  },
  {
    key: 'country-Saint Kitts and Nevis-119',
    value: 'Saint Kitts and Nevis',
    text: 'Saint Kitts and Nevis',
  },
  {
    key: 'country-North Korea-120',
    value: 'North Korea',
    text: 'North Korea',
  },
  {
    key: 'country-South Korea-121',
    value: 'South Korea',
    text: 'South Korea',
  },
  {
    key: 'country-Kosovo-122',
    value: 'Kosovo',
    text: 'Kosovo',
  },
  {
    key: 'country-Kuwait-123',
    value: 'Kuwait',
    text: 'Kuwait',
  },
  {
    key: 'country-Cayman Islands-124',
    value: 'Cayman Islands',
    text: 'Cayman Islands',
  },
  {
    key: 'country-Kazakhstan-125',
    value: 'Kazakhstan',
    text: 'Kazakhstan',
  },
  {
    key: 'country-Laos-126',
    value: 'Laos',
    text: 'Laos',
  },
  {
    key: 'country-Lebanon-127',
    value: 'Lebanon',
    text: 'Lebanon',
  },
  {
    key: 'country-Saint Lucia-128',
    value: 'Saint Lucia',
    text: 'Saint Lucia',
  },
  {
    key: 'country-Liechtenstein-129',
    value: 'Liechtenstein',
    text: 'Liechtenstein',
  },
  {
    key: 'country-Sri Lanka-130',
    value: 'Sri Lanka',
    text: 'Sri Lanka',
  },
  {
    key: 'country-Liberia-131',
    value: 'Liberia',
    text: 'Liberia',
  },
  {
    key: 'country-Lesotho-132',
    value: 'Lesotho',
    text: 'Lesotho',
  },
  {
    key: 'country-Lithuania-133',
    value: 'Lithuania',
    text: 'Lithuania',
  },
  {
    key: 'country-Luxembourg-134',
    value: 'Luxembourg',
    text: 'Luxembourg',
  },
  {
    key: 'country-Latvia-135',
    value: 'Latvia',
    text: 'Latvia',
  },
  {
    key: 'country-Libya-136',
    value: 'Libya',
    text: 'Libya',
  },
  {
    key: 'country-Morocco-137',
    value: 'Morocco',
    text: 'Morocco',
  },
  {
    key: 'country-Monaco-138',
    value: 'Monaco',
    text: 'Monaco',
  },
  {
    key: 'country-Moldova-139',
    value: 'Moldova',
    text: 'Moldova',
  },
  {
    key: 'country-Montenegro-140',
    value: 'Montenegro',
    text: 'Montenegro',
  },
  {
    key: 'country-Saint Martin-141',
    value: 'Saint Martin',
    text: 'Saint Martin',
  },
  {
    key: 'country-Madagascar-142',
    value: 'Madagascar',
    text: 'Madagascar',
  },
  {
    key: 'country-Marshall Islands-143',
    value: 'Marshall Islands',
    text: 'Marshall Islands',
  },
  {
    key: 'country-North Macedonia-144',
    value: 'North Macedonia',
    text: 'North Macedonia',
  },
  {
    key: 'country-Mali-145',
    value: 'Mali',
    text: 'Mali',
  },
  {
    key: 'country-Myanmar-146',
    value: 'Myanmar',
    text: 'Myanmar',
  },
  {
    key: 'country-Mongolia-147',
    value: 'Mongolia',
    text: 'Mongolia',
  },
  {
    key: 'country-Macao-148',
    value: 'Macao',
    text: 'Macao',
  },
  {
    key: 'country-Northern Mariana Islands-149',
    value: 'Northern Mariana Islands',
    text: 'Northern Mariana Islands',
  },
  {
    key: 'country-Martinique-150',
    value: 'Martinique',
    text: 'Martinique',
  },
  {
    key: 'country-Mauritania-151',
    value: 'Mauritania',
    text: 'Mauritania',
  },
  {
    key: 'country-Montserrat-152',
    value: 'Montserrat',
    text: 'Montserrat',
  },
  {
    key: 'country-Malta-153',
    value: 'Malta',
    text: 'Malta',
  },
  {
    key: 'country-Mauritius-154',
    value: 'Mauritius',
    text: 'Mauritius',
  },
  {
    key: 'country-Maldives-155',
    value: 'Maldives',
    text: 'Maldives',
  },
  {
    key: 'country-Malawi-156',
    value: 'Malawi',
    text: 'Malawi',
  },
  {
    key: 'country-Mexico-157',
    value: 'Mexico',
    text: 'Mexico',
  },
  {
    key: 'country-Malaysia-158',
    value: 'Malaysia',
    text: 'Malaysia',
  },
  {
    key: 'country-Mozambique-159',
    value: 'Mozambique',
    text: 'Mozambique',
  },
  {
    key: 'country-Namibia-160',
    value: 'Namibia',
    text: 'Namibia',
  },
  {
    key: 'country-New Caledonia-161',
    value: 'New Caledonia',
    text: 'New Caledonia',
  },
  {
    key: 'country-Niger-162',
    value: 'Niger',
    text: 'Niger',
  },
  {
    key: 'country-Norfolk Island-163',
    value: 'Norfolk Island',
    text: 'Norfolk Island',
  },
  {
    key: 'country-Nigeria-164',
    value: 'Nigeria',
    text: 'Nigeria',
  },
  {
    key: 'country-Nicaragua-165',
    value: 'Nicaragua',
    text: 'Nicaragua',
  },
  {
    key: 'country-Netherlands-166',
    value: 'Netherlands',
    text: 'Netherlands',
  },
  {
    key: 'country-Norway-167',
    value: 'Norway',
    text: 'Norway',
  },
  {
    key: 'country-Nepal-168',
    value: 'Nepal',
    text: 'Nepal',
  },
  {
    key: 'country-Nauru-169',
    value: 'Nauru',
    text: 'Nauru',
  },
  {
    key: 'country-Niue-170',
    value: 'Niue',
    text: 'Niue',
  },
  {
    key: 'country-New Zealand-171',
    value: 'New Zealand',
    text: 'New Zealand',
  },
  {
    key: 'country-Oman-172',
    value: 'Oman',
    text: 'Oman',
  },
  {
    key: 'country-Panama-173',
    value: 'Panama',
    text: 'Panama',
  },
  {
    key: 'country-Peru-174',
    value: 'Peru',
    text: 'Peru',
  },
  {
    key: 'country-French Polynesia-175',
    value: 'French Polynesia',
    text: 'French Polynesia',
  },
  {
    key: 'country-Papua New Guinea-176',
    value: 'Papua New Guinea',
    text: 'Papua New Guinea',
  },
  {
    key: 'country-Philippines-177',
    value: 'Philippines',
    text: 'Philippines',
  },
  {
    key: 'country-Pakistan-178',
    value: 'Pakistan',
    text: 'Pakistan',
  },
  {
    key: 'country-Poland-179',
    value: 'Poland',
    text: 'Poland',
  },
  {
    key: 'country-Saint Pierre and Miquelon-180',
    value: 'Saint Pierre and Miquelon',
    text: 'Saint Pierre and Miquelon',
  },
  {
    key: 'country-Pitcairn-181',
    value: 'Pitcairn',
    text: 'Pitcairn',
  },
  {
    key: 'country-Puerto Rico-182',
    value: 'Puerto Rico',
    text: 'Puerto Rico',
  },
  {
    key: 'country-Palestinian Territory-183',
    value: 'Palestinian Territory',
    text: 'Palestinian Territory',
  },
  {
    key: 'country-Portugal-184',
    value: 'Portugal',
    text: 'Portugal',
  },
  {
    key: 'country-Palau-185',
    value: 'Palau',
    text: 'Palau',
  },
  {
    key: 'country-Paraguay-186',
    value: 'Paraguay',
    text: 'Paraguay',
  },
  {
    key: 'country-Qatar-187',
    value: 'Qatar',
    text: 'Qatar',
  },
  {
    key: 'country-Reunion-188',
    value: 'Reunion',
    text: 'Reunion',
  },
  {
    key: 'country-Romania-189',
    value: 'Romania',
    text: 'Romania',
  },
  {
    key: 'country-Serbia-190',
    value: 'Serbia',
    text: 'Serbia',
  },
  {
    key: 'country-Russia-191',
    value: 'Russia',
    text: 'Russia',
  },
  {
    key: 'country-Rwanda-192',
    value: 'Rwanda',
    text: 'Rwanda',
  },
  {
    key: 'country-Saudi Arabia-193',
    value: 'Saudi Arabia',
    text: 'Saudi Arabia',
  },
  {
    key: 'country-Solomon Islands-194',
    value: 'Solomon Islands',
    text: 'Solomon Islands',
  },
  {
    key: 'country-Seychelles-195',
    value: 'Seychelles',
    text: 'Seychelles',
  },
  {
    key: 'country-Sudan-196',
    value: 'Sudan',
    text: 'Sudan',
  },
  {
    key: 'country-South Sudan-197',
    value: 'South Sudan',
    text: 'South Sudan',
  },
  {
    key: 'country-Sweden-198',
    value: 'Sweden',
    text: 'Sweden',
  },
  {
    key: 'country-Singapore-199',
    value: 'Singapore',
    text: 'Singapore',
  },
  {
    key: 'country-Saint Helena-200',
    value: 'Saint Helena',
    text: 'Saint Helena',
  },
  {
    key: 'country-Slovenia-201',
    value: 'Slovenia',
    text: 'Slovenia',
  },
  {
    key: 'country-Svalbard and Jan Mayen-202',
    value: 'Svalbard and Jan Mayen',
    text: 'Svalbard and Jan Mayen',
  },
  {
    key: 'country-Slovakia-203',
    value: 'Slovakia',
    text: 'Slovakia',
  },
  {
    key: 'country-Sierra Leone-204',
    value: 'Sierra Leone',
    text: 'Sierra Leone',
  },
  {
    key: 'country-San Marino-205',
    value: 'San Marino',
    text: 'San Marino',
  },
  {
    key: 'country-Senegal-206',
    value: 'Senegal',
    text: 'Senegal',
  },
  {
    key: 'country-Somalia-207',
    value: 'Somalia',
    text: 'Somalia',
  },
  {
    key: 'country-Suriname-208',
    value: 'Suriname',
    text: 'Suriname',
  },
  {
    key: 'country-Sao Tome and Principe-209',
    value: 'Sao Tome and Principe',
    text: 'Sao Tome and Principe',
  },
  {
    key: 'country-El Salvador-210',
    value: 'El Salvador',
    text: 'El Salvador',
  },
  {
    key: 'country-Sint Maarten-211',
    value: 'Sint Maarten',
    text: 'Sint Maarten',
  },
  {
    key: 'country-Syria-212',
    value: 'Syria',
    text: 'Syria',
  },
  {
    key: 'country-Eswatini-213',
    value: 'Eswatini',
    text: 'Eswatini',
  },
  {
    key: 'country-Turks and Caicos Islands-214',
    value: 'Turks and Caicos Islands',
    text: 'Turks and Caicos Islands',
  },
  {
    key: 'country-Chad-215',
    value: 'Chad',
    text: 'Chad',
  },
  {
    key: 'country-French Southern Territories-216',
    value: 'French Southern Territories',
    text: 'French Southern Territories',
  },
  {
    key: 'country-Togo-217',
    value: 'Togo',
    text: 'Togo',
  },
  {
    key: 'country-Thailand-218',
    value: 'Thailand',
    text: 'Thailand',
  },
  {
    key: 'country-Tajikistan-219',
    value: 'Tajikistan',
    text: 'Tajikistan',
  },
  {
    key: 'country-Tokelau-220',
    value: 'Tokelau',
    text: 'Tokelau',
  },
  {
    key: 'country-Timor Leste-221',
    value: 'Timor Leste',
    text: 'Timor Leste',
  },
  {
    key: 'country-Turkmenistan-222',
    value: 'Turkmenistan',
    text: 'Turkmenistan',
  },
  {
    key: 'country-Tunisia-223',
    value: 'Tunisia',
    text: 'Tunisia',
  },
  {
    key: 'country-Tonga-224',
    value: 'Tonga',
    text: 'Tonga',
  },
  {
    key: 'country-Turkey-225',
    value: 'Turkey',
    text: 'Turkey',
  },
  {
    key: 'country-Trinidad and Tobago-226',
    value: 'Trinidad and Tobago',
    text: 'Trinidad and Tobago',
  },
  {
    key: 'country-Tuvalu-227',
    value: 'Tuvalu',
    text: 'Tuvalu',
  },
  {
    key: 'country-Taiwan-228',
    value: 'Taiwan',
    text: 'Taiwan',
  },
  {
    key: 'country-Tanzania-229',
    value: 'Tanzania',
    text: 'Tanzania',
  },
  {
    key: 'country-Ukraine-230',
    value: 'Ukraine',
    text: 'Ukraine',
  },
  {
    key: 'country-Uganda-231',
    value: 'Uganda',
    text: 'Uganda',
  },
  {
    key: 'country-United States Minor Outlying Islands-232',
    value: 'United States Minor Outlying Islands',
    text: 'United States Minor Outlying Islands',
  },
  {
    key: 'country-United States-233',
    value: 'United States',
    text: 'United States',
  },
  {
    key: 'country-Uruguay-234',
    value: 'Uruguay',
    text: 'Uruguay',
  },
  {
    key: 'country-Uzbekistan-235',
    value: 'Uzbekistan',
    text: 'Uzbekistan',
  },
  {
    key: 'country-Vatican-236',
    value: 'Vatican',
    text: 'Vatican',
  },
  {
    key: 'country-Saint Vincent and the Grenadines-237',
    value: 'Saint Vincent and the Grenadines',
    text: 'Saint Vincent and the Grenadines',
  },
  {
    key: 'country-Venezuela-238',
    value: 'Venezuela',
    text: 'Venezuela',
  },
  {
    key: 'country-British Virgin Islands-239',
    value: 'British Virgin Islands',
    text: 'British Virgin Islands',
  },
  {
    key: 'country-U.S. Virgin Islands-240',
    value: 'U.S. Virgin Islands',
    text: 'U.S. Virgin Islands',
  },
  {
    key: 'country-Vietnam-241',
    value: 'Vietnam',
    text: 'Vietnam',
  },
  {
    key: 'country-Vanuatu-242',
    value: 'Vanuatu',
    text: 'Vanuatu',
  },
  {
    key: 'country-Wallis and Futuna-243',
    value: 'Wallis and Futuna',
    text: 'Wallis and Futuna',
  },
  {
    key: 'country-Samoa-244',
    value: 'Samoa',
    text: 'Samoa',
  },
  {
    key: 'country-Yemen-245',
    value: 'Yemen',
    text: 'Yemen',
  },
  {
    key: 'country-Mayotte-246',
    value: 'Mayotte',
    text: 'Mayotte',
  },
  {
    key: 'country-South Africa-247',
    value: 'South Africa',
    text: 'South Africa',
  },
  {
    key: 'country-Zambia-248',
    value: 'Zambia',
    text: 'Zambia',
  },
  {
    key: 'country-Zimbabwe-249',
    value: 'Zimbabwe',
    text: 'Zimbabwe',
  },
  {
    key: 'country-Serbia and Montenegro-250',
    value: 'Serbia and Montenegro',
    text: 'Serbia and Montenegro',
  },
  {
    key: 'country-Netherlands Antilles-251',
    value: 'Netherlands Antilles',
    text: 'Netherlands Antilles',
  },
];

export default countryOptions;
