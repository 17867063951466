import {
  FETCH_SELECTED_CONTENT_ERROR,
  FETCH_SELECTED_CONTENT_PENDING,
  FETCH_SELECTED_CONTENT_SUCCESS,
} from './SelectedContentActionTypes';

export const fetchSelectedContentPending = () => {
  return {
    type: FETCH_SELECTED_CONTENT_PENDING,
  };
};

export const fetchSelectedContentSuccess = (content) => {
  return {
    type: FETCH_SELECTED_CONTENT_SUCCESS,
    payload: content,
  };
};

export const fetchSelectedContentError = (error) => {
  return {
    type: FETCH_SELECTED_CONTENT_ERROR,
    payload: error,
  };
};
