import JoditEditor from 'jodit-react';
import * as Jodit from 'jodit';
import {Types} from 'mongoose';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import config from '../../config/config';
import {User} from '../../const/interfaces/User';

export interface ContentEditorProps {
  content: any;
  handleChange: any;
}

const ContentEditor = ({content, handleChange}: ContentEditorProps) => {
  const {addToast} = useToasts();
  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;

  const [editorContent, setEditorContent] = useState(content);

  useEffect(() => {
    if (editorContent) {
      handleChange({value: editor.current?.value});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);

  const editor: any = useRef(null);

  const editorConfig = useMemo(() => {
    return {
      uploader: {
        insertImageAsBase64URI: false,
        url: `${config.api}/file`,
        format: 'json',
        method: 'POST',
        filesVariableName: function () {
          return 'file';
        },
        prepareData: function (data) {
          data.append('created', new Date());
          data.append('createdBy', loggedInUser._id);
          data.append('updated', new Date());
          data.append('updatedBy', loggedInUser._id);

          data.delete('path');
          data.delete('source');

          for (const pair of data.entries()) {
            if (pair[0] === 'file') {
              data.set('file', pair[1], `${new Types.ObjectId()}.jpeg`);
            }
          }

          return data;
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMsg: function (resp) {
          return resp.msg.join !== undefined ? resp.msg.join(' ') : resp.msg;
        },
        process: function (resp) {
          let files = [];
          // @ts-ignore
          files.unshift(resp.data);
          return {
            files: resp.data,
            error: resp.msg,
            msg: resp.msg,
          };
        },
        error: function () {
          addToast('Image upload failed. Try again later.', {
            appearance: 'error',
            autoDismiss: true,
          });
        },
        defaultHandlerSuccess: function (data) {
          const j = this;
          if (data.files) {
            const tagName = 'img';
            // @ts-ignore
            const elm = j.createInside.element(tagName);
            elm.setAttribute('src', `${config.api}/file/${data.files._id}`);
            // @ts-ignore
            j.s.insertImage(
              elm as HTMLImageElement,
              null,
              // @ts-ignore
              j.o.imageDefaultWidth,
            );
          }
        },
        defaultHandlerError: function () {
          addToast('Image upload failed. Try again later.', {
            appearance: 'error',
            autoDismiss: true,
          });
        },
      },
      imageDefaultWidth: 600,
      image: {
        editSrc: false,
        editId: false,
        editSize: false,
        editAlign: false,
        editBorderRadius: false,
        editMargins: false,
        editStyle: false,
        editClass: false,
      },
      popup: {
        img: Jodit.Jodit.Array([]),
      },
      minHeight: 400,
      toolbarAdaptive: false,
      allowTabNavigation: true,
      buttons: [
        'source',
        'bold',
        'strikethrough',
        'underline',
        'italic',
        'ul',
        'ol',
        'outdent',
        'indent',
        'paragraph',
        'image',
        'link',
        'align',
        'undo',
        'redo',
      ],
    };
  }, [addToast, loggedInUser._id]);

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        // @ts-ignore
        config={editorConfig}
        onBlur={(newContent) => {
          setEditorContent(newContent);
        }}
      />
    ),
    [content, editorConfig],
  );
};

export default ContentEditor;
