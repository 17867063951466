import {startCase} from 'lodash';

export const buildTagOptions = (tags: string[], type: 'public' | 'private') => {
  return tags.map((tag: string, index: number) => {
    return {
      key: `tag-${type}-${tag}-${index}`,
      text: startCase(tag),
      value: tag,
    };
  });
};
