import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Form} from 'semantic-ui-react';
import {SchemaName} from '../../const/enums/Schema';
import {System} from '../../const/interfaces/System';
import {User} from '../../const/interfaces/User';
import {handleSearch} from '../../util/SearchHelper';
import {SEARCH_TYPING_TIMEOUT} from './AdditionalCuratedItem';

export interface FeaturedProps {
  index: number;
  loggedInUser: User;
  system: System;
  results: any;
  setResults: any;
  categoryResults: any;
  setCategoryResults: any;
  onChange: any;
}

const Featured = ({
  index,
  loggedInUser,
  system,
  results,
  setResults,
  categoryResults,
  setCategoryResults,
  onChange,
}: FeaturedProps) => {
  const [searchQueryContent, setSearchQueryContent] = useState<string>('');
  const [debounceQueryContent, setDebounceQueryContent] = useState<string>('');
  const [loadingContent, setLoadingContent] = useState<boolean>(false);

  const [searchQueryPlaylist, setSearchQueryPlaylist] = useState<string>('');
  const [debounceQueryPlaylist, setDebounceQueryPlaylist] =
    useState<string>('');
  const [loadingPlaylist, setLoadingPlaylist] = useState<boolean>(false);

  const searchContent = async (searchTerm: string) => {
    await handleSearch(
      loggedInUser.token,
      SchemaName.CONTENT,
      searchTerm,
      results,
      setResults,
      undefined,
      true,
    );
  };

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQueryContent !== searchQueryContent) {
        setSearchQueryContent(debounceQueryContent);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQueryContent, searchQueryContent]);

  // Perform search
  useEffect(() => {
    (async () => {
      setLoadingContent(true);
      await searchContent(searchQueryContent);
      setLoadingContent(false);
    })();
  }, [searchQueryContent]);

  const searchPlaylist = async (searchTerm: string) => {
    await handleSearch(
      loggedInUser.token,
      [SchemaName.CATEGORY],
      searchTerm,
      categoryResults,
      setCategoryResults,
      undefined,
      true,
    );
  };

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQueryPlaylist !== searchQueryPlaylist) {
        setSearchQueryPlaylist(debounceQueryPlaylist);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQueryPlaylist, searchQueryPlaylist]);

  // Perform search
  useEffect(() => {
    (async () => {
      setLoadingPlaylist(true);
      await searchPlaylist(searchQueryPlaylist);
      setLoadingPlaylist(false);
    })();
  }, [searchQueryPlaylist]);

  const isCategory =
    system.featuredContent?.content?.highlights?.[index] &&
    system.featuredContent.content.highlights[index].isCategory;

  return (
    <>
      <h3>Featured {index + 1}</h3>
      <Form.Field>
        <label>Content Type</label>
        <Form.Checkbox
          toggle
          label={'Channel Playlist'}
          checked={isCategory}
          onChange={(e, data) =>
            onChange(
              [
                `featuredContent.content.highlights.${index}.isCategory`,
                `featuredContent.content.highlights.${index}.contentId`,
                `featuredContent.content.highlights.${index}.categoryId`,
              ],
              [
                {
                  value: !!data.checked,
                },
                {value: null},
                {value: null},
              ],
            )
          }
        />
      </Form.Field>
      {!isCategory && (
        <Form.Field>
          <label>Content</label>
          <Dropdown
            placeholder={'Type to search Content'}
            noResultsMessage={'Type to search Content'}
            fluid
            clearable
            search
            loading={loadingContent}
            onSearchChange={async (e, data) => {
              if (data.searchQuery !== searchQueryContent) {
                setDebounceQueryContent(data.searchQuery);
              }
            }}
            onChange={(e, data) =>
              onChange(
                `featuredContent.content.highlights.${index}.contentId`,
                data,
              )
            }
            selection
            options={results}
            value={
              system.featuredContent?.content?.highlights?.[index] &&
              system.featuredContent.content.highlights[index].contentId
            }
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />

          {system.featuredContent?.content?.highlights?.[index] &&
            system.featuredContent.content.highlights[index].contentId && (
              <p className={'InputLink'}>
                <Link
                  to={`/content-item/${system.featuredContent.content.highlights[index].contentId}`}
                >
                  View Content
                </Link>
              </p>
            )}
        </Form.Field>
      )}

      {isCategory && (
        <Form.Field>
          <label>Playlist</label>
          <Dropdown
            placeholder={'Type to search Playlists'}
            noResultsMessage={'Type to search Playlists'}
            fluid
            clearable
            search
            loading={loadingPlaylist}
            onSearchChange={async (e, data) => {
              if (data.searchQuery !== searchQueryPlaylist) {
                setDebounceQueryPlaylist(data.searchQuery);
              }
            }}
            onChange={(e, data) => {
              onChange(
                [
                  `featuredContent.content.highlights.${index}.contentId`,
                  `featuredContent.content.highlights.${index}.categoryId`,
                ],
                [
                  {
                    value: null,
                  },
                  data,
                ],
              );
            }}
            selection
            options={categoryResults}
            value={
              system.featuredContent?.content?.highlights?.[index].categoryId
            }
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />
        </Form.Field>
      )}
    </>
  );
};

export default Featured;
