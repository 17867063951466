import {CommonModalProps} from '../../components/common/CommonModal';
import {
  HIDE_COMMON_MODAL,
  SHOW_COMMON_MODAL,
} from '../actions/common-modal/CommonModalActionTypes';

export interface CommonModalState {
  data: CommonModalProps;
}

const INITIAL_STATE: CommonModalState = {
  data: {
    open: false,
  },
};

const commonModalReducer = (state = INITIAL_STATE, action) => {
  const payload: CommonModalState = action.payload;
  switch (action.type) {
    case SHOW_COMMON_MODAL:
      return {...state, data: payload.data};

    case HIDE_COMMON_MODAL:
      return {...state, data: payload.data};

    default:
      return state;
  }
};

export default commonModalReducer;
