import {
  FETCH_SELECTED_CHANNEL_ERROR,
  FETCH_SELECTED_CHANNEL_PENDING,
  FETCH_SELECTED_CHANNEL_SUCCESS,
} from './SelectedChannelActionTypes';

export const fetchSelectedChannelPending = () => {
  return {
    type: FETCH_SELECTED_CHANNEL_PENDING,
  };
};

export const fetchSelectedChannelSuccess = (channel) => {
  return {
    type: FETCH_SELECTED_CHANNEL_SUCCESS,
    payload: channel,
  };
};

export const fetchSelectedChannelError = (error) => {
  return {
    type: FETCH_SELECTED_CHANNEL_ERROR,
    payload: error,
  };
};
