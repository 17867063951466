import {startCase} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dropdown,
  Popup,
  Table,
  Form,
} from 'semantic-ui-react';
import ScheduleTable from '../components/common/ScheduleTable';
import TablePagination from '../components/common/TablePagination';
import config from '../config/config';
import {contentCategories} from '../const/enums/Content';
import {PublishStatus, publishStatuses} from '../const/enums/PublishMeta';
import {SchemaName} from '../const/enums/Schema';
import BuildInitialContent from '../const/initial/Content';
import {fetchSelectedContentSuccess} from '../redux/actions/selected-content/SelectedContentAction';
import {handlePagination} from '../util/PaginationHelper';
import {handleSearch} from '../util/SearchHelper';

const ContentView = () => {
  const pageSize = 10;

  const dispatch = useDispatch();
  const history = useHistory();
  const [content, setContent] = useState([]);
  const loggedInUser = useSelector((state) => state.loggedInUser).data;
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState([] as any);
  const [filterSearchResults, setFilterSearchResults] = useState([] as any);
  const [channelFilterValue, setChannelFilterValue] = useState([] as any);
  const [channelFilterResults, setChannelFilterResults] = useState([] as any);
  const [publishStatusFilterValue, setPublishStatusFilterValue] = useState(
    [] as any,
  );
  const [categoryFilterValue, setCategoryFilterValue] = useState([] as any);
  const [dateFilterValue, setDateFilterValue] = useState();
  const [dateFilterToValue, setDateFilterToValue] = useState();

  const [scheduleFilter, setScheduleFilter] = useState(false);

  const handlePaginationChange = useCallback(
    async (activePage: number) => {
      const additionalFilters: any = {};

      if (channelFilterValue.length) {
        additionalFilters.channelId = {$in: channelFilterValue};
      }

      if (publishStatusFilterValue.length) {
        additionalFilters['publishMeta.status'] = {
          $in: publishStatusFilterValue,
        };
      }

      if (categoryFilterValue.length) {
        additionalFilters['contentCategory'] = {$in: categoryFilterValue};
      }

      if (dateFilterValue || dateFilterToValue) {
        const dateObj = {};

        if (dateFilterValue) {
          dateObj['$gte'] = new Date(dateFilterValue as unknown as string);
        }

        if (dateFilterToValue) {
          dateObj['$lte'] = new Date(dateFilterToValue as unknown as string);
        }

        additionalFilters['publishMeta.publishDate'] = dateObj;
      }

      if (loggedInUser?.accessType !== 'admin') {
        additionalFilters.channelId = {$in: loggedInUser.accessChannels};
      }

      await handlePagination(
        loggedInUser && loggedInUser.token,
        SchemaName.CONTENT,
        activePage,
        pageSize,
        filterSearchValue,
        setContent,
        setPaginationPage,
        setPaginationTotal,
        additionalFilters,
      );
    },
    [
      loggedInUser,
      filterSearchValue,
      channelFilterValue,
      publishStatusFilterValue,
      categoryFilterValue,
      dateFilterValue,
      dateFilterToValue,
    ],
  );

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        // Get content
        await handlePaginationChange(1);
      })();
    }
  }, [loggedInUser, handlePaginationChange]);

  const contentHeaders = [
    '',
    'Name',
    'Channel',
    'Category',
    'Status',
    'Publish Date',
    'Last Updated',
  ].map((header) => {
    return (
      <Table.Cell className={'TableHeader'} key={`content-header-${header}`}>
        {header}
      </Table.Cell>
    );
  });

  const contentItems =
    content &&
    content.map((contentItem: any, index: number) => {
      let contentThumbnail = contentItem.featureContent.thumbnailId
        ? `url(${config.api}/file/${contentItem.featureContent.thumbnailId})`
        : `url(${
            contentItem.primaryContent[contentItem.contentType]?.mediaUrl
          })`;

      return (
        <Table.Row
          className={'TableRowClickable'}
          key={`channel-row-${index}`}
          onClick={() => history.push(`/content-item/${contentItem._id}`)}
        >
          <Table.Cell>
            <div
              className={'TableImage'}
              style={{
                backgroundImage: contentThumbnail,
              }}
            >
              {contentItem.moderation && (
                <Popup
                  position={'left center'}
                  size={'small'}
                  content={'This content requires moderation'}
                  trigger={<div className={'TableImageModerationFlag'} />}
                />
              )}
            </div>
          </Table.Cell>
          <Table.Cell>{contentItem.contentName}</Table.Cell>
          <Table.Cell>
            {(contentItem.channel &&
              contentItem.channel[0] &&
              contentItem.channel[0].channelName) ||
              contentItem.channelId}
          </Table.Cell>
          <Table.Cell>
            {contentItem.contentCategory
              ?.map((cat) => startCase(cat))
              ?.join(', ')}
          </Table.Cell>
          <Table.Cell>{startCase(contentItem.publishMeta.status)}</Table.Cell>
          <Table.Cell>
            {moment(contentItem.publishMeta.publishDate).format(
              'DD/MM/YYYY HH:mm',
            )}
          </Table.Cell>
          <Table.Cell>
            {moment(contentItem.meta.lastUpdated).format('DD/MM/YYYY HH:mm')}
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <div className={'Page'}>
      <div className={'PageContent'}>
        <div className={'PageContentHeader'}>
          <h1>Content</h1>
          {loggedInUser?.accessType === 'admin' && (
            <Button
              primary
              onClick={(event) => {
                event.preventDefault();

                // Clear existing selectedContent data
                dispatch(
                  fetchSelectedContentSuccess(
                    BuildInitialContent(loggedInUser._id, ''),
                  ),
                );

                // Redirect
                history.push(`/content-item`);
              }}
            >
              Create Content
            </Button>
          )}
        </div>

        <div className={'FilterFullWidth'}>
          <Form>
            <div className={'FilterRow'}>
              <div>
                <label>
                  <b>Content Name</b>
                </label>
                <Dropdown
                  placeholder={'Type to search Content'}
                  noResultsMessage={'Type to search Content'}
                  fluid
                  multiple
                  search
                  onSearchChange={async (e, data) => {
                    await handleSearch(
                      loggedInUser.token,
                      SchemaName.CONTENT,
                      data.searchQuery,
                      filterSearchResults,
                      setFilterSearchResults,
                      loggedInUser?.accessType !== 'admin'
                        ? {channelId: {$in: loggedInUser.accessChannels}}
                        : {},
                    );
                  }}
                  onChange={async (e, data) => {
                    if (data.value) {
                      setPaginationPage(1);
                      setFilterSearchValue(data.value);
                    }
                  }}
                  selection
                  options={filterSearchResults}
                  renderLabel={(item) => {
                    return {
                      content: <>{item.text}</>,
                    };
                  }}
                />
              </div>

              <div>
                <label>
                  <b>Content Category</b>
                </label>
                <Dropdown
                  placeholder={'Type to search categories'}
                  noResultsMessage={'Type to search categories'}
                  fluid
                  multiple
                  search
                  onChange={async (e, data) => {
                    if (data.value) {
                      setPaginationPage(1);
                      setCategoryFilterValue(data.value);
                    }
                  }}
                  selection
                  options={contentCategories().map((status, index) => {
                    return {
                      key: `content-category-${status}-${index}`,
                      value: status,
                      text: startCase(status),
                    };
                  })}
                  renderLabel={(item) => {
                    return {
                      content: <>{item.text}</>,
                    };
                  }}
                />
              </div>
            </div>

            {loggedInUser?.accessType === 'admin' && (
              <>
                <div className={'FilterRow'}>
                  <div>
                    <label>
                      <b>Channel Name</b>
                    </label>
                    <Dropdown
                      placeholder={'Type to search Channels'}
                      noResultsMessage={'Type to search Channels'}
                      fluid
                      search
                      clearable
                      multiple
                      onSearchChange={async (e, data) => {
                        await handleSearch(
                          loggedInUser.token,
                          SchemaName.CHANNEL,
                          data.searchQuery,
                          channelFilterResults,
                          setChannelFilterResults,
                        );
                      }}
                      onChange={async (e, data) => {
                        if (data.value) {
                          setPaginationPage(1);
                          setChannelFilterValue(data.value);
                        }
                      }}
                      selection
                      options={channelFilterResults}
                      renderLabel={(item) => {
                        return {
                          content: <>{item.text}</>,
                        };
                      }}
                    />
                  </div>

                  <div>
                    <label>
                      <b>Publish Status</b>
                    </label>
                    <Dropdown
                      placeholder={'Type to search statuses'}
                      noResultsMessage={'Type to search statuses'}
                      fluid
                      multiple
                      search
                      onChange={async (e, data) => {
                        if (data.value) {
                          setPaginationPage(1);
                          setPublishStatusFilterValue(data.value);
                        }
                      }}
                      selection
                      options={publishStatuses().map((status, index) => {
                        return {
                          key: `content-status-${status}-${index}`,
                          value: status,
                          text: startCase(status),
                        };
                      })}
                      renderLabel={(item) => {
                        return {
                          content: <>{item.text}</>,
                        };
                      }}
                    />
                  </div>
                </div>

                <div className={'FilterRow'}>
                  <div>
                    <label>
                      <b>Publish Date - From</b>
                    </label>
                    <Form.Field>
                      <DatePicker
                        selected={dateFilterValue}
                        onChange={(date) => setDateFilterValue(date)}
                        isClearable={true}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </Form.Field>
                  </div>
                  <div>
                    <label>
                      <b>Publish Date - To</b>
                    </label>

                    <Form.Field>
                      <DatePicker
                        selected={dateFilterToValue}
                        onChange={(date) => setDateFilterToValue(date)}
                        isClearable={true}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                            escapeWithReference: false,
                            boundariesElement: 'viewport',
                          },
                        }}
                      />
                    </Form.Field>
                  </div>
                </div>

                <div className={'FilterRowFullWidthCenter'}>
                  <Checkbox
                    className={'FilterModeration'}
                    slider
                    label={`View Schedule`}
                    checked={scheduleFilter}
                    onChange={async (e, data) => {
                      setScheduleFilter(!!data.checked);
                    }}
                  />
                </div>
              </>
            )}
          </Form>
        </div>

        {!scheduleFilter && (
          <Table className={'Table'} textAlign={'center'} selectable striped>
            <Table.Header>
              <Table.Row>{contentHeaders}</Table.Row>
            </Table.Header>

            <Table.Body>
              {contentItems && contentItems.length ? (
                contentItems
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={7}>
                    <p>No Content found</p>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>

            <TablePagination
              totalCols={7}
              pageSize={pageSize}
              paginationPage={paginationPage}
              paginationTotal={paginationTotal}
              onChange={handlePaginationChange}
            />
          </Table>
        )}

        {scheduleFilter && (
          <ScheduleTable
            loggedInUser={loggedInUser}
            model={SchemaName.CONTENT}
            moderationFilter={publishStatusFilterValue.includes(
              PublishStatus.AWAITING_MODERATION,
            )}
            channelFilter={channelFilterValue}
          />
        )}
      </div>
    </div>
  );
};

export default ContentView;
