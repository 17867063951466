import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunk from 'redux-thunk';
import commonModalReducer from './reducers/CommonModalReducer';
import loggedInUserReducer from './reducers/LoggedInUserReducer';
import selectedChannelReducer from './reducers/SelectedChannelReducer';
import selectedContentReducer from './reducers/SelectedContentReducer';
import selectedUserReducer from './reducers/SelectedUserReducer';
import systemReducer from './reducers/SystemReducer';

const middleware = [thunk];
const store = createStore(
  combineReducers({
    commonModal: commonModalReducer,
    loggedInUser: loggedInUserReducer,
    selectedChannel: selectedChannelReducer,
    selectedContent: selectedContentReducer,
    selectedUser: selectedUserReducer,
    system: systemReducer,
  }),
  applyMiddleware(...middleware),
);

export default store;
