import {
  SHOW_COMMON_MODAL,
  HIDE_COMMON_MODAL
} from './CommonModalActionTypes';


export const showCommonModal = (modal) => {
  return {
    type: SHOW_COMMON_MODAL,
    payload: {
      data: modal,
    },
  };
};

export const hideCommonModal = () => {
  return {
    type: HIDE_COMMON_MODAL,
    payload: {
      open: false,
    },
  };
};
