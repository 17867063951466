import {useAuth0} from '@auth0/auth0-react';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Divider, Dropdown, Icon, Input} from 'semantic-ui-react';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import {User} from '../const/interfaces/User';
import {
  fetchLoggedInUserPending,
  fetchLoggedInUserSuccess,
} from '../redux/actions/logged-in-user/LoggedInUserAction';
import AxiosHelper from '../util/AxiosHelper';
import {
  handleWideSearch,
  initialWideSearch,
  SearchOption,
} from '../util/SearchHelper';

const HeaderBar = ({mobileNavOpen, setMobileNavOpen}) => {
  const history = useHistory();
  const {getAccessTokenSilently, logout, user} = useAuth0();
  const dispatch = useDispatch();
  const loggedInUser: User =
    useSelector((state) => state.loggedInUser).data || {};

  const [wideSearchValue, setWideSearchValue] = useState('');
  const [wideSearchResults, setWideSearchResults] = useState(initialWideSearch);

  const clearSearch = useCallback(
    (event) => {
      if (event.key === 'Escape' && wideSearchValue && wideSearchValue.length) {
        setWideSearchValue('');
      }
    },
    [wideSearchValue],
  );

  useEffect(() => {
    document.addEventListener('keydown', clearSearch);
  }, [clearSearch]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();

        const axiosHelper = new AxiosHelper(token);

        // Get loggedInUser
        dispatch(fetchLoggedInUserPending());
        const result = await axiosHelper.get(`${config.api}/user/admin-auth/`, {
          auth0Id: user.sub,
        });
        result.data.token = token;

        dispatch(fetchLoggedInUserSuccess(result.data));
      } catch (e) {
        logout({
          returnTo: config.logoutUrl,
        });
      }
    })();
  }, [getAccessTokenSilently, dispatch, user.sub, logout]);

  const handleWideSearchClick = (model, item) => {
    const endpoint =
      model === SchemaName.CONTENT ? 'content-item' : 'channel-item';
    history.push(`/${endpoint}/${item._id}`);

    setWideSearchValue('');
  };

  const buildWideSearchItem = (
    model: SchemaName,
    item: SearchOption,
    index: number,
  ) => (
    <div
      className={'WideSearchResultItem'}
      key={`wide-search-item-${item.text}-${index}`}
      onClick={() => handleWideSearchClick(model, item)}
    >
      <div
        className={'WideSearchResultItemImage'}
        style={{backgroundImage: `url(${item.image})`}}
      />
      {item.text}
    </div>
  );

  return (
    <div className={'HeaderBar'}>
      <div className={'NavigationWrapper'}>
        <div className={'BackButton'} onClick={() => history.goBack()}>
          <Icon name="chevron left" />
        </div>

        <div
          className={'MobileNavButton'}
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        >
          <Icon name="bars" />
        </div>

        {loggedInUser?.accessType === 'admin' && <div className={`WideSearchWrapper ${wideSearchValue ? 'active' : ''}`}>
          <Input
            placeholder={'Type to search'}
            className={wideSearchValue ? 'active' : ''}
            icon={
              wideSearchValue ? (
                <Icon
                  name="delete"
                  link
                  onClick={() => setWideSearchValue('')}
                />
              ) : (
                'search'
              )
            }
            fluid
            onChange={async (e, data) => {
              setWideSearchValue(data.value as string);
              await handleWideSearch(
                loggedInUser.token,
                data.value as string,
                wideSearchResults,
                setWideSearchResults,
              );
            }}
            value={wideSearchValue}
          />
          {wideSearchValue && (
            <div className={'WideSearchResultsWrapper'}>
              <div className={'WideSearchResultsType'}>
                <h3>Content</h3>
                {!wideSearchResults.content.results.length && (
                  <p>No content found</p>
                )}
                <div className={'WideSearchResultsItems'}>
                  {wideSearchResults.content.results.map((item, index) =>
                    buildWideSearchItem(SchemaName.CONTENT, item, index),
                  )}
                </div>
              </div>

              <Divider section />

              <div className={'WideSearchResultsType'}>
                <h3>Channels</h3>
                {!wideSearchResults.channels.results.length && (
                  <p>No channels found</p>
                )}
                <div className={'WideSearchResultsItems'}>
                  {wideSearchResults.channels.results.map((item, index) =>
                    buildWideSearchItem(SchemaName.CHANNEL, item, index),
                  )}
                </div>
              </div>
            </div>
          )}
        </div>}
      </div>

      <div className={'LoggedInUser'}>
        {loggedInUser ? (
          <Dropdown
            direction={'left'}
            icon={null}
            floating
            options={[
              {
                key: 'profile',
                text: 'View Profile',
                value: 'profile',
                onClick: () => history.push(`/user-item/${loggedInUser._id}`),
              },
              {
                key: 'logout',
                text: 'Logout',
                value: 'logout',
                onClick: () =>
                  logout({
                    returnTo: config.logoutUrl,
                  }),
              },
            ]}
            trigger={
              <div
                className={'LoggedInUserAvatar'}
                style={
                  loggedInUser &&
                  loggedInUser.avatar && {
                    backgroundImage: `url(${config.api}/file/${loggedInUser.avatar})`,
                  }
                }
              />
            }
          />
        ) : (
          <div className={'LoggedInUserAvatar'} />
        )}
      </div>
    </div>
  );
};

export default HeaderBar;
