import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Divider, Dropdown, Form} from 'semantic-ui-react';
import SectionHeader from '../components/common/SectionHeader';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import {User} from '../const/interfaces/User';
import AxiosHelper from '../util/AxiosHelper';
import {handleSearch} from '../util/SearchHelper';

const NotificationsView = () => {
  const {addToast} = useToasts();

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;

  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<string>();

  const [selectedChannel, setSelectedChannel] = useState();
  const [channelSearchResults, setChannelSearchResults] = useState([]);

  const [selectedContent, setSelectedContent] = useState();
  const [contentSearchResults, setContentSearchResults] = useState([]);

  const handleChange = (field: string, data: any) => {
    if (field && data) {
      if (field === 'title') {
        setTitle(data);
      }

      if (field === 'message') {
        setMessage(data);
      }

      if (field === 'channelId') {
        setSelectedChannel(data.value);
      }

      if (field === 'contentId') {
        setSelectedContent(data.value);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Get channel/content if required
      let channel;
      let content;

      if (selectedChannel) {
        channel = await axiosHelper.get(
          `${config.api}/channel/${selectedChannel}`,
        );
      }

      if (selectedContent) {
        content = await axiosHelper.get(
          `${config.api}/content/${selectedContent}`,
        );
      }

      // Send notification
      await axiosHelper.post(`${config.api}/notifications`, {
        title,
        message,
        channel: channel?.data?.urlSlug,
        content: content?.data?.urlSlug,
      });

      // Add toast
      addToast(`Notification sent successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      // Add toast
      addToast(
        `Notification could not be sent to all users. Try again later.`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <h1>Notifications</h1>

        <h2 className={'alt-colour-type'}>Create a notification</h2>

        <Form.Field required>
          <label>Title</label>
          <Form.Input
            placeholder={'Enter a notification title'}
            value={title}
            onChange={(e, data) => handleChange('title', data.value)}
          />
        </Form.Field>

        <Form.Field required>
          <label>Message</label>
          <Form.TextArea
            placeholder={'Enter a notification message'}
            value={message}
            onChange={(e, data) => handleChange('message', data.value)}
          />
        </Form.Field>

        <Divider />

        <SectionHeader title={'Notification Link'} />
        <Form.Field>
          <label>Channel</label>
          <Dropdown
            placeholder={'Type to search Channels'}
            noResultsMessage={'Type to search Channels'}
            fluid
            search
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                SchemaName.CHANNEL,
                data.searchQuery,
                channelSearchResults,
                setChannelSearchResults,
              );
            }}
            onChange={(e, data) => handleChange('channelId', data)}
            selection
            options={channelSearchResults}
            value={selectedChannel}
          />
        </Form.Field>
        <Form.Field>
          <label>Content</label>
          <Dropdown
            disabled={!selectedChannel}
            placeholder={'Type to search Content'}
            noResultsMessage={'Type to search Content'}
            fluid
            search
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                SchemaName.CONTENT,
                data.searchQuery,
                contentSearchResults,
                setContentSearchResults,
                {channelId: selectedChannel},
              );
            }}
            onChange={(e, data) => handleChange('contentId', data)}
            selection
            options={contentSearchResults}
            value={selectedContent}
          />
        </Form.Field>
      </div>

      <aside className={'PageSidebar'}>
        <h2>Send Notification</h2>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={!title?.length || !message?.length}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            Send Notification
          </Button>
        </div>
      </aside>
    </Form>
  );
};

export default NotificationsView;
