import {faHeart} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cloneDeep, get, set, startCase, uniq} from 'lodash';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Select,
} from 'semantic-ui-react';
import ContentEditor from '../components/common/ContentEditor';
import SectionHeader from '../components/common/SectionHeader';
import ContentMediaCover from '../components/content/ContentMediaCover';
import Facebook from '../components/content/Facebook';
import Mixed from '../components/content/Mixed';
import SoundCloud from '../components/content/SoundCloud';
import Spotify from '../components/content/Spotify';
import Vimeo from '../components/content/Vimeo';
import YouTube from '../components/content/YouTube';
import config from '../config/config';
import {CommonModalActionTypes} from '../const/enums/CommonModal';
import {
  contentCategories,
  ContentType,
  contentTypes,
  FeatureType,
  featureTypes,
} from '../const/enums/Content';
import {
  highlightPositions,
  HighlightType,
  highlightTypes,
} from '../const/enums/Highlight';
import {Language} from '../const/enums/Language';
import {PublishStatus, publishStatuses} from '../const/enums/PublishMeta';
import {SchemaName} from '../const/enums/Schema';
import {systemStatuses} from '../const/enums/System';
import BuildInitialContent from '../const/initial/Content';
import BuildInitialSchedule from '../const/initial/Schedule';
import {TOTAL_HIGHLIGHTS} from '../const/initial/System';
import {Content} from '../const/interfaces/Content';
import {User} from '../const/interfaces/User';
import {
  hideCommonModal,
  showCommonModal,
} from '../redux/actions/common-modal/CommonModalAction';
import {
  fetchSelectedContentError,
  fetchSelectedContentPending,
  fetchSelectedContentSuccess,
} from '../redux/actions/selected-content/SelectedContentAction';
import AxiosHelper from '../util/AxiosHelper';
import countryOptions from '../util/countries';
import {getModerationField} from '../util/ModerationHelper';
import {buildSearchOption, handleSearch} from '../util/SearchHelper';
import {buildTagOptions} from '../util/TagHelper';
import {validateContent, validateSchedule} from '../util/ValidationHelper';

const ContentItemView = () => {
  const {addToast} = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const contentId = params.id;
  const queryChannelId = query.get('channelId');

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;

  const content: Content =
    useSelector((state) => state.selectedContent).data ||
    BuildInitialContent(
      (loggedInUser && loggedInUser._id) || '',
      queryChannelId || '',
    );

  const [langStrings, setLangStrings] = useState({
    description: Language.EN,
    bodyContent: Language.EN,
    comingSoonDescription: Language.EN,
  });

  const [selectedChannel, setSelectedChannel] = useState(null as any);
  const [channelSearchResults, setChannelSearchResults] = useState([] as any);
  const [userSearchResults, setUserSearchResults] = useState([] as any);
  const [contentEditorReady, setContentEditorReady] = useState(false);
  const [fetchedHighlights, setFetchedHighlights] = useState(true);
  const [currentHighlights, setCurrentHighlights] = useState({
    current: {} as any,
    scheduled: [],
  } as any);
  const [scheduleHighlight, setScheduleHighlight] = useState(
    BuildInitialSchedule(contentId),
  );

  if (!contentId && !contentEditorReady) {
    setContentEditorReady(true);
  }

  useEffect(() => {
    (async () => {
      if (loggedInUser && loggedInUser.token) {
        const axiosHelper = new AxiosHelper(loggedInUser.token);
        if (contentId) {
          let content;
          try {
            dispatch(fetchSelectedContentPending());

            // Get content
            content = (
              await axiosHelper.get(`${config.api}/content/${contentId}`)
            ).data;

            dispatch(fetchSelectedContentSuccess(content));

            // Set channel dropdown
            if (content?.channelId) {
              const channel = await axiosHelper.get(
                `${config.api}/channel/${content.channelId}`,
              );

              if (channel) {
                setSelectedChannel(channel.data);
                setChannelSearchResults([
                  {
                    key: `channel-${channel.data.channelName}-0`,
                    value: channel.data._id,
                    text: channel.data.channelName,
                    image: `${config.api}/file/${channel.data.theme.logo}`,
                  },
                ]);
              }
            }

            // Set user dropdown
            if (
              content &&
              content.watchlist &&
              content.watchlist.active &&
              (content.watchlist.users.length ||
                content.watchlist.pending.length)
            ) {
              const users = await axiosHelper.get(
                `${config.api}/user/minimal`,
                {
                  _id: {
                    $in: content.watchlist.users.concat(
                      content.watchlist.pending,
                    ),
                  },
                },
              );

              if (users) {
                const userOptions = users.data.results.map((result, index) => {
                  return buildSearchOption(SchemaName.USER, result, index);
                });

                setUserSearchResults(userOptions);
              }
            }

            // Get current highlights
            setFetchedHighlights(false);

            setContentEditorReady(true);
          } catch (e) {
            dispatch(fetchSelectedContentError(e));
          }
        } else {
          fetchSelectedContentSuccess(
            BuildInitialContent(
              (loggedInUser && loggedInUser._id) || '',
              queryChannelId || '',
            ),
          );

          // Set channel dropdown
          if (queryChannelId) {
            const channel = await axiosHelper.get(
              `${config.api}/channel/${queryChannelId}`,
            );

            if (channel) {
              setChannelSearchResults([
                {
                  key: `channel-${channel.data.channelName}-0`,
                  value: channel.data._id,
                  text: channel.data.channelName,
                  image: `${config.api}/file/${channel.data.theme.logo}`,
                },
              ]);
            }
          }
        }
      }
    })();
  }, [loggedInUser, dispatch, contentId, queryChannelId]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.token && contentId && !fetchedHighlights) {
      setFetchedHighlights(true);
      (async () => {
        const axiosHelper = new AxiosHelper(loggedInUser.token);

        // Get current highlights
        const highlights = (
          await axiosHelper.get(`${config.api}/content/highlight/${contentId}`)
        ).data;

        if (highlights) {
          // Filter scheduled for only future highlights
          if (highlights.scheduled) {
            const now = moment();
            highlights.scheduled = highlights.scheduled.filter((i) =>
              now.isBefore(moment(i.publishMeta.publishDate)),
            );
          }

          setCurrentHighlights(highlights);
        }
      })();
    }
  }, [loggedInUser, contentId, fetchedHighlights]);

  const handleLangChange = (field: string, lang: Language) => {
    if (field && lang) {
      const cloned = cloneDeep(langStrings);
      set(cloned, field, lang);
      setLangStrings(cloned);
    }
  };

  const handleFeaturedSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Set featured on channel
      const updatedChannel = (
        await axiosHelper.post(`${config.api}/channel/featured`, {
          channelId: selectedChannel._id,
          contentId,
        })
      ).data;

      // Add toast
      addToast(`Content featured successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      // Update selected channel
      setSelectedChannel(updatedChannel);
    } catch (e) {
      // Add toast
      addToast(`Featuring content failed. Try again later.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleHighlightChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(scheduleHighlight);

        set(cloned, field, data.value);
        setScheduleHighlight(cloned);
      }
    },
    [scheduleHighlight],
  );

  const handleHighlightSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Set highlight
      const newHighlight = (
        await axiosHelper.post(
          `${config.api}/content/highlight`,
          scheduleHighlight,
        )
      ).data;
      setScheduleHighlight(newHighlight);

      // Add toast
      addToast(`Highlight created successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      // Update current highlights
      setFetchedHighlights(false);
    } catch (e) {
      // Add toast
      addToast(`Highlight creation failed. Try again later.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleChange = useCallback(
    async (field: string | string[], data: any) => {
      if (field && data) {
        const cloned = cloneDeep(content);

        if (!Array.isArray(field)) {
          field = [field];
        }

        if (!Array.isArray(data)) {
          data = [data];
        }

        for (let i = 0; i < field.length; i++) {
          if (get(content, `moderation.${field[i]}`)) {
            field[i] = `moderation.${field[i]}`;
          }

          // If changing channel fetch channel
          if (field[i] === 'channelId') {
            const axiosHelper = new AxiosHelper(loggedInUser.token);
            const channel = await axiosHelper.get(
              `${config.api}/channel/${data[i].value}`,
            );

            if (channel) {
              setSelectedChannel(channel.data);
            }
          }

          // If changing content type update media image
          if (
            field[i] === 'contentType' &&
            data[i].value === ContentType.MIXED
          ) {
            set(cloned, 'featureContent.featureType', FeatureType.MEDIA);
          } else if (
            field[i] === 'moderation.contentType' &&
            data[i].value === ContentType.MIXED
          ) {
            set(
              cloned,
              'moderation.featureContent.featureType',
              FeatureType.MEDIA,
            );
          }

          set(cloned, field[i], data[i].value);
        }

        dispatch(fetchSelectedContentSuccess(cloned));
      }
    },
    [dispatch, content, loggedInUser],
  );

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update lastUpdated
      content.meta.lastUpdated = new Date();
      content.meta.lastUpdatedBy = loggedInUser._id;

      // Update/Create content
      const newContent = (
        contentId
          ? await axiosHelper.put(`${config.api}/content`, content)
          : await axiosHelper.post(`${config.api}/content`, content)
      ).data;
      dispatch(fetchSelectedContentSuccess(newContent));

      // Add toast
      addToast(`Content ${contentId ? 'updated' : 'created'} successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      // If creation - redirect to edit page
      if (!contentId) {
        history.push(`/content-item/${newContent._id}`);
      }
    } catch (e) {
      dispatch(fetchSelectedContentError(e));

      // Add toast
      addToast(
        `Content ${contentId ? 'update' : 'creation'} failed. Try again later.`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  };

  const handleDelete = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Delete content
      await axiosHelper.delete(`${config.api}/content/${content._id}`);
      dispatch(fetchSelectedContentSuccess(null));

      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('Content deleted successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });

      // Redirect
      history.push(`/content`);
    } catch (e) {
      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('Content deletion failed. Try again later.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const contentEditorHandleChange = useCallback(
    (
      data,
      lang,
      type: 'bodyContent' | 'description' | 'comingSoonContent.description',
    ) => handleChange(`${type}.${lang}`, data),
    [handleChange],
  );

  const isCurrentlyHighlighted = (): boolean => {
    const cur = currentHighlights && currentHighlights.current;

    if (cur) {
      if (cur.hero) {
        return true;
      }

      if (cur.highlights) {
        for (let key of Object.keys(cur.highlights)) {
          if (cur[key]) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <div className={'PageTitle'}>
          <h1>
            {contentId ? `Editing ${content.contentName}` : `Creating Content`}
          </h1>

          <div className={'MetaCount'}>
            <FontAwesomeIcon icon={faHeart} />
            <p className={'bold-type'}>{content.likeCount}</p>
          </div>
        </div>

        {loggedInUser?.accessType === 'admin' && (
          <>
            <SectionHeader title={'Channel Details'} />
            <Form.Field required>
              <label>Channel</label>
              <Dropdown
                placeholder={'Type to search Channels'}
                noResultsMessage={'Type to search Channels'}
                error={!get(content, 'channelId')}
                fluid
                search
                onSearchChange={async (e, data) => {
                  await handleSearch(
                    loggedInUser.token,
                    SchemaName.CHANNEL,
                    data.searchQuery,
                    channelSearchResults,
                    setChannelSearchResults,
                  );
                }}
                onChange={(e, data) => handleChange('channelId', data)}
                selection
                options={channelSearchResults}
                value={content.channelId}
              />

              {content.channelId && (
                <p className={'InputLink'}>
                  <Link to={`/channel-item/${content.channelId}`}>
                    View Channel
                  </Link>
                </p>
              )}
            </Form.Field>
          </>
        )}

        <SectionHeader
          data={content}
          fields={['contentName', 'description']}
          title={'Content Details'}
        />

        <Form.Field required>
          <label>Content Name</label>
          <Form.Input
            placeholder={'Enter a content name'}
            value={
              get(content, getModerationField(content, 'contentName')) || ''
            }
            onChange={(e, data) => handleChange('contentName', data)}
            error={!get(content, getModerationField(content, 'contentName'))}
          />
        </Form.Field>

        <Form.Field>
          <label className={'LangStringSelector'}>
            <span
              className={
                langStrings.description !== Language.EN ? 'normal-type' : ''
              }
              onClick={() => handleLangChange('description', Language.EN)}
            >
              Description
            </span>
            <span className={'normal-type'}> | </span>
            <span
              className={
                langStrings.description !== Language.CY ? 'normal-type' : ''
              }
              onClick={() => handleLangChange('description', Language.CY)}
            >
              Disgrifiad
            </span>
          </label>
          {langStrings.description === Language.EN && contentEditorReady && (
            <ContentEditor
              content={get(
                content,
                getModerationField(content, 'description.' + Language.EN),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.EN, 'description')
              }
            />
          )}
          {langStrings.description === Language.CY && contentEditorReady && (
            <ContentEditor
              content={get(
                content,
                getModerationField(content, 'description.' + Language.CY),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.CY, 'description')
              }
            />
          )}
        </Form.Field>

        <Form.Field required>
          <label>Content Category</label>
          <Select
            search
            placeholder={'Select a content category'}
            multiple={true}
            options={contentCategories().map((cat, index) => {
              return {
                key: `content-cat-${cat}-${index}`,
                value: cat,
                text: startCase(cat),
              };
            })}
            value={
              get(content, getModerationField(content, `contentCategory`)) ||
              contentCategories()[0]
            }
            onChange={(e, data) => handleChange(`contentCategory`, data)}
          />
        </Form.Field>

        <Form.Field>
          <label>Channel Highlights Playlist</label>
          <Select
            search
            placeholder={'Select a channel highlights playlist'}
            multiple={true}
            options={(selectedChannel?.categories || [])
              .filter((i) => i.isPlaylist)
              .map((i, index) =>
                buildSearchOption(SchemaName.CATEGORY, i, index),
              )}
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
            value={
              get(content, getModerationField(content, `channelCategories`)) ||
              contentCategories()[0]
            }
            onChange={(e, data) => handleChange(`channelCategories`, data)}
          />
        </Form.Field>

        <Divider section />

        <SectionHeader
          data={content}
          fields={['contentType']}
          title={'Content Type'}
        />

        <Form.Field required>
          <label>Content Type</label>
          <Select
            placeholder={'Select a content type'}
            options={contentTypes().map((type, index) => {
              return {
                key: `content-type-${type}-${index}`,
                value: type,
                text: startCase(type),
              };
            })}
            value={
              get(content, getModerationField(content, 'contentType')) ||
              contentTypes()[0]
            }
            onChange={(e, data) => handleChange(`contentType`, data)}
          />
        </Form.Field>

        <Divider section />

        <SectionHeader
          data={content}
          fields={[
            `primaryContent.${content.contentType}`,
            'featureContent.thumbnailId',
          ]}
          title={`${startCase(content.contentType)} Content`}
        />

        {/*Content type specific inputs*/}
        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.YOUTUBE && (
          <YouTube content={content} handleChange={handleChange} />
        )}

        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.VIMEO && (
          <Vimeo content={content} handleChange={handleChange} />
        )}

        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.SPOTIFY && (
          <Spotify content={content} handleChange={handleChange} />
        )}

        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.SOUNDCLOUD && (
          <SoundCloud content={content} handleChange={handleChange} />
        )}

        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.FACEBOOK && (
          <Facebook content={content} handleChange={handleChange} />
        )}

        {get(content, getModerationField(content, 'contentType')) ===
          ContentType.MIXED && (
          <Mixed content={content} handleChange={handleChange} />
        )}

        <Divider section />

        <SectionHeader
          data={content}
          fields={['featureContent.featureType', 'featureContent.coverId']}
          title={`Media Image`}
        />

        <Form.Field
          required={
            !(
              get(content, getModerationField(content, 'contentType')) ===
              ContentType.MIXED
            )
          }
        >
          <label>Media Type</label>
          <Select
            clearable
            placeholder={'Select content to use when featured'}
            options={featureTypes().map((type, index) => {
              return {
                disabled:
                  (get(content, getModerationField(content, 'contentType')) ===
                    ContentType.MIXED &&
                    type === FeatureType.EMBED) ||
                  false,
                key: `feature-type-${type}-${index}`,
                value: type,
                text:
                  type === FeatureType.EMBED
                    ? 'Embedded content'
                    : 'Cover image',
              };
            })}
            value={get(
              content,
              getModerationField(content, `featureContent.featureType`),
            )}
            onChange={(e, data) =>
              handleChange(`featureContent.featureType`, data)
            }
          />
        </Form.Field>

        {get(
          content,
          getModerationField(content, `featureContent.featureType`),
        ) === FeatureType.MEDIA && (
          <ContentMediaCover
            content={content}
            handleChange={handleChange}
            required={
              !(
                get(content, getModerationField(content, 'contentType')) ===
                ContentType.MIXED
              )
            }
          />
        )}

        <Divider section />

        <SectionHeader
          data={content}
          fields={['bodyContent']}
          title={`Content Body`}
        />

        <Form.Field>
          <label className={'LangStringSelector'}>
            <span
              className={
                langStrings.bodyContent !== Language.EN ? 'normal-type' : ''
              }
              onClick={() => handleLangChange('bodyContent', Language.EN)}
            >
              Content
            </span>
            <span className={'normal-type'}> | </span>
            <span
              className={
                langStrings.bodyContent !== Language.CY ? 'normal-type' : ''
              }
              onClick={() => handleLangChange('bodyContent', Language.CY)}
            >
              Cynnwys
            </span>
          </label>
          {langStrings.bodyContent === Language.EN && contentEditorReady && (
            <ContentEditor
              content={get(
                content,
                getModerationField(content, 'bodyContent.' + Language.EN),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.EN, 'bodyContent')
              }
            />
          )}
          {langStrings.bodyContent === Language.CY && contentEditorReady && (
            <ContentEditor
              content={get(
                content,
                getModerationField(content, 'bodyContent.' + Language.CY),
              )}
              handleChange={(data) =>
                contentEditorHandleChange(data, Language.CY, 'bodyContent')
              }
            />
          )}
        </Form.Field>

        <Divider section />

        <SectionHeader
          data={content}
          fields={['tags']}
          title={`Content Tags`}
        />

        <Form.Field>
          <label>Public Tags</label>
          <Form.Dropdown
            placeholder={'Type to add tags'}
            noResultsMessage={'Type to add tags'}
            fluid
            multiple
            selection
            allowAdditions
            search
            value={
              get(content, getModerationField(content, 'tags.public')) || []
            }
            options={
              content && content.tags
                ? buildTagOptions(
                    get(content, getModerationField(content, 'tags.public')),
                    'public',
                  )
                : []
            }
            onChange={(e, data) => handleChange(`tags.public`, data)}
          />
        </Form.Field>

        <Form.Field>
          <label>Private Tags</label>
          <Form.Dropdown
            placeholder={'Type to add tags'}
            noResultsMessage={'Type to add tags'}
            fluid
            multiple
            selection
            allowAdditions
            search
            value={
              get(content, getModerationField(content, 'tags.private')) || []
            }
            options={
              content && content.tags
                ? buildTagOptions(
                    get(content, getModerationField(content, 'tags.private')),
                    'private',
                  )
                : []
            }
            onChange={(e, data) => handleChange(`tags.private`, data)}
          />
        </Form.Field>

        {get(content, 'geolocation.active') && (
          <>
            {' '}
            <Divider section />
            <SectionHeader
              data={content}
              fields={['tags']}
              title={`Locations`}
            />
            <Form.Field>
              <label>Allowed Locations</label>
              <Form.Dropdown
                placeholder={'Type to add allowed locations'}
                noResultsMessage={'Type to add allowed locations'}
                fluid
                multiple
                selection
                search
                value={
                  get(
                    content,
                    getModerationField(content, 'geolocation.allow'),
                  ) || []
                }
                options={countryOptions}
                onChange={(e, data) => handleChange(`geolocation.allow`, data)}
              />
            </Form.Field>
          </>
        )}

        {get(content, 'watchlist.active') && (
          <>
            <Divider section />

            <SectionHeader title={'Private Content'} />

            <Form.Field>
              <label>Auto Approve Watchlist Requests</label>
              <Checkbox
                label={`Auto Approve`}
                checked={get(
                  content,
                  getModerationField(content, `watchlist.autoApproval`),
                )}
                onChange={async (e, data) => {
                  handleChange(`watchlist.autoApproval`, {
                    value: !!data.checked,
                  });
                }}
              />
            </Form.Field>

            <Form.Field>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <label style={{fontWeight: 'bold'}}>
                  Pending User Watchlist (
                  {content?.watchlist?.pending?.length || 0})
                </label>
                <Button
                  disabled={!content?.watchlist?.pending?.length}
                  primary
                  onClick={async (event) => {
                    event.preventDefault();
                    const allUsers = (content.watchlist.users || []).concat(
                      content.watchlist.pending || [],
                    );
                    await handleChange(
                      [`watchlist.users`, `watchlist.pending`],
                      [{value: uniq(allUsers)}, {value: []}],
                    );
                  }}
                >
                  Approve All
                </Button>
              </div>
              <Dropdown
                clearable
                placeholder={'Type to search Users'}
                noResultsMessage={'Type to search Users'}
                fluid
                multiple
                search
                onSearchChange={async (e, data) => {
                  await handleSearch(
                    loggedInUser.token,
                    SchemaName.USER,
                    data.searchQuery,
                    userSearchResults,
                    setUserSearchResults,
                  );
                }}
                onChange={(e, data) => handleChange('watchlist.pending', data)}
                selection
                options={userSearchResults}
                value={content.watchlist.pending}
                renderLabel={(item) => {
                  return {
                    content: <>{item.text}</>,
                  };
                }}
              />
            </Form.Field>

            <Form.Field>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 10,
                }}
              >
                <label style={{fontWeight: 'bold'}}>
                  Approved User Watchlist (
                  {content?.watchlist?.users?.length || 0})
                </label>
                <Button
                  disabled={!content?.watchlist?.users?.length}
                  color={'red'}
                  onClick={async (event) => {
                    event.preventDefault();
                    const allUsers = (content.watchlist.pending || []).concat(
                      content.watchlist.users || [],
                    );
                    await handleChange(
                      [`watchlist.pending`, `watchlist.users`],
                      [{value: uniq(allUsers)}, {value: []}],
                    );
                  }}
                >
                  Remove All
                </Button>
              </div>

              <Dropdown
                clearable
                placeholder={'Type to search Users'}
                noResultsMessage={'Type to search Users'}
                fluid
                multiple
                search
                onSearchChange={async (e, data) => {
                  await handleSearch(
                    loggedInUser.token,
                    SchemaName.USER,
                    data.searchQuery,
                    userSearchResults,
                    setUserSearchResults,
                  );
                }}
                onChange={(e, data) => handleChange('watchlist.users', data)}
                selection
                options={userSearchResults}
                value={content.watchlist.users}
                renderLabel={(item) => {
                  return {
                    content: <>{item.text}</>,
                  };
                }}
              />
            </Form.Field>
          </>
        )}

        {get(content, 'isComingSoon') && (
          <>
            <Divider section />

            <SectionHeader
              data={content}
              fields={[
                `comingSoonContent.primaryContent.${content.contentType}`,
              ]}
              title={`Coming Soon`}
            />

            <Form.Field>
              <label>Launch Date</label>
              <DatePicker
                selected={
                  content &&
                  content.comingSoonContent?.launchDate &&
                  new Date(
                    get(
                      content,
                      getModerationField(
                        content,
                        'comingSoonContent.launchDate',
                      ),
                    ),
                  )
                }
                onChange={(date) =>
                  handleChange('comingSoonContent.launchDate', {value: date})
                }
                showTimeSelect
                timeIntervals={5}
                dateFormat={'MMM d, yyyy h:mm aa'}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>End Date</label>
              <DatePicker
                selected={
                  content &&
                  content.comingSoonContent?.endDate &&
                  new Date(
                    get(
                      content,
                      getModerationField(content, 'comingSoonContent.endDate'),
                    ),
                  )
                }
                onChange={(date) =>
                  handleChange('comingSoonContent.endDate', {value: date})
                }
                showTimeSelect
                timeIntervals={5}
                dateFormat={'MMM d, yyyy h:mm aa'}
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport',
                  },
                }}
              />
            </Form.Field>

            <Form.Field required>
              <label>Title</label>
              <Form.Input
                placeholder={'Enter a title'}
                value={
                  get(
                    content,
                    getModerationField(content, 'comingSoonContent.title'),
                  ) || ''
                }
                onChange={(e, data) =>
                  handleChange('comingSoonContent.title', data)
                }
                error={
                  !get(
                    content,
                    getModerationField(content, 'comingSoonContent.title'),
                  )
                }
              />
            </Form.Field>

            <Form.Field>
              <label className={'LangStringSelector'}>
                <span
                  className={
                    langStrings.comingSoonDescription !== Language.EN
                      ? 'normal-type'
                      : ''
                  }
                  onClick={() =>
                    handleLangChange('comingSoonDescription', Language.EN)
                  }
                >
                  Description
                </span>
                <span className={'normal-type'}> | </span>
                <span
                  className={
                    langStrings.comingSoonDescription !== Language.CY
                      ? 'normal-type'
                      : ''
                  }
                  onClick={() =>
                    handleLangChange('comingSoonDescription', Language.CY)
                  }
                >
                  Disgrifiad
                </span>
              </label>
              {langStrings.comingSoonDescription === Language.EN &&
                contentEditorReady && (
                  <ContentEditor
                    content={get(
                      content,
                      getModerationField(
                        content,
                        'comingSoonContent.description.' + Language.EN,
                      ),
                    )}
                    handleChange={(data) =>
                      contentEditorHandleChange(
                        data,
                        Language.EN,
                        'comingSoonContent.description',
                      )
                    }
                  />
                )}
              {langStrings.comingSoonDescription === Language.CY &&
                contentEditorReady && (
                  <ContentEditor
                    content={get(
                      content,
                      getModerationField(
                        content,
                        'comingSoonContent.description.' + Language.CY,
                      ),
                    )}
                    handleChange={(data) =>
                      contentEditorHandleChange(
                        data,
                        Language.CY,
                        'comingSoonContent.description',
                      )
                    }
                  />
                )}
            </Form.Field>

            <Divider section />

            <Form.Field required>
              <label>Content Type</label>
              <Select
                placeholder={'Select a content type'}
                options={contentTypes().map((type, index) => {
                  return {
                    key: `content-type-${type}-${index}`,
                    value: type,
                    text: startCase(type),
                  };
                })}
                value={
                  get(
                    content,
                    getModerationField(
                      content,
                      'comingSoonContent.contentType',
                    ),
                  ) || contentTypes()[0]
                }
                onChange={(e, data) =>
                  handleChange(`comingSoonContent.contentType`, data)
                }
              />
            </Form.Field>

            <SectionHeader
              data={content}
              fields={[
                `comingSoonContent.primaryContent.${content.contentType}`,
                'comingSoonContent.featureContent.thumbnailId',
              ]}
              title={`Coming Soon ${startCase(
                content.comingSoonContent?.contentType,
              )} Content`}
            />

            {/*Content type specific inputs*/}
            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.YOUTUBE && (
              <YouTube
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.VIMEO && (
              <Vimeo
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.SPOTIFY && (
              <Spotify
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.SOUNDCLOUD && (
              <SoundCloud
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.FACEBOOK && (
              <Facebook
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            {get(
              content,
              getModerationField(content, 'comingSoonContent.contentType'),
            ) === ContentType.MIXED && (
              <Mixed
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}

            <Divider section />

            <SectionHeader
              data={content}
              fields={[
                'comingSoonContent.featureContent.featureType',
                'comingSoonContent.featureContent.coverId',
              ]}
              title={`Feature Content`}
            />

            <Form.Field required>
              <label>Feature Type</label>
              <Select
                placeholder={'Select content to use when featured'}
                options={featureTypes().map((type, index) => {
                  return {
                    disabled:
                      (get(
                        content,
                        getModerationField(
                          content,
                          'comingSoonContent.contentType',
                        ),
                      ) === ContentType.MIXED &&
                        type === FeatureType.EMBED) ||
                      false,
                    key: `feature-type-${type}-${index}`,
                    value: type,
                    text:
                      type === FeatureType.EMBED
                        ? 'Show embedded content'
                        : 'Show cover image',
                  };
                })}
                value={
                  get(
                    content,
                    getModerationField(
                      content,
                      `comingSoonContent.featureContent.featureType`,
                    ),
                  ) || FeatureType.EMBED
                }
                onChange={(e, data) =>
                  handleChange(
                    `comingSoonContent.featureContent.featureType`,
                    data,
                  )
                }
              />
            </Form.Field>

            {get(
              content,
              getModerationField(
                content,
                `comingSoonContent.featureContent.featureType`,
              ),
            ) === FeatureType.MEDIA && (
              <ContentMediaCover
                content={content}
                handleChange={handleChange}
                comingSoon={true}
              />
            )}
          </>
        )}
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>

        {content.urlSlug && (
          <Form.Field>
            <label>URL Slug</label>
            <Form.Input
              placeholder={'Preview URL'}
              value={get(content, getModerationField(content, 'urlSlug'))}
              onChange={(e, data) => handleChange(`urlSlug`, data)}
            />
            <p className={'InputLink'}>
              <a
                href={`${config.webUrl}/${get(
                  selectedChannel,
                  'urlSlug',
                )}/${get(content, getModerationField(content, 'urlSlug'))}`}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                View Content
              </a>
            </p>
          </Form.Field>
        )}

        <Form.Field required>
          <label>Status</label>
          <Select
            fluid
            placeholder={'Select a status'}
            options={publishStatuses()
              .filter((i) => {
                if (loggedInUser?.accessType !== 'admin') {
                  const status =
                    get(
                      content,
                      getModerationField(content, `publishMeta.status`),
                    ) || publishStatuses()[0];
                  if (
                    status === PublishStatus.AWAITING_MODERATION ||
                    status === PublishStatus.DRAFT
                  ) {
                    if (
                      i === PublishStatus.PUBLISHED ||
                      i === PublishStatus.REJECTED
                    ) {
                      return false;
                    }
                  }
                }

                return true;
              })
              .map((status, index) => {
                return {
                  key: `content-status-${status}-${index}`,
                  value: status,
                  text: startCase(status),
                };
              })}
            value={
              get(content, getModerationField(content, `publishMeta.status`)) ||
              publishStatuses()[0]
            }
            onChange={(e, data) => handleChange(`publishMeta.status`, data)}
          />
        </Form.Field>

        {content.publishMeta.status === PublishStatus.REJECTED && (
          <Form.Field>
            <label>Moderation Note</label>
            <Form.TextArea
              disabled={loggedInUser?.accessType !== 'admin'}
              placeholder={'Enter any moderation notes'}
              value={get(content, `moderation.moderationNote`) || ''}
              onChange={(e, data) =>
                handleChange(`moderation.moderationNote`, data)
              }
            />
          </Form.Field>
        )}

        <Form.Field required>
          <label>Publish Date</label>
          <DatePicker
            selected={
              (content &&
                content.publishMeta &&
                new Date(
                  get(
                    content,
                    getModerationField(content, 'publishMeta.publishDate'),
                  ),
                )) ||
              new Date()
            }
            onChange={(date) =>
              handleChange('publishMeta.publishDate', {value: date})
            }
            showTimeSelect
            timeIntervals={5}
            dateFormat={'MMM d, yyyy h:mm aa'}
            popperModifiers={{
              offset: {
                enabled: true,
                offset: '-100px, 5px',
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Latest Playlist</label>
          <Checkbox
            label={`Show in latest`}
            checked={get(content, `showInLatest`)}
            onChange={async (e, data) => {
              handleChange(`showInLatest`, {value: !!data.checked});
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Channel Playlist</label>
          <Checkbox
            label={`Show in channel`}
            checked={get(content, `showInChannelLatest`)}
            onChange={async (e, data) => {
              handleChange(`showInChannelLatest`, {value: !!data.checked});
            }}
          />
        </Form.Field>

        {loggedInUser?.accessType === 'admin' && (
          <>
            <Form.Field>
              <label>Notifications</label>
              <Checkbox
                label={`Send Notifications`}
                checked={get(content, `sendNotifications`)}
                onChange={async (e, data) => {
                  handleChange(`sendNotifications`, {value: !!data.checked});
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>Private Content</label>
              <Checkbox
                label={`Watchlist restricted`}
                checked={get(
                  content,
                  getModerationField(content, `watchlist.active`),
                )}
                onChange={async (e, data) => {
                  handleChange(`watchlist.active`, {value: !!data.checked});
                }}
              />
            </Form.Field>
          </>
        )}

        <Form.Field>
          <label>Coming Soon Content</label>
          <Checkbox
            label={`Additional content`}
            checked={get(content, getModerationField(content, `isComingSoon`))}
            onChange={async (e, data) => {
              handleChange(`isComingSoon`, {value: !!data.checked});
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>Geolocation</label>
          <Checkbox
            label={`Restrict content by location`}
            checked={get(
              content,
              getModerationField(content, `geolocation.active`),
            )}
            onChange={async (e, data) => {
              handleChange(`geolocation.active`, {value: !!data.checked});
            }}
          />
        </Form.Field>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateContent(content)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            {contentId ? 'Update' : 'Create'} Content
          </Button>
          {contentId && (
            <Button
              className={'PageSidebarDelete'}
              color={'red'}
              size={'tiny'}
              onClick={() =>
                dispatch(
                  showCommonModal({
                    open: true,
                    headerText: 'Delete Content',
                    bodyText:
                      'Are you sure you wish to delete this content? This action cannot be undone.',
                    buttons: [
                      {
                        className: 'button red',
                        buttonText: 'Delete Content',
                        action: handleDelete,
                      },
                      {
                        className: 'button primary',
                        buttonText: 'Cancel',
                        action: CommonModalActionTypes.CLOSE,
                      },
                    ],
                  }),
                )
              }
            >
              Delete Content
            </Button>
          )}
        </div>

        {contentId && (
          <>
            {get(content, 'channelId') &&
              selectedChannel?._id === get(content, 'channelId') && (
                <div>
                  <Divider section />

                  <h2>Feature Content</h2>

                  {selectedChannel.featuredContentId === contentId && (
                    <p>
                      This content is featured on {selectedChannel.channelName}.
                    </p>
                  )}

                  {selectedChannel.featuredContentId !== contentId && (
                    <div className={'PageSidebarActions'}>
                      <Button
                        className={'PageSidebarAction'}
                        onClick={handleFeaturedSubmit}
                        primary
                      >
                        Feature Content
                      </Button>
                    </div>
                  )}
                </div>
              )}

            {loggedInUser?.accessType === 'admin' && (
              <>
                <Divider section />

                <h2>Highlights</h2>

                {isCurrentlyHighlighted() && (
                  <div className={'HighlightCurrent'}>
                    <p className={'bold-type'}>Current</p>
                    {currentHighlights && currentHighlights.current.hero && (
                      <p className={'HighlightLabel'}>Hero</p>
                    )}
                    {currentHighlights &&
                      currentHighlights.current.highlights &&
                      Object.keys(currentHighlights.current.highlights).map(
                        (k) => {
                          if (currentHighlights.current.highlights[k]) {
                            return (
                              <p
                                key={`highlight-label-${k}`}
                                className={'HighlightLabel'}
                              >
                                Highlight {parseInt(k) + 1}
                              </p>
                            );
                          }

                          return null;
                        },
                      )}

                    <Divider section />
                  </div>
                )}

                {currentHighlights.scheduled &&
                  currentHighlights.scheduled.length > 0 && (
                    <div className={'HighlightCurrent'}>
                      <p className={'bold-type'}>Scheduled</p>

                      {currentHighlights.scheduled.map((item, index) => {
                        return (
                          <p
                            className={'HighlightLabel'}
                            key={`scheduled-highlight-${index}`}
                          >
                            {item.highlightType === HighlightType.HERO
                              ? startCase(item.highlightType)
                              : `Highlight ${item.highlightPosition + 1}`}
                            <br />
                            {moment(item.publishMeta.publishDate).format(
                              'MMM D, YYYY h:mm A',
                            )}
                          </p>
                        );
                      })}

                      <Divider section />
                    </div>
                  )}

                <p className={'bold-type'}>Create Highlight</p>
                <Form.Field>
                  <label>Highlight Type</label>
                  <Select
                    fluid
                    placeholder={'Select type'}
                    options={highlightTypes().map((type, index) => {
                      return {
                        key: `highlight-type-${type}-${index}`,
                        value: type,
                        text: startCase(type),
                      };
                    })}
                    value={
                      get(scheduleHighlight, `highlightType`) ||
                      systemStatuses()[0]
                    }
                    onChange={(e, data) =>
                      handleHighlightChange(`highlightType`, data)
                    }
                  />
                </Form.Field>

                {scheduleHighlight.highlightType ===
                  HighlightType.HIGHLIGHT && (
                  <Form.Field>
                    <label>Highlight Position</label>
                    <Select
                      placeholder={'Select a highlight position'}
                      options={highlightPositions(TOTAL_HIGHLIGHTS)}
                      value={
                        get(scheduleHighlight, `highlightPosition`) ||
                        highlightPositions(TOTAL_HIGHLIGHTS)[0].value
                      }
                      onChange={(e, data) =>
                        handleHighlightChange(`highlightPosition`, data)
                      }
                    />
                  </Form.Field>
                )}

                <Form.Field required>
                  <label>Publish Date</label>
                  <DatePicker
                    selected={
                      (scheduleHighlight &&
                        scheduleHighlight.publishMeta &&
                        new Date(scheduleHighlight.publishMeta.publishDate)) ||
                      new Date()
                    }
                    onChange={(date) =>
                      handleHighlightChange('publishMeta.publishDate', {
                        value: date,
                      })
                    }
                    showTimeSelect
                    timeIntervals={5}
                    dateFormat={'MMM d, yyyy h:mm aa'}
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: '-100px, 5px',
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                  />
                </Form.Field>

                <div className={'PageSidebarActions'}>
                  <Button
                    disabled={validateSchedule([scheduleHighlight])}
                    className={'PageSidebarAction'}
                    onClick={handleHighlightSubmit}
                    primary
                  >
                    Create Highlight
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </aside>
    </Form>
  );
};

export default ContentItemView;
