import {get} from 'lodash';
import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Form} from 'semantic-ui-react';
import config from '../../config/config';
import {CommonModalActionTypes} from '../../const/enums/CommonModal';
import {SchemaName} from '../../const/enums/Schema';
import {Content} from '../../const/interfaces/Content';
import {showCommonModal} from '../../redux/actions/common-modal/CommonModalAction';
import {getModerationField} from '../../util/ModerationHelper';

export interface ContentThumbnailProps {
  content: Content;
  handleChange: any;
  thumbnail?: string;
  comingSoon?: boolean;
}

const ContentThumbnail = ({
  content,
  handleChange,
  thumbnail,
  comingSoon,
}: ContentThumbnailProps) => {
  const dispatch = useDispatch();

  const field = comingSoon
    ? `comingSoonContent.featureContent`
    : `featureContent`;

  const featureContentMediaId = get(
    content,
    getModerationField(content, `${field}.thumbnailId`, true),
  );

  return (
    <Form.Field required>
      <label>Content Thumbnail</label>
      <div
        className={'ContentTypeThumbnail'}
        style={{
          backgroundImage:
            featureContentMediaId && featureContentMediaId.length
              ? `url(${config.api}/file/${get(
                  content,
                  getModerationField(content, `${field}.thumbnailId`, true),
                )})`
              : `url(${thumbnail})`,
        }}
      />

      <div className={'ContentTypeThumbnailActions'}>
        <Form.Input
          placeholder={'Enter an ALT tag'}
          value={
            get(content, getModerationField(content, `${field}.alt`)) ||
            get(content, getModerationField(content, `contentName`)) ||
            ''
          }
          onChange={(e, data) => handleChange(`${field}.alt`, data)}
        />

        <Button
          primary
          onClick={(event) => {
            event.preventDefault();

            dispatch(
              showCommonModal({
                open: true,
                headerText: 'Upload Custom Thumbnail',
                buttons: [
                  {
                    className: 'button primary',
                    buttonText: 'Save Thumbnail',
                    action: 'upload',
                  },
                  {
                    className: 'button secondary',
                    buttonText: 'Cancel',
                    action: CommonModalActionTypes.CLOSE,
                  },
                ],
                upload: {
                  documentId: content._id,
                  model: SchemaName.CONTENT,
                  field: `${field}.thumbnailId`,
                  size: {
                    width: 150,
                    height: 150,
                    minWidth: 150,
                    minHeight: 150,
                  },
                  aspect: {
                    width: 10,
                    height: 10,
                  },
                },
              }),
            );
          }}
        >
          Upload Custom Thumbnail
        </Button>

        {featureContentMediaId && featureContentMediaId.length && (
          <Button
            color={'red'}
            size={'tiny'}
            onClick={(event) => {
              event.preventDefault();

              handleChange(`${field}.thumbnailId`, {value: null});
            }}
          >
            Delete Custom Thumbnail
          </Button>
        )}
      </div>
    </Form.Field>
  );
};

export default ContentThumbnail;
