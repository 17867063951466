import {cloneDeep, get, set, sortBy, startCase} from 'lodash';
import {Types} from 'mongoose';
import React, {useCallback, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Dropdown, Form, Select, Table} from 'semantic-ui-react';
import config from '../../config/config';
import {contentCategories} from '../../const/enums/Content';
import {Language} from '../../const/enums/Language';
import {SchemaName} from '../../const/enums/Schema';
import BuildInitialCategory from '../../const/initial/Category';
import {Channel} from '../../const/interfaces/Channel';
import {User} from '../../const/interfaces/User';
import {showCommonModal} from '../../redux/actions/common-modal/CommonModalAction';
import {fetchSelectedChannelSuccess} from '../../redux/actions/selected-channel/SelectedChannelAction';
import AxiosHelper from '../../util/AxiosHelper';
import {handleSearch} from '../../util/SearchHelper';
import {validateCategory} from '../../util/ValidationHelper';
import ContentEditor from '../common/ContentEditor';
import TablePagination from '../common/TablePagination';

export interface CategoryTableProps {
  channel: Channel;
}

const CategoryTable = ({channel}: CategoryTableProps) => {
  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const pageSize = 10;

  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const [categoryPaginationPage, setCategoryPaginationPage] = useState(1);
  const [categoryPaginationTotal, setCategoryPaginationTotal] = useState(
    channel.categories.length,
  );

  const [langStrings, setLangStrings] = useState({
    description: Language.EN,
  });

  const [contentSearchResults, setContentSearchResults] = useState([] as any);

  const [contentItems, setContentItems] = useState([] as any);

  const [selectedCategoryItem, setSelectedCategoryItem] = useState(
    BuildInitialCategory(channel._id),
  );
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);

  const clearCategoryModal = () => {
    setCategoryModalOpen(false);
    setSelectedCategoryItem(BuildInitialCategory(channel._id));
    dispatch(showCommonModal({open: false}));
  };

  const handleLangChange = (field: string, lang: Language) => {
    if (field && lang) {
      const cloned = cloneDeep(langStrings);
      set(cloned, field, lang);
      setLangStrings(cloned);
    }
  };

  const handleCategoryChange = useCallback(
    (field: string | string[], data: any) => {
      if (field && data) {
        const cloned = cloneDeep(selectedCategoryItem);

        if (!Array.isArray(field)) {
          field = [field];
        }

        if (!Array.isArray(data)) {
          data = [data];
        }

        for (let i = 0; i < field.length; i++) {
          set(cloned, field[i], data[i].value);
        }

        setSelectedCategoryItem(cloned);
      }
    },
    [selectedCategoryItem],
  );

  const handleContentCategoryChange = useCallback(
    (field: string | string[], data: string[]) => {
      if (field && data) {
        const cloned = cloneDeep(selectedCategoryItem);

        set(cloned, field, data);

        setSelectedCategoryItem(cloned);
      }
    },
    [selectedCategoryItem],
  );

  const contentEditorHandleChange = useCallback(
    (data, lang, type: 'description') =>
      handleCategoryChange(`${type}.${lang}`, data),
    [handleCategoryChange],
  );

  const handleCategorySave = () => {
    const cloned = cloneDeep(channel);

    // Find if update or create category
    if (selectedCategoryItem._id) {
      cloned.categories = cloned.categories.map((category) => {
        if (category._id === selectedCategoryItem._id) {
          return selectedCategoryItem;
        }
        return category;
      });

      // Add toast
      addToast(`Highlight updated successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      selectedCategoryItem._id = new Types.ObjectId().toHexString();
      cloned.categories.push(selectedCategoryItem);

      // Add toast
      addToast(`Highlight created successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }

    dispatch(fetchSelectedChannelSuccess(cloned));
    clearCategoryModal();
  };

  const handleCategoryDelete = () => {
    const cloned = cloneDeep(channel);

    if (!selectedCategoryItem._id) {
      // Add toast
      addToast(`Highlight deletion failed. Try again later.`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }

    // Delete category
    let deletedIndex;
    cloned.categories = cloned.categories.filter((item, index) => {
      if (item._id === selectedCategoryItem._id) {
        deletedIndex = index;
      }
      return item._id !== selectedCategoryItem._id;
    });

    // Update pagination if necessary
    if (deletedIndex) {
      if (
        deletedIndex + 1 === categoryPaginationTotal &&
        categoryPaginationPage > 1
      ) {
        setCategoryPaginationPage(categoryPaginationPage - 1);
      }
    }

    // Add toast
    addToast(`Highlight deleted successfully.`, {
      appearance: 'success',
      autoDismiss: true,
    });

    dispatch(fetchSelectedChannelSuccess(cloned));
    clearCategoryModal();
  };

  useEffect(() => {
    if (channel.categories.length !== categoryPaginationTotal) {
      setCategoryPaginationTotal(channel.categories.length);
    }

    // Get content items
    if (loggedInUser?.token) {
      (async () => {
        const contentIds = channel.categories
          .filter((i) => !i.isPlaylist)
          .map((i) => i.contentId);

        if (contentIds.length) {
          const axiosHelper = new AxiosHelper(loggedInUser.token);
          const content = await axiosHelper.get(`${config.api}/content/`, {
            _id: {$in: contentIds},
          });

          if (content?.data?.results?.length) {
            setContentItems(content.data.results);
          }
        }
      })();
    }
  }, [
    channel.categories,
    categoryPaginationTotal,
    setCategoryPaginationTotal,
    loggedInUser,
  ]);

  useEffect(() => {
    if (categoryModalOpen) {
      const isEditing = !!selectedCategoryItem._id;

      if (
        !selectedCategoryItem.isPlaylist &&
        selectedCategoryItem.contentId &&
        !contentSearchResults.length
      ) {
        (async () => {
          const axiosHelper = new AxiosHelper(loggedInUser.token);
          const content = await axiosHelper.get(
            `${config.api}/content/${selectedCategoryItem.contentId}`,
          );

          if (content) {
            setContentSearchResults([
              {
                key: `content-${content.data.contentName}-0`,
                value: content.data._id,
                text: content.data.contentName,
                image: content.data.featureContent.thumbnailId
                  ? `${config.api}/file/${content.data.featureContent.thumbnailId}`
                  : content.data.primaryContent[content.data.contentType]
                      .mediaUrl,
              },
            ]);
          }
        })();
      }

      const buttons = [
        {
          disabled: validateCategory([selectedCategoryItem]),
          className: 'button primary',
          buttonText: 'Save Highlight',
          action: handleCategorySave,
        },
        {
          className: 'button secondary',
          buttonText: 'Cancel',
          action: clearCategoryModal,
        },
      ];

      if (isEditing) {
        buttons.splice(1, 0, {
          className: 'button red',
          buttonText: 'Delete Highlight',
          action: handleCategoryDelete,
        });
      }

      dispatch(
        showCommonModal({
          open: true,
          onClose: clearCategoryModal,
          headerText: `${isEditing ? 'Edit' : 'Create'} Highlight`,
          bodyText: (
            <Form>
              <Form.Field required>
                <label>Highlight Type</label>
                <Form.Checkbox
                  toggle
                  label={'Is A Playlist'}
                  checked={get(selectedCategoryItem, 'isPlaylist')}
                  onChange={(e, data) =>
                    handleCategoryChange(
                      [`isPlaylist`],
                      [
                        {
                          value: !!data.checked,
                        },
                      ],
                    )
                  }
                />
              </Form.Field>

              <Form.Field required>
                <label>Status</label>
                <Form.Checkbox
                  toggle
                  label={'Hidden'}
                  checked={get(selectedCategoryItem, 'hidden')}
                  onChange={(e, data) =>
                    handleCategoryChange(
                      [`hidden`],
                      [
                        {
                          value: !!data.checked,
                        },
                      ],
                    )
                  }
                />
              </Form.Field>

              <Form.Field required>
                <label>Order</label>
                <Form.Input
                  type={'number'}
                  placeholder={'Enter an Order'}
                  value={get(selectedCategoryItem, 'order') || ''}
                  onChange={(e, data) => handleCategoryChange('order', data)}
                />
              </Form.Field>

              {get(selectedCategoryItem, 'isPlaylist') && (
                <>
                  <Form.Field required>
                    <label>Highlight Playlist Name</label>
                    <Form.Input
                      placeholder={'Enter a Highlight Name'}
                      value={get(selectedCategoryItem, 'categoryName') || ''}
                      onChange={(e, data) =>
                        handleCategoryChange('categoryName', data)
                      }
                    />
                  </Form.Field>

                  <Form.Field required>
                    <label>URL Slug</label>
                    <Form.Input
                      placeholder={'Enter a URL slug'}
                      value={get(selectedCategoryItem, 'urlSlug') || ''}
                      onChange={(e, data) =>
                        handleCategoryChange('urlSlug', data)
                      }
                    />
                    <p className={'InputLink'}>
                      <a
                        href={`${config.webUrl}/channel-highlight/${get(
                          channel,
                          'urlSlug',
                        )}/${get(selectedCategoryItem, 'urlSlug')}`}
                        target={'_blank'}
                        rel="noopener noreferrer"
                      >
                        View Content
                      </a>
                    </p>
                  </Form.Field>

                  <Form.Field>
                    <label>Content Category</label>
                    <Select
                      search
                      placeholder={'Select a content category'}
                      multiple={true}
                      options={contentCategories().map((cat, index) => {
                        return {
                          key: `content-cat-${cat}-${index}`,
                          value: cat,
                          text: startCase(cat),
                        };
                      })}
                      value={get(selectedCategoryItem, `contentCategory`) || []}
                      onChange={(e, data) =>
                        handleContentCategoryChange(
                          `contentCategory`,
                          data.value as string[],
                        )
                      }
                    />
                  </Form.Field>

                  <Form.Field>
                    <label className={'LangStringSelector'}>
                      <span
                        className={
                          langStrings.description !== Language.EN
                            ? 'normal-type'
                            : ''
                        }
                        onClick={() =>
                          handleLangChange('description', Language.EN)
                        }
                      >
                        Description
                      </span>
                      <span className={'normal-type'}> | </span>
                      <span
                        className={
                          langStrings.description !== Language.CY
                            ? 'normal-type'
                            : ''
                        }
                        onClick={() =>
                          handleLangChange('description', Language.CY)
                        }
                      >
                        Disgrifiad
                      </span>
                    </label>
                    {langStrings.description === Language.EN && (
                      <ContentEditor
                        content={get(
                          selectedCategoryItem,
                          'description.' + Language.EN,
                        )}
                        handleChange={(data) =>
                          contentEditorHandleChange(
                            data,
                            Language.EN,
                            'description',
                          )
                        }
                      />
                    )}
                    {langStrings.description === Language.CY && (
                      <ContentEditor
                        content={get(
                          selectedCategoryItem,
                          'description.' + Language.CY,
                        )}
                        handleChange={(data) =>
                          contentEditorHandleChange(
                            data,
                            Language.CY,
                            'description',
                          )
                        }
                      />
                    )}
                  </Form.Field>

                  <div className={'CategoryLogoWrapper'}>
                    <Form.Field required>
                      <label>Highlight Playlist Thumbnail</label>
                      <div
                        className={'CategoryLogoPreview'}
                        style={
                          selectedCategoryItem.thumbnailId
                            ? {
                                backgroundImage: `url(${config.api}/file/${selectedCategoryItem.thumbnailId})`,
                              }
                            : undefined
                        }
                      />

                      <div className={'CategoryLogoActions'}>
                        <Button
                          primary
                          onClick={(event) => {
                            event.preventDefault();

                            dispatch(
                              showCommonModal({
                                open: true,
                                onClose: clearCategoryModal,
                                headerText: 'Upload Highlight Thumbnail',
                                buttons: [
                                  {
                                    className: 'button primary',
                                    buttonText: 'Save Thumbnail',
                                    action: 'upload',
                                  },
                                  {
                                    className: 'button secondary',
                                    buttonText: 'Cancel',
                                    action: clearCategoryModal,
                                  },
                                ],
                                upload: {
                                  localSetState: handleCategoryChange,
                                  documentId: selectedCategoryItem._id,
                                  model: SchemaName.CATEGORY,
                                  field: `thumbnailId`,
                                  size: {
                                    width: 150,
                                    height: 150,
                                    minWidth: 150,
                                    minHeight: 150,
                                  },
                                  aspect: {
                                    width: 10,
                                    height: 10,
                                  },
                                },
                              }),
                            );
                          }}
                        >
                          Upload Thumbnail
                        </Button>
                      </div>
                    </Form.Field>
                  </div>
                </>
              )}

              {!get(selectedCategoryItem, 'isPlaylist') && (
                <Form.Field required>
                  <label>Content</label>
                  <Dropdown
                    placeholder={'Type to search Content'}
                    noResultsMessage={'Type to search Content'}
                    fluid
                    search
                    clearable
                    onSearchChange={async (e, data) => {
                      await handleSearch(
                        loggedInUser.token,
                        SchemaName.CONTENT,
                        data.searchQuery,
                        contentSearchResults,
                        setContentSearchResults,
                        {
                          channelId: new Types.ObjectId(channel._id),
                        },
                      );
                    }}
                    onChange={(e, data) => {
                      let val: any = data?.value as string;
                      if (!val?.length) {
                        val = null;
                      }

                      handleCategoryChange('contentId', {value: val});
                    }}
                    selection
                    options={contentSearchResults}
                    value={get(selectedCategoryItem, 'contentId')}
                  />
                </Form.Field>
              )}
            </Form>
          ),
          buttons,
        }),
      );
    }
  });

  const categoryTableHeaders = ['', 'Name', 'Type', 'Status', 'Order'].map(
    (header) => {
      return (
        <Table.Cell className={'TableHeader'} key={`category-header-${header}`}>
          {header}
        </Table.Cell>
      );
    },
  );

  const getContentData = (contentId: string, field: 'name' | 'thumbnail') => {
    if (contentItems.length) {
      const item = contentItems.find((i) => i._id === contentId);

      if (item) {
        if (field === 'name') {
          return item.contentName;
        }

        if (field === 'thumbnail') {
          return item.featureContent.thumbnailId
            ? `${config.api}/file/${item.featureContent.thumbnailId}`
            : item.primaryContent[item.contentType].mediaUrl;
        }
      }
    }
  };

  const categoryTableItems = sortBy(channel.categories, ['order']).map(
    (category: any, index: number) => {
      if (
        index >= (categoryPaginationPage - 1) * pageSize &&
        index < categoryPaginationPage * pageSize
      ) {
        return (
          <Table.Row
            className={'TableRowClickable'}
            key={`category-row-${index}`}
            onClick={() => {
              setSelectedCategoryItem(category);
              setCategoryModalOpen(true);
            }}
          >
            <Table.Cell>
              <div
                className={'TableImage'}
                style={
                  category.isPlaylist
                    ? category.thumbnailId && {
                        backgroundImage: `url(${config.api}/file/${category.thumbnailId})`,
                      }
                    : {
                        backgroundImage: `url(${getContentData(
                          category.contentId,
                          'thumbnail',
                        )}`,
                      }
                }
              />
            </Table.Cell>
            <Table.Cell>
              {category.isPlaylist
                ? category.categoryName
                : getContentData(category.contentId, 'name')}
            </Table.Cell>
            <Table.Cell>
              {category.isPlaylist ? 'Playlist' : 'Content'}
            </Table.Cell>
            <Table.Cell>{category.hidden ? 'Hidden' : 'Active'}</Table.Cell>
            <Table.Cell>{category.order}</Table.Cell>
          </Table.Row>
        );
      }
      return null;
    },
  );

  return (
    <>
      <div className={'SubTableHeader'}>
        <h2 className={'alt-colour-type'}>Highlight Items</h2>
        <Button
          primary
          onClick={(event) => {
            event.preventDefault();

            setCategoryModalOpen(true);
          }}
        >
          Create Highlight
        </Button>
      </div>

      <Table className={'Table'} textAlign={'center'} selectable striped>
        <Table.Header>
          <Table.Row>{categoryTableHeaders}</Table.Row>
        </Table.Header>

        <Table.Body>
          {categoryTableItems && categoryTableItems.length ? (
            categoryTableItems
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <p>No Highlights found</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <TablePagination
          totalCols={5}
          pageSize={pageSize}
          paginationPage={categoryPaginationPage}
          paginationTotal={categoryPaginationTotal}
          onChange={(activePage: number) => {
            setCategoryPaginationPage(activePage);
          }}
        />
      </Table>
    </>
  );
};

export default CategoryTable;
