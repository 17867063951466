import {useAuth0} from '@auth0/auth0-react';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Switch} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import PrivateRoute from './components/auth/PrivateRoute';
import CommonModal from './components/common/CommonModal';
import HeaderBar from './components/HeaderBar';
import Loading from './components/Loading';
import Navigation from './components/Navigation';
import {User} from './const/interfaces/User';
import AdditionalCuratedView from './views/AdditionalCurated';
import ChannelItemView from './views/ChannelItem';
import Channels from './views/Channels';
import ComingSoon from './views/ComingSoon';
import Content from './views/Content';
import ContentItemView from './views/ContentItem';
import CuratedView from './views/Curated';
import Featured from './views/Featured';
import FiveCuratedView from './views/FiveCurated';
import FourCuratedView from './views/FourCurated';
import Highlights from './views/Highlights';
import NotificationsView from './views/Notifications';
import SixCuratedView from './views/SixCurated';
import System from './views/System';
import TertiaryCuratedView from './views/TertiaryCurated';
import UserItemView from './views/UserItem';
import Users from './views/Users';

const App = () => {
  const {isLoading} = useAuth0();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const loggedInUser: User =
    useSelector((state) => state.loggedInUser).data || {};

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="App">
      <CommonModal />

      <div className={'Wrapper'}>
        <Navigation
          mobileNavOpen={mobileNavOpen}
          setMobileNavOpen={setMobileNavOpen}
        />

        <div className={'Main'}>
          <HeaderBar
            mobileNavOpen={mobileNavOpen}
            setMobileNavOpen={setMobileNavOpen}
          />

          <Switch>
            {loggedInUser?.accessType === 'admin' ? (
              <>
                <PrivateRoute path="/" exact component={Highlights} />
                <PrivateRoute path="/featured" exact component={Featured} />
                <PrivateRoute
                  path="/coming-soon"
                  exact
                  component={ComingSoon}
                />
                <PrivateRoute path="/curated" exact component={CuratedView} />
                <PrivateRoute
                  path="/additional-curated"
                  exact
                  component={AdditionalCuratedView}
                />
                <PrivateRoute
                  path="/tertiary-curated"
                  exact
                  component={TertiaryCuratedView}
                />
                <PrivateRoute
                  path="/four-curated"
                  exact
                  component={FourCuratedView}
                />
                <PrivateRoute
                  path="/five-curated"
                  exact
                  component={FiveCuratedView}
                />
                <PrivateRoute
                  path="/six-curated"
                  exact
                  component={SixCuratedView}
                />
                <PrivateRoute path="/system" exact component={System} />
                <PrivateRoute
                  path="/notifications"
                  exact
                  component={NotificationsView}
                />
                <PrivateRoute path="/users" exact component={Users} />
                <PrivateRoute path="/channels" exact component={Channels} />
                <PrivateRoute
                  path="/channel-item"
                  exact
                  component={ChannelItemView}
                />
                <PrivateRoute
                  path="/channel-item/:id"
                  exact
                  component={ChannelItemView}
                />
                <PrivateRoute path="/content" exact component={Content} />
                <PrivateRoute
                  path="/content-item"
                  exact
                  component={ContentItemView}
                />
                <PrivateRoute
                  path="/content-item/:id"
                  exact
                  component={ContentItemView}
                />

                <PrivateRoute
                  path="/user-item/"
                  exact
                  component={UserItemView}
                />
                <PrivateRoute
                  path="/user-item/:id"
                  exact
                  component={UserItemView}
                />
              </>
            ) : (
              <>
                <PrivateRoute path="/" exact component={Channels} />
                <PrivateRoute path="/channels" exact component={Channels} />
                <PrivateRoute
                  path="/channel-item"
                  exact
                  component={ChannelItemView}
                />
                <PrivateRoute
                  path="/channel-item/:id"
                  exact
                  component={ChannelItemView}
                />
                <PrivateRoute path="/content" exact component={Content} />
                <PrivateRoute
                  path="/content-item"
                  exact
                  component={ContentItemView}
                />
                <PrivateRoute
                  path="/content-item/:id"
                  exact
                  component={ContentItemView}
                />

                <PrivateRoute
                  path="/user-item/"
                  exact
                  component={UserItemView}
                />
                <PrivateRoute
                  path="/user-item/:id"
                  exact
                  component={UserItemView}
                />
              </>
            )}
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default App;
