import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Divider, Icon} from 'semantic-ui-react';
import version from '../config/version';
import {User} from '../const/interfaces/User';

const logo = require('../assets/am-logo.png');

const NavigationItem = (props: {
  title: string;
  route: string;
  setMobileNavOpen;
}) => {
  return (
    <Link
      className={'NavigationItem'}
      onClick={() => props.setMobileNavOpen(false)}
      to={props.route}
    >
      {props.title}
    </Link>
  );
};

const Navigation = ({mobileNavOpen, setMobileNavOpen}) => {
  const loggedInUser: User =
    useSelector((state) => state.loggedInUser).data || {};

  return (
    <aside className={`Navigation ${mobileNavOpen && 'MobileNavigation'}`}>
      {mobileNavOpen && (
        <Icon
          className={'MobileNavigationClose'}
          name="times"
          onClick={() => setMobileNavOpen(!mobileNavOpen)}
        />
      )}

      <img src={logo} alt={'AM - Dashboard'} />

      <div className={'NavigationLinks'}>
        {loggedInUser?.accessType === 'admin' && (
          <>
            <NavigationItem
              title={'Highlights'}
              route={'/'}
              setMobileNavOpen={setMobileNavOpen}
            />
            <NavigationItem
              title={'Featured'}
              route={'/featured'}
              setMobileNavOpen={setMobileNavOpen}
            />
            <NavigationItem
              title={'Coming Soon'}
              route={'/coming-soon'}
              setMobileNavOpen={setMobileNavOpen}
            />
            <NavigationItem
              title={'Curated'}
              route={'/curated'}
              setMobileNavOpen={setMobileNavOpen}
            />
            <NavigationItem
              title={'Additional Curated'}
              route={'/additional-curated'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <NavigationItem
              title={'Additional Curated 2'}
              route={'/tertiary-curated'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <NavigationItem
              title={'Additional Curated 3'}
              route={'/four-curated'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <NavigationItem
              title={'Additional Curated 4'}
              route={'/five-curated'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <NavigationItem
              title={'Additional Curated 5'}
              route={'/six-curated'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <Divider hidden className={'NavigationDivider'} />
          </>
        )}

        <NavigationItem
          title={'Channels'}
          route={'/channels'}
          setMobileNavOpen={setMobileNavOpen}
        />
        <NavigationItem
          title={'Content'}
          route={'/content'}
          setMobileNavOpen={setMobileNavOpen}
        />

        {loggedInUser?.accessType === 'admin' && (
          <>
            <NavigationItem
              title={'Users'}
              route={'/users'}
              setMobileNavOpen={setMobileNavOpen}
            />

            <Divider hidden className={'NavigationDivider'} />

            <NavigationItem
              title={'System'}
              route={'/system'}
              setMobileNavOpen={setMobileNavOpen}
            />
            <NavigationItem
              title={'Notifications'}
              route={'/notifications'}
              setMobileNavOpen={setMobileNavOpen}
            />
          </>
        )}
      </div>

      <p className={'NavigationVersion'}>v{version.version}</p>
    </aside>
  );
};

export default Navigation;
