import {get} from 'lodash';

export const getModerationField = (data: any, field: string, allowNull: boolean = false): string => {
  if (get(data, `moderation.${field}`)) {
    field = `moderation.${field}`;
  }

  if (allowNull) {
    if (get(data, `moderation.${field}`) === null) {
      field = `moderation.${field}`;
    }
  }

  return field;
};
