import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const getCroppedImg = (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx &&
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      1,
    );
  });
};

export interface ImageCropProps {
  size?: {
    width: number;
    height: number;
    minWidth?: number;
    minHeight?: number;
  };
  aspect?: {
    width: number;
    height: number;
  };
  setState: any;
}

const ImageCrop = (props: ImageCropProps) => {
  const [upImg, setUpImg] = useState(null as unknown);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: 'px',
    width: props.size?.width || 150,
    height: props.size?.height || 150,
    aspect:
      ((props.aspect && props.aspect.width) || 16) /
      ((props.aspect && props.aspect.height) || 4.4),
  });
  const [completedCrop, setCompletedCrop] = useState(null as any);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image: any = imgRef.current;
    const crop: any = completedCrop;

    if (!image || !crop) {
      return;
    }

    props.setState(getCroppedImg(image, crop));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <div>
      <div className={'FileDrop'}>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
      <ReactCrop
        src={upImg}
        onImageLoaded={onLoad}
        crop={crop}
        minWidth={props.size?.minWidth}
        minHeight={props.size?.minHeight}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
      />
    </div>
  );
};

export default ImageCrop;
