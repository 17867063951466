import {
  FETCH_SYSTEM_ERROR,
  FETCH_SYSTEM_PENDING,
  FETCH_SYSTEM_SUCCESS,
} from './SystemActionTypes';

export const fetchSystemPending = () => {
  return {
    type: FETCH_SYSTEM_PENDING,
  };
};

export const fetchSystemSuccess = (system) => {
  return {
    type: FETCH_SYSTEM_SUCCESS,
    payload: system,
  };
};

export const fetchSystemError = (error) => {
  return {
    type: FETCH_SYSTEM_ERROR,
    payload: error,
  };
};
