const BuildInitialSponsor = () => {
  return {
    _id: null,
    sponsorName: '',
    url: '',
    logo: null,
  };
};

export default BuildInitialSponsor;
