import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button, Checkbox, Dropdown, Popup, Table} from 'semantic-ui-react';
import ScheduleTable from '../components/common/ScheduleTable';
import TablePagination from '../components/common/TablePagination';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import BuildInitialChannel from '../const/initial/Channel';
import {fetchSelectedChannelSuccess} from '../redux/actions/selected-channel/SelectedChannelAction';
import {handlePagination} from '../util/PaginationHelper';
import {handleSearch} from '../util/SearchHelper';

const ChannelsView = () => {
  const pageSize = 10;

  const dispatch = useDispatch();
  const history = useHistory();
  const [channels, setChannels] = useState([]);
  const loggedInUser = useSelector((state) => state.loggedInUser).data;
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState([] as any);
  const [filterSearchResults, setFilterSearchResults] = useState([] as any);
  const [moderationFilter, setModerationFilter] = useState(false);
  const [scheduleFilter, setScheduleFilter] = useState(false);

  const handlePaginationChange = useCallback(
    async (activePage: number) => {
      const filters: any = {};
      if (moderationFilter) {
        filters.moderation = {$ne: null};
      }

      if (loggedInUser?.accessType !== 'admin') {
        filters._id = {$in: loggedInUser.accessChannels};
      }

      await handlePagination(
        loggedInUser && loggedInUser.token,
        SchemaName.CHANNEL,
        activePage,
        pageSize,
        filterSearchValue,
        setChannels,
        setPaginationPage,
        setPaginationTotal,
        filters,
      );
    },
    [loggedInUser, filterSearchValue, moderationFilter],
  );

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        // Get channels
        await handlePaginationChange(1);
      })();
    }
  }, [loggedInUser, handlePaginationChange]);

  const channelHeaders = [
    '',
    'Name',
    'Followers',
    'Created',
    'Last Updated',
  ].map((header) => {
    return (
      <Table.Cell className={'TableHeader'} key={`channel-header-${header}`}>
        {header}
      </Table.Cell>
    );
  });

  const channelItems =
    channels &&
    channels.map((channel: any, index: number) => {
      return (
        <Table.Row
          className={'TableRowClickable'}
          key={`channel-row-${index}`}
          onClick={() => history.push(`/channel-item/${channel._id}`)}
        >
          <Table.Cell>
            <div
              className={'TableImage'}
              style={
                channel.theme.logo && {
                  backgroundImage: `url(${config.api}/file/${channel.theme.logo})`,
                }
              }
            >
              {channel.moderation && (
                <Popup
                  position={'left center'}
                  size={'small'}
                  content={'This content requires moderation'}
                  trigger={<div className={'TableImageModerationFlag'} />}
                />
              )}
            </div>
          </Table.Cell>
          <Table.Cell>{channel.channelName}</Table.Cell>
          <Table.Cell>{channel.followerCount}</Table.Cell>
          <Table.Cell>
            {moment(channel.meta.created).format('DD/MM/YYYY HH:mm')}
          </Table.Cell>
          <Table.Cell>
            {moment(channel.meta.lastUpdated).format('DD/MM/YYYY HH:mm')}
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <div className={'Page'}>
      <div className={'PageContent'}>
        <div className={'PageContentHeader'}>
          <h1>Channels</h1>
          {loggedInUser?.accessType === 'admin' && (
            <Button
              primary
              onClick={(event) => {
                event.preventDefault();

                // Clear existing selectedChannel data
                dispatch(
                  fetchSelectedChannelSuccess(
                    BuildInitialChannel(loggedInUser._id),
                  ),
                );

                // Redirect
                history.push(`/channel-item`);
              }}
            >
              Create Channel
            </Button>
          )}
        </div>

        <div className={'Filter'}>
          <h2>Search Channels</h2>
          <Dropdown
            placeholder={'Type to search Channels'}
            noResultsMessage={'Type to search Channels'}
            fluid
            search
            clearable
            multiple
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                SchemaName.CHANNEL,
                data.searchQuery,
                filterSearchResults,
                setFilterSearchResults,
                loggedInUser?.accessType !== 'admin'
                  ? {_id: {$in: loggedInUser.accessChannels}}
                  : {},
              );
            }}
            onChange={async (e, data) => {
              if (data.value) {
                setPaginationPage(1);
                setFilterSearchValue(data.value);
              }
            }}
            selection
            options={filterSearchResults}
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />

          {loggedInUser?.accessType === 'admin' && (
            <>
              <Checkbox
                className={'FilterModeration'}
                label={`Show channels awaiting moderation`}
                checked={moderationFilter}
                onChange={async (e, data) => {
                  setModerationFilter(!!data.checked);
                }}
              />

              <Checkbox
                className={'FilterModeration'}
                slider
                label={`View Schedule`}
                checked={scheduleFilter}
                onChange={async (e, data) => {
                  setScheduleFilter(!!data.checked);
                }}
              />
            </>
          )}
        </div>

        {!scheduleFilter && (
          <Table className={'Table'} textAlign={'center'} selectable striped>
            <Table.Header>
              <Table.Row>{channelHeaders}</Table.Row>
            </Table.Header>

            <Table.Body>
              {channelItems && channelItems.length ? (
                channelItems
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <p>No Channels found</p>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>

            <TablePagination
              totalCols={6}
              pageSize={pageSize}
              paginationPage={paginationPage}
              paginationTotal={paginationTotal}
              onChange={handlePaginationChange}
            />
          </Table>
        )}

        {scheduleFilter && (
          <ScheduleTable
            loggedInUser={loggedInUser}
            model={SchemaName.CHANNEL}
            moderationFilter={moderationFilter}
          />
        )}
      </div>
    </div>
  );
};

export default ChannelsView;
