import {cloneDeep, get, set} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Form} from 'semantic-ui-react';
import TertiaryCuratedItem from '../components/system/TertiaryCuratedItem';
import config from '../config/config';
import {Language} from '../const/enums/Language';
import BuildInitialSystem from '../const/initial/System';
import {System} from '../const/interfaces/System';
import {User} from '../const/interfaces/User';
import {
  fetchSystemError,
  fetchSystemPending,
  fetchSystemSuccess,
} from '../redux/actions/system/SystemAction';
import AxiosHelper from '../util/AxiosHelper';
import {validateSystem} from '../util/ValidationHelper';

const TertiaryCuratedView = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const system: System =
    useSelector((state) => state.system).data ||
    BuildInitialSystem((loggedInUser && loggedInUser._id) || '');

  const [langStrings, setLangStrings] = useState({
    'tertiaryCurated.title': Language.EN,
  });

  const [contentSearchResults, setContentSearchResults] = useState([] as any);

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        try {
          dispatch(fetchSystemPending());
          const axiosHelper = new AxiosHelper(loggedInUser.token);

          // Get system
          const result = await axiosHelper.get(`${config.api}/system`);

          if (!result) {
            dispatch(fetchSystemError('No system found'));
          }

          dispatch(fetchSystemSuccess(result.data));

          // Set curated dropdowns
          if (result && result.data) {
            const content = await axiosHelper.get(`${config.api}/content`, {
              _id: {
                $in: Object.keys(
                  result.data.tertiaryCurated.content,
                ).map((k) =>
                  result.data.tertiaryCurated.content[k].contentId.length
                    ? result.data.tertiaryCurated.content[k].contentId
                    : null,
                ),
              },
            });

            if (content && content.data.results) {
              setContentSearchResults(
                content.data.results.map((item, index) => {
                  return {
                    key: `tertiary-curated-${item.contentName}-${index}`,
                    value: item._id,
                    text: item.contentName,
                    // Hiding to test performance
                    // image: item.featureContent.thumbnailId
                    //   ? `${config.api}/file/${item.featureContent.thumbnailId}`
                    //   : item.primaryContent[item.contentType].mediaUrl,
                  };
                }),
              );
            }
          }
        } catch (e) {
          dispatch(fetchSystemError(e));
        }
      })();
    }
  }, [loggedInUser, dispatch]);

  const handleChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(system);

        set(cloned, field, data.value);
        dispatch(fetchSystemSuccess(cloned));
      }
    },
    [dispatch, system],
  );

  const handleLangChange = (field: string, lang: Language) => {
    if (field && lang) {
      const cloned = cloneDeep(langStrings);
      set(cloned, field, lang);
      setLangStrings(cloned);
    }
  };

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update system
      const updatedSystem = (
        await axiosHelper.put(`${config.api}/system`, system)
      ).data;
      dispatch(fetchSystemSuccess(updatedSystem));

      // Add toast
      addToast(`Additional Curated updated successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      dispatch(fetchSystemError(e));

      // Add toast
      addToast(`Additional Curated update failed. Try again later.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleAddItem = () => {
    const cloned = cloneDeep(system);

    const content = cloned.tertiaryCurated?.content || [];

    content.push({contentId: ''});

    cloned.tertiaryCurated = {
      ...cloned.tertiaryCurated,
      content,
    };

    dispatch(fetchSystemSuccess(cloned));
  };

  const buildCurated = () => {
    if (system.tertiaryCurated?.content) {
      let curatedItems: any[] = [];
      for (let i = 0; i < system.tertiaryCurated.content.length; i++) {
        curatedItems.push(
          <TertiaryCuratedItem
            key={`tertiary-curated-${i}`}
            index={i}
            loggedInUser={loggedInUser}
            system={system}
            onChange={handleChange}
            results={contentSearchResults}
            setResults={setContentSearchResults}
          />,
        );
      }
      return curatedItems;
    }
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <h1>Additional Curated 2</h1>

        <Form.Field>
          <label className={'LangStringSelector'}>
            <span
              className={
                langStrings['tertiaryCurated.title'] !== Language.EN
                  ? 'normal-type'
                  : ''
              }
              onClick={() =>
                handleLangChange('tertiaryCurated.title', Language.EN)
              }
            >
              Playlist Title
            </span>
            <span className={'normal-type'}> | </span>
            <span
              className={
                langStrings['tertiaryCurated.title'] !== Language.CY
                  ? 'normal-type'
                  : ''
              }
              onClick={() =>
                handleLangChange('tertiaryCurated.title', Language.CY)
              }
            >
              Teitl Rhestr Chwarae
            </span>
          </label>
          {langStrings['tertiaryCurated.title'] === Language.EN && (
            <Form.Input
              placeholder={'Enter a playlist title'}
              value={
                get(system, 'tertiaryCurated.title.' + Language.EN) || ''
              }
              onChange={(e, data) =>
                handleChange(`tertiaryCurated.title.${Language.EN}`, data)
              }
              error={!get(system, 'tertiaryCurated.title.' + Language.EN)}
            />
          )}
          {langStrings['tertiaryCurated.title'] === Language.CY && (
            <Form.Input
              placeholder={'Rhowch deitl'}
              value={
                get(system, 'tertiaryCurated.title.' + Language.CY) || ''
              }
              onChange={(e, data) =>
                handleChange(`tertiaryCurated.title.${Language.CY}`, data)
              }
              error={!get(system, 'tertiaryCurated.title.' + Language.CY)}
            />
          )}
        </Form.Field>

        <Form.Field>
          <label>Display Position</label>
          <Form.Input
            placeholder={'Enter a playlist title'}
            value={get(system, 'tertiaryCurated.position')}
            onChange={(e, data) => handleChange(`tertiaryCurated.position`, data)}
          />
        </Form.Field>

        <h2 className={'alt-colour-type'}>Playlist Content</h2>

        <Button className={'PageSidebarAction'} onClick={handleAddItem} primary>
          Add Featured Content Item
        </Button>

        {buildCurated()}
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>

        <Form.Field required>
          <label>Active</label>
          <Form.Checkbox
            toggle
            label={'Show playlist'}
            checked={get(system, 'tertiaryCurated.active') || false}
            onChange={(e, data) =>
              handleChange(`tertiaryCurated.active`, {
                value: !!data.checked,
              })
            }
          />
        </Form.Field>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateSystem(system)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            Update Additional Curated
          </Button>
        </div>
      </aside>
    </Form>
  );
};

export default TertiaryCuratedView;
