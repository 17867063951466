import {Language} from '../enums/Language';
import {publishStatuses} from '../enums/PublishMeta';
import {Channel} from '../interfaces/Channel';

const coeff = 1000 * 60 * 5;
const date = new Date();

const BuildInitialChannel = (loggedinUserId: string): Channel => {
  return {
    meta: {
      created: new Date(),
      createdBy: loggedinUserId,
      lastUpdated: new Date(),
      lastUpdatedBy: loggedinUserId,
    },

    publishMeta: {
      status: publishStatuses()[0],
      publishDate: new Date(Math.floor(date.getTime() / coeff) * coeff),
    },

    channelName: '',
    urlSlug: '',

    description: {
      [Language.EN]: '',
      [Language.CY]: '',
    },

    bio: {
      [Language.EN]: '',
      [Language.CY]: '',
    },

    featuredContentLatest: true,
    featuredContentId: null,

    contactDetails: {
      email: '',
      phone: '',
      address: {
        address1: '',
        address2: '',
        address3: '',
        postalCode: '',
        country: '',
      },
    },

    social: {},

    tags: {
      private: [],
      public: [],
    },

    theme: {
      logo: null,
      logoAlt: '',
      header: null,
      headerAlt: '',
      colours: {
        primary: '',
        secondary: '',
        tertiary: '',
      },
    },

    channelAdmins: [],

    followerCount: 0,

    highlightsTitle: {
      [Language.EN]: '',
      [Language.CY]: '',
    },
    categories: [],
  };
};

export default BuildInitialChannel;
