import {cloneDeep} from 'lodash';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import AxiosHelper from './AxiosHelper';

export interface SearchOption {
  _id: string;
  key: string;
  text: string;
  image: string;
}

export interface WideSearch {
  channels: {
    name: string;
    results: SearchOption[];
  };
  content: {
    name: string;
    results: SearchOption[];
  };
}

export const initialWideSearch = {
  content: {
    name: 'Content',
    results: [],
  },
  channels: {
    name: 'Channels',
    results: [],
  },
};

export const buildSearchOption = (
  model: SchemaName,
  result: any,
  index: number,
  hideImages?: boolean,
) => {
  switch (model) {
    case SchemaName.CHANNEL:
      return {
        _id: result._id,
        key: `${result.channelName}-${index}`,
        text: result.channelName,
        value: result._id,
        image: hideImages
          ? undefined
          : result.theme.logo
          ? `${config.api}/file/${result.theme.logo}`
          : '',
      };

    case SchemaName.CONTENT:
      return {
        _id: result._id,
        key: `${result.contentName}-${index}`,
        text: result.contentName,
        value: result._id,
        image: hideImages
          ? undefined
          : result.featureContent.thumbnailId
          ? `${config.api}/file/${result.featureContent.thumbnailId}`
          : result.primaryContent[result.contentType].mediaUrl,
      };

    case SchemaName.USER:
      return {
        _id: result._id,
        key: `${result.firstName}-${result.lastName}-${index}`,
        text: `${result.firstName} ${result.lastName} (${result.contactDetails.email})`,
        value: result._id,
        image: hideImages
          ? undefined
          : result.avatar ? `${config.api}/file/${result.avatar}` : '',
      };
    case SchemaName.CATEGORY:
      return {
        _id: result.channelId,
        key: `${result.categoryName}-${index}`,
        text: `${result.categoryName} - Playlist`,
        value: result._id,
        image: hideImages
          ? undefined
          : result.thumbnailId
          ? `${config.api}/file/${result.thumbnailId}`
          : '',
      };
  }
};

export const handleWideSearch = async (
  token: string,
  searchQuery: string,
  searchResults: WideSearch = initialWideSearch,
  setState: any,
) => {
  if (searchQuery && searchQuery.length && token) {
    const axiosHelper = new AxiosHelper(token);

    // Search
    const searchAPIResult = await axiosHelper.get(`${config.api}/search`, {
      search: searchQuery,
    });

    let updatedSearchResults = cloneDeep(initialWideSearch);

    // Content
    searchAPIResult.data.content.map((result: any, index: number) => {
      const inResults = updatedSearchResults.content.results.find(
        (r) => r.value === result._id,
      );

      if (!inResults) {
        updatedSearchResults.content.results.push(
          buildSearchOption(SchemaName.CONTENT, result, index),
        );
      }

      return result;
    });

    // Channel
    searchAPIResult.data.channels.map((result: any, index: number) => {
      const inResults = updatedSearchResults.channels.results.find(
        (r) => r.value === result._id,
      );

      if (!inResults) {
        updatedSearchResults.channels.results.push(
          buildSearchOption(SchemaName.CHANNEL, result, index),
        );
      }
      return result;
    });

    setState(updatedSearchResults);
  }
};

export const handleSearch = async (
  token: string,
  models: SchemaName | SchemaName[],
  searchQuery: string,
  searchResults: any[] = [],
  setState: any,
  additionalFilters?: any,
  hideImages?: boolean,
) => {
  if (searchQuery && searchQuery.length >= 2 && token) {
    const axiosHelper = new AxiosHelper(token);

    if (!Array.isArray(models)) {
      models = [models];
    }

    let updatedSearchResults = cloneDeep(searchResults);

    for (const model of models) {
      // Search
      const searchAPIResult = await axiosHelper.get(
        `${config.api}/${
          model === SchemaName.CATEGORY
            ? 'channel/search-category'
            : model + '/search'
        }`,
        {
          search: searchQuery,
          additional: additionalFilters,
        },
      );

      searchAPIResult.data.map((result: any, index: number) => {
        const inResults = updatedSearchResults.find(
          (r) => r.value === result._id || r.value === result.urlSlug,
        );

        if (!inResults) {
          updatedSearchResults.push(
            buildSearchOption(model, result, index, hideImages),
          );
        }

        return result;
      });
    }

    setState(updatedSearchResults);
  }
};
