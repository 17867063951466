const BuildInitialCategory = (channelId: string) => {
  return {
    _id: null,
    hidden: false,
    isPlaylist: false,
    contentId: null,
    channelId,
    categoryName: '',
    urlSlug: '',
    thumbnailId: null,
    order: 0,
    description: {},
  };
};

export default BuildInitialCategory;
