import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {Form} from 'semantic-ui-react';
import {ContentType} from '../../const/enums/Content';
import {Content} from '../../const/interfaces/Content';
import {getYouTubeVideoId} from '../../util/ContentHelper';
import {getModerationField} from '../../util/ModerationHelper';
import ContentThumbnail from './ContentThumbnail';

export interface YouTubeProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
}

const YouTube = ({content, handleChange, comingSoon}: YouTubeProps) => {
  const field = comingSoon ? `comingSoonContent.primaryContent` : `primaryContent`;
  const url = get(
    content,
    getModerationField(content, `${field}.${ContentType.YOUTUBE}.url`),
  );
  const existingThumbnail = get(
    content,
    getModerationField(
      content,
      `${field}.${ContentType.YOUTUBE}.mediaUrl`,
    ),
  );

  const [thumbnailVideoUrl, setThumbnailVideoUrl] = useState(url);
  const [thumbnail, setThumbnail] = useState(existingThumbnail);

  useEffect(() => {
    if (url && url !== thumbnailVideoUrl) {
      setThumbnailVideoUrl(url);

      const thumbnail = `https://img.youtube.com/vi/${getYouTubeVideoId(
        url,
      )}/0.jpg`;

      handleChange(`${field}.${ContentType.YOUTUBE}.mediaUrl`, {
        value: thumbnail,
      });
      setThumbnail(thumbnail);
    }
  }, [url, handleChange, thumbnailVideoUrl, field]);

  if (!content || !handleChange) {
    return null;
  }

  return (
    <div className={'ContentType'}>
      <div className={'ContentTypeInputs'}>
        <Form.Field required>
          <label>YouTube Share Link e.g. https://youtu.be/1Bv5NW1Tv8s</label>
          <Form.Input
            placeholder={
              'Enter a YouTube URL e.g. https://youtu.be/1Bv5NW1Tv8s'
            }
            value={
              get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.YOUTUBE}.url`,
                ),
              ) || ''
            }
            onChange={(e, data) =>
              handleChange(`${field}.${ContentType.YOUTUBE}.url`, data)
            }
            error={
              !get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.YOUTUBE}.url`,
                ),
              )
            }
          />
        </Form.Field>

        <Form.Field required>
          <label>YouTube Live Only</label>
          <Form.Checkbox
            toggle
            label={'Show YouTube Live chat'}
            checked={
              get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.YOUTUBE}.showChat`,
                ),
              ) || false
            }
            onChange={(e, data) =>
              handleChange(`${field}.${ContentType.YOUTUBE}.showChat`, {
                value: !!data.checked,
              })
            }
          />
        </Form.Field>
      </div>

      <div>
        <ContentThumbnail
          content={content}
          handleChange={handleChange}
          thumbnail={thumbnail}
          comingSoon={comingSoon}
        />
      </div>
    </div>
  );
};

export default YouTube;
