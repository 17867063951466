import {cloneDeep, get, set, startCase} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Divider, Dropdown, Form, Select} from 'semantic-ui-react';
import SponsorTable from '../components/system/SponsorTable';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import {systemStatuses} from '../const/enums/System';
import BuildInitialSystem from '../const/initial/System';
import {System} from '../const/interfaces/System';
import {User} from '../const/interfaces/User';
import {
  fetchSystemError,
  fetchSystemPending,
  fetchSystemSuccess,
} from '../redux/actions/system/SystemAction';
import AxiosHelper from '../util/AxiosHelper';
import {buildSearchOption, handleSearch} from '../util/SearchHelper';
import {validateSystem} from '../util/ValidationHelper';

const SystemView = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const system: System =
    useSelector((state) => state.system).data ||
    BuildInitialSystem((loggedInUser && loggedInUser._id) || '');

  const [userSearchResults, setUserSearchResults] = useState([] as any);

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        try {
          dispatch(fetchSystemPending());
          const axiosHelper = new AxiosHelper(loggedInUser.token);

          // Get system
          const result = await axiosHelper.get(`${config.api}/system`);

          if (!result) {
            dispatch(fetchSystemError('No system found'));
          }

          dispatch(fetchSystemSuccess(result.data));

          // Get system admin users
          if (result.data.systemAdmins && result.data.systemAdmins.length) {
            const adminResult = await axiosHelper.get(`${config.api}/user`, {
              _id: {$in: result.data.systemAdmins},
            });
            const userOptions = adminResult.data.results.map(
              (result, index) => {
                return buildSearchOption(SchemaName.USER, result, index);
              },
            );

            setUserSearchResults(userOptions);
          }
        } catch (e) {
          dispatch(fetchSystemError(e));
        }
      })();
    }
  }, [loggedInUser, dispatch]);

  const handleChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(system);

        // Do not allow deletion of own user from systemAdmins
        if (field === 'systemAdmins') {
          if (data.value.indexOf(loggedInUser._id) === -1) {
            // Add toast
            addToast(`You cannot remove yourself as a System Admin.`, {
              appearance: 'warning',
              autoDismiss: true,
            });

            return;
          }
        }

        set(cloned, field, data.value);
        dispatch(fetchSystemSuccess(cloned));
      }
    },
    [dispatch, system, addToast, loggedInUser],
  );

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update system
      const updatedSystem = (
        await axiosHelper.put(`${config.api}/system`, system)
      ).data;
      dispatch(fetchSystemSuccess(updatedSystem));

      // Add toast
      addToast(`System updated successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      dispatch(fetchSystemError(e));

      // Add toast
      addToast(`System update failed. Try again later.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <h1>System</h1>

        <h2 className={'alt-colour-type'}>System Admins</h2>

        <Form.Field required>
          <label>Admin Users</label>
          <Dropdown
            placeholder={'Type to search Users'}
            noResultsMessage={'Type to search Users'}
            fluid
            multiple
            search
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                SchemaName.USER,
                data.searchQuery,
                userSearchResults,
                setUserSearchResults,
              );
            }}
            onChange={(e, data) => handleChange('systemAdmins', data)}
            selection
            options={userSearchResults}
            value={system.systemAdmins}
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />
        </Form.Field>

        <Divider section />

        <h2 className={'alt-colour-type'}>Social Links</h2>

        <Form.Field>
          <label>Email</label>
          <Form.Input
            placeholder={'Enter an Email address'}
            value={get(system, 'social.email') || ''}
            onChange={(e, data) => handleChange('social.email', data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Facebook</label>
          <Form.Input
            placeholder={'Enter a Facebook url'}
            value={get(system, 'social.facebook') || ''}
            onChange={(e, data) => handleChange('social.facebook', data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Twitter</label>
          <Form.Input
            placeholder={'Enter a Twitter url'}
            value={get(system, 'social.twitter') || ''}
            onChange={(e, data) => handleChange('social.twitter', data)}
          />
        </Form.Field>
        <Form.Field>
          <label>Instagram</label>
          <Form.Input
            placeholder={'Enter an Instagram url'}
            value={get(system, 'social.instagram') || ''}
            onChange={(e, data) => handleChange('social.instagram', data)}
          />
        </Form.Field>

        <Divider section />

        <SponsorTable system={system} />
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>
        <Form.Field required>
          <label>Status</label>
          <Select
            placeholder={'Select a status'}
            options={systemStatuses().map((status, index) => {
              return {
                key: `system-status-${status}-${index}`,
                value: status,
                text: startCase(status),
              };
            })}
            value={get(system, `status`) || systemStatuses()[0]}
            onChange={(e, data) => handleChange(`status`, data)}
          />
        </Form.Field>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateSystem(system)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            Update System
          </Button>
        </div>
      </aside>
    </Form>
  );
};

export default SystemView;
