import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import AxiosHelper from './AxiosHelper';

export const handlePagination = async (
  token: string,
  model: SchemaName,
  activePage: number,
  pageSize = 10,
  searchValue: string[],
  setContent: any,
  setPaginationPage: any,
  setPaginationTotal: any,
  additionalFilters?: {},
) => {
  if (token) {
    const axiosHelper = new AxiosHelper(token);

    const filters = {
      page: activePage,
      pageSize,
      ...additionalFilters
    };

    if (searchValue && searchValue.length) {
      filters['_id'] = {$in: searchValue};
    }

    // Get documents
    const result = await axiosHelper.get(
      `${config.api}/${model}/formatted`,
      filters,
    );
    setContent(result.data.results);
    setPaginationPage(result.data.page);
    setPaginationTotal(result.data.total);
  }
};
