import {
  FETCH_LOGGED_IN_USER_ERROR,
  FETCH_LOGGED_IN_USER_PENDING,
  FETCH_LOGGED_IN_USER_SUCCESS,
} from './LoggedInUserActionTypes';

export const fetchLoggedInUserPending = () => {
  return {
    type: FETCH_LOGGED_IN_USER_PENDING,
  };
};

export const fetchLoggedInUserSuccess = (user) => {
  return {
    type: FETCH_LOGGED_IN_USER_SUCCESS,
    payload: user,
  };
};

export const fetchLoggedInUserError = (error) => {
  return {
    type: FETCH_LOGGED_IN_USER_ERROR,
    payload: error,
  };
};
