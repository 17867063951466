import {cloneDeep, set} from 'lodash';
import React, {ReactElement, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Modal} from 'semantic-ui-react';
import {FeatureType} from '../../const/enums/Content';
import {SchemaName} from '../../const/enums/Schema';
import {Channel} from '../../const/interfaces/Channel';
import {Content} from '../../const/interfaces/Content';
import {User} from '../../const/interfaces/User';
import {
  hideCommonModal,
  showCommonModal,
} from '../../redux/actions/common-modal/CommonModalAction';
import {fetchSelectedChannelSuccess} from '../../redux/actions/selected-channel/SelectedChannelAction';
import {fetchSelectedContentSuccess} from '../../redux/actions/selected-content/SelectedContentAction';
import {fetchSelectedUserSuccess} from '../../redux/actions/selected-user/SelectedUserAction';
import {uploadHelper} from '../../util/UploadHelper';
import ImageCrop from './ImageCrop';

export interface CommonModalProps {
  open: boolean;
  onClose?: any;
  headerText?: string | ReactElement;
  bodyText?: string | ReactElement;
  buttons?: {
    className: string;
    icon: string;
    buttonText: string;
    action: any;
    disabled?: boolean;
  }[];
  upload?: {
    localSetState?: any;
    documentId: string;
    model: SchemaName;
    subModel?: FeatureType | 'logo' | 'header';
    field: string;
    size?: {
      width: number;
      height: number;
      minWidth?: number;
      minHeight?: number;
    };
    aspect?: {
      width: number;
      height: number;
    };
  };
}

const CommonModal = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const modalData: CommonModalProps =
    useSelector((state) => state.commonModal).data || {};
  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const selectedChannel: Channel = useSelector((state) => state.selectedChannel)
    .data;
  const selectedContent: Content = useSelector((state) => state.selectedContent)
    .data;
  const selectedUser: User = useSelector((state) => state.selectedUser).data;

  const [uploadState, setUploadState] = useState();

  const uploadFile = async (filePromise) => {
    try {
      filePromise
        .then(async (blob) => {
          const uploadedMedia = await uploadHelper(
            loggedInUser._id,
            loggedInUser.token,
            blob,
          );

          await updateDocument(uploadedMedia._id);
        })
        .catch(() => {
          addToast('Image upload failed. Try again later.', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } catch (e) {
      addToast('Image upload failed. Try again later.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const updateDocument = async (mediaId: string) => {
    const field = modalData.upload?.field;

    // Update redux
    switch (modalData.upload && modalData.upload.model) {
      case SchemaName.CHANNEL:
        const cloneChannel = cloneDeep(selectedChannel);
        set(cloneChannel, field, mediaId);
        dispatch(fetchSelectedChannelSuccess(cloneChannel));
        break;
      case SchemaName.CONTENT:
        const cloneContent = cloneDeep(selectedContent);
        set(cloneContent, field, mediaId);
        dispatch(fetchSelectedContentSuccess(cloneContent));
        break;
      case SchemaName.USER:
        const cloneUser = cloneDeep(selectedUser);
        set(cloneUser, field, mediaId);
        dispatch(fetchSelectedUserSuccess(cloneUser));
        break;
      case SchemaName.SPONSOR:
        setUploadState(undefined);
        if (modalData.upload) {
          return modalData.upload.localSetState('logo', {value: mediaId});
        }
        break;
      case SchemaName.CATEGORY:
        setUploadState(undefined);
        if (modalData.upload) {
          return modalData.upload.localSetState('thumbnailId', {
            value: mediaId,
          });
        }
    }

    // Add toast
    addToast('Image uploaded successfully!', {
      appearance: 'success',
      autoDismiss: true,
    });

    // Clear state
    setUploadState(undefined);

    // Close modal
    dispatch(hideCommonModal());
  };

  // Format buttons
  const getModalButtons = () => {
    if (modalData.buttons) {
      return modalData.buttons.map((button, index) => {
        let action;
        switch (button.action) {
          case 'close':
            action = () => {
              // Clear state
              if (modalData.upload) {
                setUploadState(undefined);
              }

              dispatch(
                showCommonModal({
                  open: false,
                }),
              );
            };

            break;
          case 'upload':
            action = () => uploadFile(uploadState);
            break;
          default:
            action = button.action;
        }

        return (
          <Button
            key={`button-${index}-${button.buttonText}`}
            className={button.className}
            content={button.buttonText}
            onClick={action}
            icon={button.icon}
            disabled={
              button.action === 'upload' ? !uploadState : button.disabled
            }
          />
        );
      });
    }
  };

  const getModalBody = () => {
    if (modalData.upload) {
      return (
        <ImageCrop
          aspect={modalData.upload.aspect}
          size={modalData.upload.size}
          setState={setUploadState}
        />
      );
    }

    if (typeof modalData.bodyText === 'string') {
      return <p>{modalData.bodyText}</p>;
    }
    return modalData.bodyText;
  };

  if (!modalData.open) {
    return null;
  }

  return (
    <Modal
      onClose={
        modalData.onClose
          ? modalData.onClose
          : () => {
              // Clear state
              if (modalData.upload) {
                setUploadState(undefined);
              }

              dispatch(
                showCommonModal({
                  open: false,
                }),
              );
            }
      }
      open={modalData.open}
    >
      <Modal.Header>{modalData.headerText}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{getModalBody()}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>{getModalButtons()}</Modal.Actions>
    </Modal>
  );
};

export default CommonModal;
