import {Language} from '../enums/Language';
import {SystemStatus} from '../enums/System';
import {Highlights, System} from '../interfaces/System';

export const TOTAL_HIGHLIGHTS = 50;

const highlights = (): Highlights => {
  const hl = {};
  for (let i = 0; i < TOTAL_HIGHLIGHTS; i++) {
    hl[`${i}`] = {
      isCategory: false,
    };
  }
  return hl as Highlights;
};

const BuildInitialSystem = (loggedinUserId: string): System => {
  return {
    meta: {
      created: new Date(),
      createdBy: loggedinUserId,
      lastUpdated: new Date(),
      lastUpdatedBy: loggedinUserId,
    },

    status: SystemStatus.ACTIVE,

    systemAdmins: [],

    social: {},

    sponsors: [],

    languages: [
      {
        lang: Language.EN,
        primary: true,
      },
      {
        lang: Language.CY,
        primary: false,
      },
    ],

    highlightedContent: {
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: {
        hero: null,
        highlights: highlights(),
        scheduled: [],
      },
    },

    featuredContent: {
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: {
        highlights: [],
      },
    },

    comingSoon: {
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    curated: {
      position: 0,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    additionalCurated: {
      position: 1,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    tertiaryCurated: {
      position: 2,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    fourCurated: {
      position: 3,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    fiveCurated: {
      position: 4,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },

    sixCurated: {
      position: 5,
      title: {
        [Language.EN]: '',
        [Language.CY]: '',
      },
      active: false,
      content: [],
    },
  };
};

export default BuildInitialSystem;
