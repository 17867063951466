import {cloneDeep, get, set} from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Button, Checkbox, Divider, Dropdown, Form} from 'semantic-ui-react';
import ScheduleTable from '../components/common/ScheduleTable';
import Highlight from '../components/system/Highlight';
import config from '../config/config';
import {Language} from '../const/enums/Language';
import {SchemaName} from '../const/enums/Schema';
import BuildInitialSystem, {TOTAL_HIGHLIGHTS} from '../const/initial/System';
import {System} from '../const/interfaces/System';
import {User} from '../const/interfaces/User';
import {fetchSystemError, fetchSystemPending, fetchSystemSuccess} from '../redux/actions/system/SystemAction';
import AxiosHelper from '../util/AxiosHelper';
import {handleSearch} from '../util/SearchHelper';
import {validateSystem} from '../util/ValidationHelper';

const HighlightsView = () => {
  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const system: System =
    useSelector((state) => state.system).data ||
    BuildInitialSystem((loggedInUser && loggedInUser._id) || '');

  const [contentSearchResults, setContentSearchResults] = useState([] as any);
  const [categorySearchResults, setCategorySearchResults] = useState([] as any);
  const [scheduleFilter, setScheduleFilter] = useState(false);

  const [langStrings, setLangStrings] = useState({
    'highlightedContent.title': Language.EN,
  });

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        try {
          dispatch(fetchSystemPending());
          const axiosHelper = new AxiosHelper(loggedInUser.token);

          // Get system
          const result = await axiosHelper.get(`${config.api}/system`);
          if (!result) {
            dispatch(fetchSystemError('No system found'));
          }

          // Set highlight dropdowns
          if (result && result.data) {
            try {
              const content = await axiosHelper.get(`${config.api}/content`, {
                _id: {
                  $in: [result.data.highlightedContent.content.hero].concat(
                    Object.keys(
                      result.data.highlightedContent.content.highlights,
                    ).map((k) =>
                      result.data.highlightedContent.content.highlights[k]
                        .contentId &&
                      result.data.highlightedContent.content.highlights[k]
                        .contentId.length
                        ? result.data.highlightedContent.content.highlights[k]
                            .contentId
                        : null,
                    ),
                  ),
                },
              });

              if (content && content.data.results) {
                setContentSearchResults(
                  content.data.results.map((item) => {
                    return {
                      key: `hero-${item.contentName}-0`,
                      value: item._id,
                      text: item.contentName,
                      // Hiding to test performance
                      // image: item.featureContent.thumbnailId
                      //   ? `${config.api}/file/${item.featureContent.thumbnailId}`
                      //   : item.primaryContent[item.contentType]?.mediaUrl,
                    };
                  }),
                );
              }

              if (result.data.highlightedContent.content.highlights) {
                const highlights = cloneDeep(
                  result.data.highlightedContent.content.highlights,
                );
                const categoryOptions: any = [];
                for (const key of Object.keys(highlights)) {
                  if (highlights[key].isCategory) {
                    if (highlights[key].categoryId) {
                      const res = await axiosHelper.get(
                        `${config.api}/channel/category`,
                        {
                          categoryId: highlights[key].categoryId,
                        },
                      );

                      if (res) {
                        categoryOptions.push({
                          _id: res.data.channelId,
                          key: `highlight-${res.data.contentId}-${
                            res.data.categoryId
                          }-${Math.random() * 100000}`,
                          value: res.data._id,
                          text: res.data.categoryName,
                          // Hiding to test performance
                          // image: `${config.api}/file/${res.data.thumbnailId}`,
                        });
                      }
                    }
                  }
                }
                setCategorySearchResults(categoryOptions);
              }
            } catch (err) {
              console.log(err);
            }
          }

          dispatch(fetchSystemSuccess(result.data));
        } catch (e) {
          dispatch(fetchSystemError(e));
        }
      })();
    }
  }, [loggedInUser, dispatch]);

  const handleChange = useCallback(
    (field: string | string[], data: any) => {
      if (field && data) {
        const cloned = cloneDeep(system);

        if (!Array.isArray(field)) {
          field = [field];
        }

        if (!Array.isArray(data)) {
          data = [data];
        }

        for (let i = 0; i < field.length; i++) {
          set(cloned, field[i], data[i].value);
        }

        dispatch(fetchSystemSuccess(cloned));
      }
    },
    [dispatch, system],
  );

  const handleLangChange = (field: string, lang: Language) => {
    if (field && lang) {
      const cloned = cloneDeep(langStrings);
      set(cloned, field, lang);
      setLangStrings(cloned);
    }
  };

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update system
      const updatedSystem = (
        await axiosHelper.put(`${config.api}/system`, system)
      ).data;
      dispatch(fetchSystemSuccess(updatedSystem));

      // Add toast
      addToast(`Highlights updated successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      dispatch(fetchSystemError(e));

      // Add toast
      addToast(`Highlights update failed. Try again later.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const buildHighlights = () => {
    let highlights: any[] = [];
    for (let i = 0; i < TOTAL_HIGHLIGHTS; i++) {
      highlights.push(
        <Highlight
          key={`highlight-${i}`}
          index={i}
          loggedInUser={loggedInUser}
          system={system}
          onChange={handleChange}
          results={contentSearchResults}
          setResults={setContentSearchResults}
          categoryResults={categorySearchResults}
          setCategoryResults={setCategorySearchResults}
        />,
      );
    }
    return highlights;
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <h1>Highlights</h1>

        <Form.Field>
          <label className={'LangStringSelector'}>
            <span
              className={
                langStrings['highlightedContent.title'] !== Language.EN
                  ? 'normal-type'
                  : ''
              }
              onClick={() =>
                handleLangChange('highlightedContent.title', Language.EN)
              }
            >
              Playlist Title
            </span>
            <span className={'normal-type'}> | </span>
            <span
              className={
                langStrings['highlightedContent.title'] !== Language.CY
                  ? 'normal-type'
                  : ''
              }
              onClick={() =>
                handleLangChange('highlightedContent.title', Language.CY)
              }
            >
              Teitl Rhestr Chwarae
            </span>
          </label>
          {langStrings['highlightedContent.title'] === Language.EN && (
            <Form.Input
              placeholder={'Enter a playlist title'}
              value={
                get(system, 'highlightedContent.title.' + Language.EN) || ''
              }
              onChange={(e, data) =>
                handleChange(`highlightedContent.title.${Language.EN}`, data)
              }
              error={!get(system, 'highlightedContent.title.' + Language.EN)}
            />
          )}
          {langStrings['highlightedContent.title'] === Language.CY && (
            <Form.Input
              placeholder={'Rhowch deitl'}
              value={
                get(system, 'highlightedContent.title.' + Language.CY) || ''
              }
              onChange={(e, data) =>
                handleChange(`highlightedContent.title.${Language.CY}`, data)
              }
              error={!get(system, 'highlightedContent.title.' + Language.CY)}
            />
          )}
        </Form.Field>

        <div className={'Filter'}>
          <Checkbox
            className={'FilterModeration'}
            slider
            label={`View Schedule`}
            checked={scheduleFilter}
            onChange={async (e, data) => {
              setScheduleFilter(!!data.checked);
            }}
          />
        </div>

        {!scheduleFilter && (
          <>
            <h2 className={'alt-colour-type'}>Active Hero</h2>

            <Form.Field required>
              <label>Hero Content</label>
              <Dropdown
                placeholder={'Type to search Content'}
                noResultsMessage={'Type to search Content'}
                fluid
                search
                onSearchChange={async (e, data) => {
                  await handleSearch(
                    loggedInUser.token,
                    [SchemaName.CONTENT, SchemaName.CATEGORY],
                    data.searchQuery,
                    contentSearchResults,
                    setContentSearchResults,
                  );
                }}
                onChange={(e, data) =>
                  handleChange('highlightedContent.content.hero', data)
                }
                selection
                options={contentSearchResults}
                value={system.highlightedContent.content.hero}
                renderLabel={(item) => {
                  return {
                    content: <>{item.text}</>,
                  };
                }}
              />

              {system.highlightedContent.content.hero && (
                <p className={'InputLink'}>
                  <Link
                    to={`/content-item/${system.highlightedContent.content.hero}`}
                  >
                    View Content
                  </Link>
                </p>
              )}
            </Form.Field>

            <Divider section />

            <h2 className={'alt-colour-type'}>Active Highlights</h2>

            {buildHighlights()}
          </>
        )}

        {scheduleFilter && (
          <ScheduleTable
            loggedInUser={loggedInUser}
            model={SchemaName.SYSTEM}
          />
        )}
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>

        <Form.Field required>
          <label>Active</label>
          <Form.Checkbox
            toggle
            label={'Show playlist'}
            checked={get(system, 'highlightedContent.active') || false}
            onChange={(e, data) =>
              handleChange(`highlightedContent.active`, {
                value: !!data.checked,
              })
            }
          />
        </Form.Field>

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateSystem(system)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            Update Highlights
          </Button>
        </div>
      </aside>
    </Form>
  );
};

export default HighlightsView;
