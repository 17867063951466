import axios from 'axios';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {Form} from 'semantic-ui-react';
import {ContentType} from '../../const/enums/Content';
import {Content} from '../../const/interfaces/Content';
import {getModerationField} from '../../util/ModerationHelper';
import ContentThumbnail from './ContentThumbnail';

export interface VimeoProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
}

const Vimeo = ({content, handleChange, comingSoon}: VimeoProps) => {
  const {addToast} = useToasts();

  const field = comingSoon ? `comingSoonContent.primaryContent` : `primaryContent`;
  const url = get(
    content,
    getModerationField(content, `${field}.${ContentType.VIMEO}.url`),
  );
  const existingThumbnail = get(
    content,
    getModerationField(
      content,
      `${field}.${ContentType.VIMEO}.mediaUrl`,
    ),
  );

  const [thumbnailVideoUrl, setThumbnailVideoUrl] = useState(url);
  const [thumbnail, setThumbnail] = useState(existingThumbnail);

  useEffect(() => {
    if (url && url !== thumbnailVideoUrl) {
      setThumbnailVideoUrl(url);

      (async () => {
        try {
          const videoData = await axios.get(
            `https://vimeo.com/api/oembed.json?url=${url}`,
          );

          if (!videoData || !videoData.data || !videoData.data.thumbnail_url) {
            return addToast(`Thumbnail could not be found.`, {
              appearance: 'warning',
              autoDismiss: true,
            });
          }

          const thumbnail = videoData.data.thumbnail_url;
          handleChange(`${field}.${ContentType.VIMEO}.mediaUrl`, {
            value: thumbnail,
          });
          setThumbnail(thumbnail);
        } catch (e) {
          return addToast(`Thumbnail could not be found.`, {
            appearance: 'warning',
            autoDismiss: true,
          });
        }
      })();
    }
  }, [url, handleChange, thumbnailVideoUrl, addToast, field]);

  if (!content || !handleChange) {
    return null;
  }

  return (
    <div className={'ContentType'}>
      <div className={'ContentTypeInputs'}>
        <Form.Field required>
          <label>Vimeo URL</label>
          <Form.Input
            placeholder={'Enter a Vimeo URL e.g. https://vimeo.com/123456789'}
            value={
              get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.VIMEO}.url`,
                ),
              ) || ''
            }
            onChange={(e, data) =>
              handleChange(`${field}.${ContentType.VIMEO}.url`, data)
            }
            error={
              !get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.VIMEO}.url`,
                ),
              )
            }
          />
        </Form.Field>
      </div>

      <div>
        <ContentThumbnail
          content={content}
          handleChange={handleChange}
          thumbnail={thumbnail}
          comingSoon={comingSoon}
        />
      </div>
    </div>
  );
};

export default Vimeo;
