export enum SocialPlatform {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  SOUNDCLOUD = 'soundcloud',
  SPOTIFY = 'spotify',
  TWITTER = 'twitter',
  WEBSITE = 'website',
  YOUTUBE = 'youtube',
}
