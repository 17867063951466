import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Button, Checkbox, Dropdown, Table} from 'semantic-ui-react';
import TablePagination from '../components/common/TablePagination';
import config from '../config/config';
import {SchemaName} from '../const/enums/Schema';
import BuildInitialUser from '../const/initial/User';
import {fetchSelectedUserSuccess} from '../redux/actions/selected-user/SelectedUserAction';
import {handlePagination} from '../util/PaginationHelper';
import {handleSearch} from '../util/SearchHelper';

const UsersView = () => {
  const pageSize = 10;

  const dispatch = useDispatch();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const loggedInUser = useSelector((state) => state.loggedInUser).data;
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationTotal, setPaginationTotal] = useState(0);
  const [filterSearchValue, setFilterSearchValue] = useState([] as any);
  const [filterSearchResults, setFilterSearchResults] = useState([] as any);
  const [deletionFilter, setDeletionFilter] = useState(false);

  const handlePaginationChange = useCallback(
    async (activePage: number, additionalFilters?) => {
      await handlePagination(
        loggedInUser && loggedInUser.token,
        SchemaName.USER,
        activePage,
        10,
        filterSearchValue,
        setUsers,
        setPaginationPage,
        setPaginationTotal,
        additionalFilters,
      );
    },
    [loggedInUser, filterSearchValue],
  );

  useEffect(() => {
    if (loggedInUser && loggedInUser.token) {
      (async () => {
        // Get users
        await handlePaginationChange(1);
      })();
    }
  }, [loggedInUser, handlePaginationChange]);

  const userHeaders = [
    '',
    'Name',
    'Requested Deletion',
    'Channel Admin',
    'Total Follows',
    'Total Likes',
    'Created',
    'Last Updated',
  ].map((header) => {
    return (
      <Table.Cell className={'TableHeader'} key={`user-header-${header}`}>
        {header}
      </Table.Cell>
    );
  });

  const userItems =
    users &&
    users.map((userItem: any, index: number) => {
      return (
        <Table.Row
          className={'TableRowClickable'}
          key={`user-row-${index}`}
          onClick={() => history.push(`/user-item/${userItem._id}`)}
        >
          <Table.Cell>
            <div
              className={'TableImage'}
              style={
                userItem.avatar && {
                  backgroundImage: `url(${config.api}/file/${userItem.avatar})`,
                }
              }
            />
          </Table.Cell>
          <Table.Cell>
            {userItem.firstName} {userItem.lastName}
          </Table.Cell>
          <Table.Cell>{userItem.deletionRequested ? 'True' : '---'}</Table.Cell>
          <Table.Cell>
            {userItem.channel && userItem.channel.length
              ? userItem.channel.length[0].chanelName
              : '---'}
          </Table.Cell>
          <Table.Cell>{userItem.library.content.length}</Table.Cell>
          <Table.Cell>{userItem.library.channels.length}</Table.Cell>
          <Table.Cell>
            {moment(userItem.meta.created).format('DD/MM/YYYY HH:mm')}
          </Table.Cell>
          <Table.Cell>
            {moment(userItem.meta.lastUpdated).format('DD/MM/YYYY HH:mm')}
          </Table.Cell>
        </Table.Row>
      );
    });

  return (
    <div className={'Page'}>
      <div className={'PageContent'}>
        <div className={'PageContentHeader'}>
          <h1>Users</h1>
          <Button
            primary
            onClick={(event) => {
              event.preventDefault();

              // Clear existing selectedUser data
              dispatch(
                fetchSelectedUserSuccess(BuildInitialUser(loggedInUser._id)),
              );

              // Redirect
              history.push(`/user-item`);
            }}
          >
            Create User
          </Button>
        </div>

        <div className={'Filter'}>
          <h2>Search Users</h2>
          <Dropdown
            placeholder={'Type to search Users'}
            noResultsMessage={'Type to search Users'}
            fluid
            search
            clearable
            multiple
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                SchemaName.USER,
                data.searchQuery,
                filterSearchResults,
                setFilterSearchResults,
              );
            }}
            onChange={async (e, data) => {
              if (data.value) {
                setPaginationPage(1);
                setFilterSearchValue(data.value);
              }
            }}
            selection
            options={filterSearchResults}
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />

          <Checkbox
            className={'FilterModeration'}
            slider
            label={`View Deletion Requests`}
            checked={deletionFilter}
            onChange={async (e, data) => {
              setDeletionFilter(!!data.checked);

              await handlePaginationChange(
                1,
                data.checked
                  ? {
                      deletionRequested: true,
                    }
                  : {},
              );
            }}
          />
        </div>

        <Table className={'Table'} textAlign={'center'} selectable striped>
          <Table.Header>
            <Table.Row>{userHeaders}</Table.Row>
          </Table.Header>

          <Table.Body>
            {userItems && userItems.length ? (
              userItems
            ) : (
              <Table.Row>
                <Table.Cell colSpan={userHeaders.length}>
                  <p>No Users found</p>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>

          <TablePagination
            totalCols={userHeaders.length}
            pageSize={pageSize}
            paginationPage={paginationPage}
            paginationTotal={paginationTotal}
            onChange={handlePaginationChange}
          />
        </Table>
      </div>
    </div>
  );
};

export default UsersView;
