import {
  FETCH_LOGGED_IN_USER_ERROR,
  FETCH_LOGGED_IN_USER_PENDING,
  FETCH_LOGGED_IN_USER_SUCCESS,
} from '../actions/logged-in-user/LoggedInUserActionTypes';

export interface LoggedInUserState {
  pending: boolean;
  data: any;
  error: string | null;
}

const INITIAL_STATE: LoggedInUserState = {
  pending: false,
  data: null,
  error: null,
};

const loggedInUserReducer = (state = INITIAL_STATE, action) => {
  const payload: LoggedInUserState = action.payload;
  switch (action.type) {
    case FETCH_LOGGED_IN_USER_PENDING:
      return {...state, pending: true};

    case FETCH_LOGGED_IN_USER_SUCCESS:
      return {...state, pending: false, data: payload};

    case FETCH_LOGGED_IN_USER_ERROR:
      return {...state, pending: false, error: payload};

    default:
      return state;
  }
};

export default loggedInUserReducer;
