import {HighlightType} from '../enums/Highlight';
import {PublishStatus} from '../enums/PublishMeta';

const coeff = 1000 * 60 * 5;
const date = new Date();

const BuildInitialSchedule = (contentId?: string, categoryId?: string) => {
  return {
    _id: null,
    isCategory: false,
    highlightType: '' as HighlightType,
    highlightPosition: 0,
    contentId: contentId || '',
    categoryId: categoryId || '',
    publishMeta: {
      status: PublishStatus.DRAFT,
      publishDate: new Date(Math.floor(date.getTime() / coeff) * coeff) as any,
    },
  };
};

export default BuildInitialSchedule;
