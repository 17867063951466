import {Environment} from '../const/enums/Environment';

export default {
  environment: Environment.LIVE,
  port: 3000,
  api: 'https://core.amam.cymru/api',
  webUrl: 'https://amam.cymru',
  logoutUrl: 'https://dashboard.amam.cymru',
  auth0: {
    clientId: '2wgDS347Yg1NV7EndaYbuqkpMnC59FFc',
    domain: 'amam-cymru.eu.auth0.com',
    audience: 'https://dashboard.amam.cymru/api/',
    connection: 'Username-Password-Authentication',
  },
};
