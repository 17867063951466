import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get} from 'lodash';
import React, {ReactElement} from 'react';
import {Popup} from 'semantic-ui-react';

export interface SectionHeaderProps {
  data?: any;
  fields?: string[];
  title: string;
}

const SectionHeader = ({
  data = {},
  fields = [],
  title,
}: SectionHeaderProps): ReactElement => {
  let requiresModeration = false;

  for (const field of fields) {
    if (
      get(data, `moderation.${field}`) ||
      get(data, `moderation.${field}`) === null
    ) {
      requiresModeration = true;
    }
  }

  return (
    <div className={'SectionHeader'}>
      <h2 className={'alt-colour-type'}>{title}</h2>

      {requiresModeration && (
        <Popup
          position={'left center'}
          size={'small'}
          content={'This section requires moderation'}
          trigger={<FontAwesomeIcon icon={faExclamationCircle} />}
        />
      )}
    </div>
  );
};

export default SectionHeader;
