import {cloneDeep, get, set, startCase} from 'lodash';
import React, {useCallback, useEffect} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {Button, Divider, Form, Select} from 'semantic-ui-react';
import config from '../config/config';
import {CommonModalActionTypes} from '../const/enums/CommonModal';
import {SchemaName} from '../const/enums/Schema';
import {userStatuses} from '../const/enums/User';
import BuildInitialUser from '../const/initial/User';
import {User} from '../const/interfaces/User';
import {hideCommonModal, showCommonModal} from '../redux/actions/common-modal/CommonModalAction';
import {
  fetchSelectedUserError,
  fetchSelectedUserPending,
  fetchSelectedUserSuccess,
} from '../redux/actions/selected-user/SelectedUserAction';
import AxiosHelper from '../util/AxiosHelper';
import {validateUser} from '../util/ValidationHelper';

const UserItemView = () => {
  const {addToast} = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params.id;

  const loggedInUser: User = useSelector((state) => state.loggedInUser).data;
  const user: User =
    useSelector((state) => state.selectedUser).data ||
    BuildInitialUser((loggedInUser && loggedInUser._id) || '');

  useEffect(() => {
    if (loggedInUser && loggedInUser.token && userId) {
      (async () => {
        try {
          dispatch(fetchSelectedUserPending());
          const axiosHelper = new AxiosHelper(loggedInUser.token);

          // Get user
          dispatch(
            fetchSelectedUserSuccess(
              (await axiosHelper.get(`${config.api}/user/${userId}`)).data,
            ),
          );
        } catch (e) {
          dispatch(fetchSelectedUserError(e));
        }
      })();
    } else if (loggedInUser && loggedInUser.token && !userId) {
      fetchSelectedUserSuccess(
        BuildInitialUser((loggedInUser && loggedInUser._id) || ''),
      );
    }
  }, [loggedInUser, dispatch, userId]);

  const handleChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(user);
        const val =
          field === 'channelId' && !data.value.length ? null : data.value;

        set(cloned, field, val);
        dispatch(fetchSelectedUserSuccess(cloned));
      }
    },
    [dispatch, user],
  );

  const handleSubmit = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Update lastUpdated
      user.meta.lastUpdated = new Date();
      user.meta.lastUpdatedBy = loggedInUser._id;

      // Update/Create user
      const newUser = (userId
        ? await axiosHelper.put(`${config.api}/user`, user)
        : await axiosHelper.post(`${config.api}/user`, user)
      ).data;
      dispatch(fetchSelectedUserSuccess(newUser));

      // Add toast
      addToast(`User ${userId ? 'updated' : 'created'} successfully!`, {
        appearance: 'success',
        autoDismiss: true,
      });

      // If creation - redirect to edit page
      if (!userId) {
        history.push(`/user-item/${newUser._id}`);
      }
    } catch (e) {
      dispatch(fetchSelectedUserError(e));

      // Add toast
      addToast(
        `User ${userId ? 'update' : 'creation'} failed. Try again later.`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  };

  const handleDelete = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Delete user
      await axiosHelper.delete(`${config.api}/user/${user._id}`);
      dispatch(fetchSelectedUserSuccess(null));

      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('User deleted successfully!', {
        appearance: 'success',
        autoDismiss: true,
      });

      // Redirect
      history.push(`/users`);
    } catch (e) {
      // Close modal
      dispatch(hideCommonModal());

      // Add toast
      addToast('User deletion failed. Try again later.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleResetPassword = async () => {
    try {
      const axiosHelper = new AxiosHelper(loggedInUser.token);

      // Request reset password email
      await axiosHelper.post(`${config.api}/user/reset-password`, {
        email: user.contactDetails.email,
      });

      // Add toast
      addToast('A password reset email has been sent to the user!', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      // Add toast
      addToast(
        'A password reset email could not be generated. Try again later.',
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }
  };

  return (
    <Form className={'Page PageEditor'} as={'div'}>
      <div className={'PageContent'}>
        <h1>
          {userId
            ? `Editing ${user.firstName} ${user.lastName}`
            : `Creating User`}
        </h1>

        {user.deletionRequested && (
          <h2 className={'danger-colour-type'}>
            THIS USER HAS REQUESTED FOR THEIR ACCOUNT TO BE DELETED
          </h2>
        )}

        <h2 className={'alt-colour-type'}>User Information</h2>

        <Form.Group widths={'equal'}>
          <Form.Field required>
            <label>First Name</label>
            <Form.Input
              placeholder={'Enter a first name'}
              value={get(user, 'firstName') || ''}
              onChange={(e, data) => handleChange('firstName', data)}
              error={!get(user, 'firstName')}
            />
          </Form.Field>

          <Form.Field required>
            <label>Last Name</label>
            <Form.Input
              placeholder={'Enter a last name'}
              value={get(user, 'lastName') || ''}
              onChange={(e, data) => handleChange('lastName', data)}
              error={!get(user, 'lastName')}
            />
          </Form.Field>
        </Form.Group>

        {loggedInUser?.accessType === 'admin' && (
          <Form.Field>
            <label>Auth0 ID</label>
            <Form.Input
              placeholder={'Enter a Auth0 ID'}
              value={get(user, 'auth0Id') || ''}
              onChange={(e, data) => handleChange('auth0Id', data)}
              error={!get(user, 'auth0Id')}
            />
          </Form.Field>
        )}

        <Divider section />

        <h2 className={'alt-colour-type'}>Contact Information</h2>
        <Form.Field>
          <label>Email Address</label>
          <Form.Input
            placeholder={'Enter an email address'}
            value={get(user, 'contactDetails.email') || ''}
            onChange={(e, data) => handleChange('contactDetails.email', data)}
          />
        </Form.Field>

        <Divider section />

        <h2 className={'alt-colour-type'}>User Appearance</h2>

        <div className={'UserAppearanceWrapper'}>
          <div className={'UserAvatar'}>
            <Form.Field required>
              <label className={'LangStringSelector'}>User Avatar</label>
              <div
                className={'UserAvatarPreview'}
                style={
                  user &&
                  user.avatar && {
                    backgroundImage: `url(${config.api}/file/${user.avatar})`,
                  }
                }
              />
              <Button
                primary
                onClick={(event) => {
                  event.preventDefault();

                  dispatch(
                    showCommonModal({
                      open: true,
                      headerText: 'Upload Avatar',
                      buttons: [
                        {
                          className: 'button primary',
                          buttonText: 'Save Avatar',
                          action: 'upload',
                        },
                        {
                          className: 'button secondary',
                          buttonText: 'Cancel',
                          action: CommonModalActionTypes.CLOSE,
                        },
                      ],
                      upload: {
                        documentId: user._id,
                        model: SchemaName.USER,
                        field: 'avatar',
                        aspect: {
                          width: 10,
                          height: 10,
                        },
                      },
                    }),
                  );
                }}
              >
                Upload Avatar
              </Button>
            </Form.Field>
          </div>
        </div>
      </div>

      <aside className={'PageSidebar'}>
        <h2>Publishing</h2>
        {loggedInUser?.accessType === 'admin' && (
          <Form.Field required>
            <label>Status</label>
            <Select
              fluid
              placeholder={'Select a status'}
              options={userStatuses().map((status, index) => {
                return {
                  key: `user-status-${status}-${index}`,
                  value: status,
                  text: startCase(status),
                };
              })}
              value={get(user, `status`) || userStatuses()[0]}
              onChange={(e, data) => handleChange(`status`, data)}
            />
          </Form.Field>
        )}

        <div className={'PageSidebarActions'}>
          <Button
            disabled={validateUser(user)}
            className={'PageSidebarAction'}
            onClick={handleSubmit}
            primary
          >
            {userId ? 'Update' : 'Create'} User
          </Button>

          {loggedInUser?.accessType === 'admin' && userId && (
            <Button
              className={'PageSidebarDelete'}
              color={'red'}
              size={'tiny'}
              onClick={() =>
                dispatch(
                  showCommonModal({
                    open: true,
                    headerText: 'Delete User',
                    bodyText:
                      'Are you sure you wish to delete this user? This action cannot be undone.',
                    buttons: [
                      {
                        className: 'button red',
                        buttonText: 'Delete User',
                        action: handleDelete,
                      },
                      {
                        className: 'button primary',
                        buttonText: 'Cancel',
                        action: CommonModalActionTypes.CLOSE,
                      },
                    ],
                  }),
                )
              }
            >
              Delete User
            </Button>
          )}
        </div>

        <Divider section />

        <Form.Field>
          <label>Password Reset</label>
          <Button onClick={handleResetPassword} primary fluid>
            Reset Password
          </Button>
        </Form.Field>
      </aside>
    </Form>
  );
};

export default UserItemView;
