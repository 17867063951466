import React from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Form} from 'semantic-ui-react';
import {SchemaName} from '../../const/enums/Schema';
import {System} from '../../const/interfaces/System';
import {User} from '../../const/interfaces/User';
import {handleSearch} from '../../util/SearchHelper';

export interface HighlightProps {
  index: number;
  loggedInUser: User;
  system: System;
  results: any;
  setResults: any;
  categoryResults: any;
  setCategoryResults: any;
  onChange: any;
}

const Highlight = ({
  index,
  loggedInUser,
  system,
  results,
  setResults,
  categoryResults,
  setCategoryResults,
  onChange,
}: HighlightProps) => {
  const isCategory =
    system.highlightedContent.content.highlights[index] &&
    system.highlightedContent.content.highlights[index].isCategory;

  return (
    <>
      <h3>Highlight {index + 1}</h3>
      <Form.Field>
        <label>Content Type</label>
        <Form.Checkbox
          toggle
          label={'Channel Playlist'}
          checked={isCategory}
          onChange={(e, data) =>
            onChange(
              [
                `highlightedContent.content.highlights.${index}.isCategory`,
                `highlightedContent.content.highlights.${index}.contentId`,
                `highlightedContent.content.highlights.${index}.categoryId`,
              ],
              [
                {
                  value: !!data.checked,
                },
                {value: null},
                {value: null},
              ],
            )
          }
        />
      </Form.Field>
      {!isCategory && (
        <Form.Field>
          <label>Content</label>
          <Dropdown
            placeholder={'Type to search Content'}
            noResultsMessage={'Type to search Content'}
            fluid
            clearable
            search
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                [SchemaName.CONTENT],
                data.searchQuery,
                results,
                setResults,
              );
            }}
            onChange={(e, data) =>
              onChange(
                `highlightedContent.content.highlights.${index}.contentId`,
                data,
              )
            }
            selection
            options={results}
            value={
              system.highlightedContent.content.highlights[index] &&
              system.highlightedContent.content.highlights[index].contentId
            }
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />

          {system.highlightedContent.content.highlights[index] &&
            system.highlightedContent.content.highlights[index].contentId && (
              <p className={'InputLink'}>
                <Link
                  to={`/content-item/${system.highlightedContent.content.highlights[index].contentId}`}
                >
                  View Content
                </Link>
              </p>
            )}
        </Form.Field>
      )}

      {isCategory && (
        <Form.Field>
          <label>Playlist</label>
          <Dropdown
            placeholder={'Type to search Playlists'}
            noResultsMessage={'Type to search Playlists'}
            fluid
            clearable
            search
            onSearchChange={async (e, data) => {
              await handleSearch(
                loggedInUser.token,
                [SchemaName.CATEGORY],
                data.searchQuery,
                categoryResults,
                setCategoryResults,
              );
            }}
            onChange={(e, data) => {
              onChange(
                [
                  `highlightedContent.content.highlights.${index}.contentId`,
                  `highlightedContent.content.highlights.${index}.categoryId`,
                ],
                [
                  {
                    value: null,
                  },
                  data,
                ],
              );
            }}
            selection
            options={categoryResults}
            value={
              system.highlightedContent.content.highlights[index].categoryId
            }
            renderLabel={(item) => {
              return {
                content: <>{item.text}</>,
              };
            }}
          />
        </Form.Field>
      )}
    </>
  );
};

export default Highlight;
