import {get} from 'lodash';
import React from 'react';
import {Form} from 'semantic-ui-react';
import {ContentType} from '../../const/enums/Content';
import {Content} from '../../const/interfaces/Content';
import {getModerationField} from '../../util/ModerationHelper';
import ContentThumbnail from './ContentThumbnail';

export interface FacebookProps {
  content: Content;
  handleChange: any;
  comingSoon?: boolean;
}

const Facebook = ({content, handleChange, comingSoon}: FacebookProps) => {
  if (!content || !handleChange) {
    return null;
  }

  const field = comingSoon
    ? `comingSoonContent.primaryContent`
    : `primaryContent`;

  return (
    <div className={'ContentType'}>
      <div className={'ContentTypeInputs'}>
        <Form.Field required>
          <label>Facebook Video URL</label>
          <Form.Input
            placeholder={
              'Enter a Facebook Video URL e.g. https://www.facebook.com/facebook/videos/10153231379946729/'
            }
            value={
              get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.FACEBOOK}.url`,
                ),
              ) || ''
            }
            onChange={(e, data) =>
              handleChange(`${field}.${ContentType.FACEBOOK}.url`, data)
            }
            error={
              !get(
                content,
                getModerationField(
                  content,
                  `${field}.${ContentType.FACEBOOK}.url`,
                ),
              )
            }
          />
        </Form.Field>
      </div>

      <div>
        <ContentThumbnail
          content={content}
          handleChange={handleChange}
          comingSoon={comingSoon}
        />
      </div>
    </div>
  );
};

export default Facebook;
