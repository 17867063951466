import {
  FETCH_SELECTED_USER_ERROR,
  FETCH_SELECTED_USER_PENDING,
  FETCH_SELECTED_USER_SUCCESS,
} from '../actions/selected-user/SelectedUserActionTypes';

export interface SelectedUserState {
  pending: boolean;
  data: any;
  error: string | null;
}

const INITIAL_STATE: SelectedUserState = {
  pending: false,
  data: null,
  error: null,
};

const selectedUserReducer = (state = INITIAL_STATE, action) => {
  const payload: SelectedUserState = action.payload;
  switch (action.type) {
    case FETCH_SELECTED_USER_PENDING:
      return {...state, pending: true};

    case FETCH_SELECTED_USER_SUCCESS:
      return {...state, pending: false, data: payload};

    case FETCH_SELECTED_USER_ERROR:
      return {...state, pending: false, error: payload};

    default:
      return state;
  }
};

export default selectedUserReducer;
