import {get} from 'lodash';
import {ContentType, FeatureType} from '../const/enums/Content';
import {Channel} from '../const/interfaces/Channel';
import {Content} from '../const/interfaces/Content';
import {System} from '../const/interfaces/System';
import {User} from '../const/interfaces/User';

const validate = (data, requiredFields): boolean => {
  for (const field of requiredFields) {
    const val = get(data, field);

    if (
      typeof val !== 'boolean' &&
      (!val || (typeof val === 'string' && !val.length))
    ) {
      // Try moderation
      const moderatedVal = get(data, `moderation.${field}`);
      if (
        typeof moderatedVal !== 'boolean' &&
        (!moderatedVal ||
          (typeof moderatedVal === 'string' && !moderatedVal.length))
      ) {
        return true;
      }
    }
  }

  return false;
};

export const validateSponsor = (sponsor: System['sponsors']) => {
  let requiredFields = ['sponsorName', 'url', 'logo'];

  return validate(sponsor[0], requiredFields);
};

export const validateCategory = (category: Channel['categories']) => {
  let requiredFields = ['isPlaylist', 'order'];

  const isPlaylist = get(category[0], 'isPlaylist');

  if (isPlaylist) {
    requiredFields = requiredFields.concat([
      'categoryName',
      'thumbnailId',
      'urlSlug',
    ]);
  } else {
    requiredFields = requiredFields.concat(['contentId']);
  }

  return validate(category[0], requiredFields);
};

export const validateSchedule = (
  schedule: System['highlightedContent']['content']['scheduled'],
) => {
  let requiredFields = [
    // TODO - FIX
    // 'contentId',
    'highlightType',
    'publishMeta.status',
    'publishMeta.publishDate',
  ];

  return validate(schedule[0], requiredFields);
};

export const validateSystem = (system: System): boolean => {
  let requiredFields = ['status'];

  return validate(system, requiredFields);
};

export const validateUser = (user: User): boolean => {
  let requiredFields = [
    'firstName',
    'lastName',
    `contactDetails.email`,
    `language`,
    'avatar',
  ];

  return validate(user, requiredFields);
};

export const validateChannel = (channel: Channel): boolean => {
  let requiredFields = [
    'channelName',
    'publishMeta.status',
    'publishMeta.publishDate',
    'theme.logo',
    // 'theme.logoAlt',
  ];

  return validate(channel, requiredFields);
};

export const validateContent = (content: Content): boolean => {
  let requiredFields = [
    'channelId',
    'contentName',
    'publishMeta.status',
    'publishMeta.publishDate',
    'contentType',
    'contentCategory',
  ];

  const isComingSoon = get(content, 'isComingSoon');
  const comingSoonType = get(content, 'comingSoonContent.contentType');

  switch (content.contentType) {
    case ContentType.FACEBOOK:
      requiredFields = requiredFields.concat([
        `primaryContent.${ContentType.FACEBOOK}.url`,
        'featureContent.thumbnailId',
        // 'featureContent.alt'
      ]);
      break;
    case ContentType.MIXED:
      // requiredFields = requiredFields.concat(['featureContent.thumbnailId']);
      break;
    case ContentType.SOUNDCLOUD:
      requiredFields = requiredFields.concat([
        `primaryContent.${ContentType.SOUNDCLOUD}.url`,
        `primaryContent.${ContentType.SOUNDCLOUD}.mediaUrl`,
      ]);
      break;
    case ContentType.SPOTIFY:
      requiredFields = requiredFields.concat([
        `primaryContent.${ContentType.SPOTIFY}.url`,
        `primaryContent.${ContentType.SPOTIFY}.mediaUrl`,
      ]);
      break;
    case ContentType.VIMEO:
      requiredFields = requiredFields.concat([
        `primaryContent.${ContentType.VIMEO}.url`,
        `primaryContent.${ContentType.VIMEO}.mediaUrl`,
      ]);
      break;
    case ContentType.YOUTUBE:
      requiredFields = requiredFields.concat([
        `primaryContent.${ContentType.YOUTUBE}.url`,
        `primaryContent.${ContentType.YOUTUBE}.mediaUrl`,
      ]);
      break;
  }

  if (content.contentType !== ContentType.MIXED) {
    requiredFields = requiredFields.concat(['featureContent.featureType']);
  }

  if (isComingSoon) {
    switch (comingSoonType) {
      case ContentType.FACEBOOK:
        requiredFields = requiredFields.concat([
          `comingSoonContent.primaryContent.${ContentType.FACEBOOK}.url`,
          'comingSoonContent.featureContent.thumbnailId',
          // 'comingSoonContent.featureContent.alt',
        ]);

        break;
      case ContentType.MIXED:
        requiredFields = requiredFields.concat([
          'comingSoonContent.featureContent.thumbnailId',
          // 'comingSoonContent.featureContent.alt',
        ]);

        break;
      case ContentType.SOUNDCLOUD:
        requiredFields = requiredFields.concat([
          `comingSoonContent.primaryContent.${ContentType.SOUNDCLOUD}.url`,
          `comingSoonContent.primaryContent.${ContentType.SOUNDCLOUD}.mediaUrl`,
        ]);

        break;
      case ContentType.SPOTIFY:
        requiredFields = requiredFields.concat([
          `comingSoonContent.primaryContent.${ContentType.SPOTIFY}.url`,
          `comingSoonContent.primaryContent.${ContentType.SPOTIFY}.mediaUrl`,
        ]);

        break;
      case ContentType.VIMEO:
        requiredFields = requiredFields.concat([
          `comingSoonContent.primaryContent.${ContentType.VIMEO}.url`,
          `comingSoonContent.primaryContent.${ContentType.VIMEO}.mediaUrl`,
        ]);

        break;
      case ContentType.YOUTUBE:
        requiredFields = requiredFields.concat([
          `comingSoonContent.primaryContent.${ContentType.YOUTUBE}.url`,
          `comingSoonContent.primaryContent.${ContentType.YOUTUBE}.mediaUrl`,
        ]);

        break;
    }
  }

  if (get(content, 'featureContent.featureType') === FeatureType.MEDIA) {
    requiredFields.push('featureContent.coverId');
    // requiredFields.push('featureContent.alt');
  }

  if (get(content, 'isComingSoon')) {
    requiredFields.push('comingSoonContent.title');

    if (
      get(content, 'comingSoonContent.featureContent.featureType') ===
      FeatureType.MEDIA
    ) {
      requiredFields.push('comingSoonContent.featureContent.coverId');
      // requiredFields.push('featureContent.alt');
    }
  }

  return validate(content, requiredFields);
};
