import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Form} from 'semantic-ui-react';
import {SchemaName} from '../../const/enums/Schema';
import {System} from '../../const/interfaces/System';
import {User} from '../../const/interfaces/User';
import {handleSearch} from '../../util/SearchHelper';
import {SEARCH_TYPING_TIMEOUT} from './AdditionalCuratedItem';

export interface ComingSoonItemProps {
  index: number;
  loggedInUser: User;
  system: System;
  results: any;
  setResults: any;
  onChange: any;
}

const ComingSoonItem = ({
  index,
  loggedInUser,
  system,
  results,
  setResults,
  onChange,
}: ComingSoonItemProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debounceQuery, setDebounceQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const search = async (searchTerm: string) => {
    await handleSearch(
      loggedInUser.token,
      SchemaName.CONTENT,
      searchTerm,
      results,
      setResults,
      undefined,
      true,
    );
  };

  // Allow user to finish typing before setting search value
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (debounceQuery !== searchQuery) {
        setSearchQuery(debounceQuery);
      }
    }, SEARCH_TYPING_TIMEOUT);

    return () => clearTimeout(delayDebounceFn);
  }, [debounceQuery, searchQuery]);

  // Perform search
  useEffect(() => {
    (async () => {
      setLoading(true);
      await search(searchQuery);
      setLoading(false);
    })();
  }, [searchQuery]);

  return (
    <Form.Field style={{marginTop: 25}}>
      <label>Coming Soon {index + 1}</label>
      <Dropdown
        placeholder={'Type to search Content'}
        noResultsMessage={'Type to search Content'}
        fluid
        clearable
        search
        loading={loading}
        onSearchChange={async (e, data) => {
          if (data.searchQuery !== searchQuery) {
            setDebounceQuery(data.searchQuery);
          }
        }}
        onChange={(e, data) =>
          onChange(`comingSoon.content.${index}.contentId`, data)
        }
        selection
        options={results}
        value={
          system.comingSoon.content[index] &&
          system.comingSoon.content[index].contentId
        }
        renderLabel={(item) => {
          return {
            content: <>{item.text}</>,
          };
        }}
      />

      {system.comingSoon.content[index] &&
        system.comingSoon.content[index].contentId && (
          <p className={'InputLink'}>
            <Link
              to={`/content-item/${
                system.comingSoon.content[index] &&
                system.comingSoon.content[index].contentId
              }`}
            >
              View Content
            </Link>
          </p>
        )}
    </Form.Field>
  );
};

export default ComingSoonItem;
