import axios from 'axios';

export default class AxiosHelper {
  private headers = {Authorization: `Bearer ${this.token}`};

  constructor(private token) {}

  public async get(url: string, filters = {}, useHeaders = true) {
    if (this.headers && !this.token) {
      return [];
    }

    const result = await axios.get(url, {
      params: filters,
      headers: useHeaders ? this.headers : undefined,
    });

    return result.data;
  }

  public async post(url: string, body: any) {
    if (this.headers && !this.token) {
      return [];
    }

    const result = await axios.post(url, body, {headers: this.headers});
    return result.data;
  }

  public async put(url: string, body: any) {
    if (this.headers && !this.token) {
      return [];
    }

    const result = await axios.put(url, body, {headers: this.headers});
    return result.data;
  }

  public async delete(url: string) {
    if (this.headers && !this.token) {
      return [];
    }

    const result = await axios.delete(url, {headers: this.headers});
    return result.data;
  }

  public async uploadFile(url: string, data: any) {
    try {
      this.headers['content-type'] = 'multipart/form-data';
      const formData = new FormData();

      const file = data.media || data.blob || data.file;
      const title = (data.body && data.body.title) || null;

      formData.append('created', data.body.created);
      formData.append('createdBy', data.body.createdBy);
      formData.append('updated', data.body.updated);
      formData.append('updatedBy', data.body.updatedBy);
      formData.append('file', file, title);

      const result = await axios.post(url, formData, {headers: this.headers});
      return result.data;
    } catch (err) {
      throw (err.response);
    }
  }
}
