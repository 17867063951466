import {
  FETCH_SELECTED_USER_ERROR,
  FETCH_SELECTED_USER_PENDING,
  FETCH_SELECTED_USER_SUCCESS,
} from './SelectedUserActionTypes';

export const fetchSelectedUserPending = () => {
  return {
    type: FETCH_SELECTED_USER_PENDING,
  };
};

export const fetchSelectedUserSuccess = (channel) => {
  return {
    type: FETCH_SELECTED_USER_SUCCESS,
    payload: channel,
  };
};

export const fetchSelectedUserError = (error) => {
  return {
    type: FETCH_SELECTED_USER_ERROR,
    payload: error,
  };
};
