import {Types} from 'mongoose';
import config from '../config/config';
import AxiosHelper from './AxiosHelper';

export const uploadHelper = async (userId: string, token: string, blob) => {
  try {
    const axiosHelper = new AxiosHelper(token);

    // Upload media
    const result = await axiosHelper.uploadFile(`${config.api}/file`, {
      body: {
        created: new Date(),
        createdBy: userId,
        updated: new Date(),
        updatedBy: userId,
        title: `${new Types.ObjectId()}.jpeg`,
      },
      blob,
    });
    return result.data;
  } catch (err) {
    throw Error(err);
  }
};
