import {
  FETCH_SYSTEM_ERROR,
  FETCH_SYSTEM_PENDING,
  FETCH_SYSTEM_SUCCESS,
} from '../actions/system/SystemActionTypes';

export interface SystemState {
  pending: boolean;
  data: any;
  error: string | null;
}

const INITIAL_STATE: SystemState = {
  pending: false,
  data: null,
  error: null,
};

const systemReducer = (state = INITIAL_STATE, action) => {
  const payload: SystemState = action.payload;
  switch (action.type) {
    case FETCH_SYSTEM_PENDING:
      return {...state, pending: true};

    case FETCH_SYSTEM_SUCCESS:
      return {...state, pending: false, data: payload};

    case FETCH_SYSTEM_ERROR:
      return {...state, pending: false, error: payload};

    default:
      return state;
  }
};

export default systemReducer;
