import {cloneDeep, get, set} from 'lodash';
import {Types} from 'mongoose';
import React, {useCallback, useEffect, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {Button, Form, Table} from 'semantic-ui-react';
import config from '../../config/config';
import {SchemaName} from '../../const/enums/Schema';
import BuildInitialSponsor from '../../const/initial/Sponsor';
import {System} from '../../const/interfaces/System';
import {showCommonModal} from '../../redux/actions/common-modal/CommonModalAction';
import {fetchSystemSuccess} from '../../redux/actions/system/SystemAction';
import {validateSponsor} from '../../util/ValidationHelper';
import TablePagination from '../common/TablePagination';

export interface SponsorTableProps {
  system: System;
}

const SponsorTable = ({system}: SponsorTableProps) => {
  const pageSize = 10;

  const {addToast} = useToasts();
  const dispatch = useDispatch();

  const [sponsorPaginationPage, setSponsorPaginationPage] = useState(1);
  const [sponsorPaginationTotal, setSponsorPaginationTotal] = useState(
    system.sponsors.length,
  );

  const [selectedSponsorItem, setSelectedSponsorItem] = useState(
    BuildInitialSponsor,
  );
  const [sponsorModalOpen, setSponsorModalOpen] = useState(false);

  const clearSponsorModal = () => {
    setSponsorModalOpen(false);
    setSelectedSponsorItem(BuildInitialSponsor);
    dispatch(showCommonModal({open: false}));
  };

  const handleSponsorChange = useCallback(
    (field: string, data: any) => {
      if (field && data) {
        const cloned = cloneDeep(selectedSponsorItem);
        set(cloned, field, data.value);

        setSelectedSponsorItem(cloned);
      }
    },
    [selectedSponsorItem],
  );

  const handleSponsorSave = () => {
    const cloned = cloneDeep(system);

    // Find if update or create sponsor
    if (selectedSponsorItem._id) {
      cloned.sponsors = cloned.sponsors.map((sponsor) => {
        if (sponsor._id === selectedSponsorItem._id) {
          return selectedSponsorItem;
        }
        return sponsor;
      });

      // Add toast
      addToast(`Sponsor updated successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } else {
      selectedSponsorItem._id = new Types.ObjectId().toHexString();
      cloned.sponsors.push(selectedSponsorItem);

      // Add toast
      addToast(`Sponsor created successfully.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    }

    dispatch(fetchSystemSuccess(cloned));
    clearSponsorModal();
  };

  const handleSponsorDelete = () => {
    const cloned = cloneDeep(system);

    if (!selectedSponsorItem._id) {
      // Add toast
      addToast(`Sponsor deletion failed. Try again later.`, {
        appearance: 'warning',
        autoDismiss: true,
      });
    }

    // Delete sponsor
    let deletedIndex;
    cloned.sponsors = cloned.sponsors.filter((item, index) => {
      if (item._id === selectedSponsorItem._id) {
        deletedIndex = index;
      }
      return item._id !== selectedSponsorItem._id;
    });

    // Update pagination if necessary
    if (deletedIndex) {
      if (
        deletedIndex + 1 === sponsorPaginationTotal &&
        sponsorPaginationPage > 1
      ) {
        setSponsorPaginationPage(sponsorPaginationPage - 1);
      }
    }

    // Add toast
    addToast(`Sponsor deleted successfully.`, {
      appearance: 'success',
      autoDismiss: true,
    });

    dispatch(fetchSystemSuccess(cloned));
    clearSponsorModal();
  };

  useEffect(() => {
    if (system.sponsors.length !== sponsorPaginationTotal) {
      setSponsorPaginationTotal(system.sponsors.length);
    }
  }, [
    system.sponsors.length,
    sponsorPaginationTotal,
    setSponsorPaginationTotal,
  ]);

  useEffect(() => {
    if (sponsorModalOpen) {
      const isEditing = !!selectedSponsorItem._id;

      const buttons = [
        {
          disabled: validateSponsor([selectedSponsorItem]),
          className: 'button primary',
          buttonText: 'Save Sponsor',
          action: handleSponsorSave,
        },
        {
          className: 'button secondary',
          buttonText: 'Cancel',
          action: clearSponsorModal,
        },
      ];

      if (isEditing) {
        buttons.splice(1, 0, {
          className: 'button red',
          buttonText: 'Delete Sponsor',
          action: handleSponsorDelete,
        });
      }

      dispatch(
        showCommonModal({
          open: true,
          onClose: clearSponsorModal,
          headerText: `${isEditing ? 'Edit' : 'Create'} Sponsor`,
          bodyText: (
            <Form>
              <Form.Field>
                <label>Sponsor Name</label>
                <Form.Input
                  placeholder={'Enter a Sponsor Name'}
                  value={get(selectedSponsorItem, 'sponsorName') || ''}
                  onChange={(e, data) =>
                    handleSponsorChange('sponsorName', data)
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>URL</label>
                <Form.Input
                  placeholder={'Enter a URL'}
                  value={get(selectedSponsorItem, 'url') || ''}
                  onChange={(e, data) => handleSponsorChange('url', data)}
                />
              </Form.Field>

              <div className={'SponsorLogoWrapper'}>
                <Form.Field required>
                  <label>Sponsor Logo</label>
                  <div
                    className={'SponsorLogoPreview'}
                    style={
                      selectedSponsorItem.logo
                        ? {
                            backgroundImage: `url(${config.api}/file/${selectedSponsorItem.logo})`,
                          }
                        : undefined
                    }
                  />

                  <div className={'SponsorLogoActions'}>
                    <Button
                      primary
                      onClick={(event) => {
                        event.preventDefault();

                        dispatch(
                          showCommonModal({
                            open: true,
                            onClose: clearSponsorModal,
                            headerText: 'Upload Sponsor Logo',
                            buttons: [
                              {
                                className: 'button primary',
                                buttonText: 'Save Logo',
                                action: 'upload',
                              },
                              {
                                className: 'button secondary',
                                buttonText: 'Cancel',
                                action: clearSponsorModal,
                              },
                            ],
                            upload: {
                              localSetState: handleSponsorChange,
                              documentId: selectedSponsorItem._id,
                              model: SchemaName.SPONSOR,
                              field: `logo`,
                              aspect: {
                                width: 43,
                                height: 15,
                              },
                            },
                          }),
                        );
                      }}
                    >
                      Upload Logo
                    </Button>
                  </div>
                </Form.Field>
              </div>
            </Form>
          ),
          buttons,
        }),
      );
    }
  });

  const sponsorTableHeaders = ['', 'Name', 'URL'].map((header) => {
    return (
      <Table.Cell className={'TableHeader'} key={`sponsor-header-${header}`}>
        {header}
      </Table.Cell>
    );
  });

  const sponsorTableItems = system.sponsors.map(
    (sponsor: any, index: number) => {
      if (
        index >= (sponsorPaginationPage - 1) * pageSize &&
        index < sponsorPaginationPage * pageSize
      ) {
        return (
          <Table.Row
            className={'TableRowClickable'}
            key={`sponsor-row-${index}`}
            onClick={() => {
              setSelectedSponsorItem(sponsor);
              setSponsorModalOpen(true);
            }}
          >
            <Table.Cell>
              <div
                className={'TableImage'}
                style={
                  sponsor.logo && {
                    backgroundImage: `url(${config.api}/file/${sponsor.logo})`,
                  }
                }
              />
            </Table.Cell>
            <Table.Cell>{sponsor.sponsorName}</Table.Cell>
            <Table.Cell>{sponsor.url}</Table.Cell>
          </Table.Row>
        );
      }
      return null;
    },
  );

  return (
    <>
      <div className={'SubTableHeader'}>
        <h2 className={'alt-colour-type'}>Sponsors</h2>
        <Button
          primary
          onClick={(event) => {
            event.preventDefault();

            setSponsorModalOpen(true);
          }}
        >
          Create Sponsor
        </Button>
      </div>

      <Table className={'Table'} textAlign={'center'} selectable striped>
        <Table.Header>
          <Table.Row>{sponsorTableHeaders}</Table.Row>
        </Table.Header>

        <Table.Body>
          {sponsorTableItems && sponsorTableItems.length ? (
            sponsorTableItems
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <p>No Sponsors found</p>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>

        <TablePagination
          totalCols={3}
          pageSize={pageSize}
          paginationPage={sponsorPaginationPage}
          paginationTotal={sponsorPaginationTotal}
          onChange={(activePage: number) => {
            setSponsorPaginationPage(activePage);
          }}
        />
      </Table>
    </>
  );
};

export default SponsorTable;
