export enum HighlightType {
  HERO = 'hero',
  HIGHLIGHT = 'highlight',
}

export const highlightTypes = () => {
  return Object.keys(HighlightType).map((type) => HighlightType[type]);
};

export const highlightPositions = (highlightCount: number) => {
  let positions: any[] = [];
  for (let i = 0; i < highlightCount; i++) {
    positions.push({
      key: `highlight-position-${i}`,
      value: i,
      text: `Highlight ${i + 1}`,
    });
  }

  return positions;
};
